import handleAuthToken from "../../Utils/handleAuthToken";
import axios from "axios";

export const getBookingListApi = async (queryString) => {
  if (localStorage.token) {
    handleAuthToken(localStorage.token);
  }
  try {
    const res = await axios.get(`/admin/av1/getBookingList`+queryString);
    return res.data;
  } catch (error) {
    return { success: false, message: error.response.data.message };
  }
};

export const nursesAvailableListApi = async (id) => {
  if (localStorage.token) {
    handleAuthToken(localStorage.token);
  }
  try {
    const res = await axios.get(`admin/av1/nurseCaregiverList/${id}`);
    return res.data;
  } catch (error) {
    return { success: false, message: error.response.data.message };
  }
};


export const assignNurseCaregive = async (body) => {
  if (localStorage.token) {
    handleAuthToken(localStorage.token);
  }
  try {
    const res = await axios.post(`admin/av1/AssignNurse`, body);
    return res.data;
  } catch (error) {
    return { success: false, message: error.response.data.message };
  }
};

export const removeNurseApi = async (body) => {
  if (localStorage.token) {
    handleAuthToken(localStorage.token);
  }
  try {
    const res = await axios.post(`/admin/av1/removeNurse`,body);
    return res.data;
  } catch (error) {
    return { success: false, message: error.response.data.message };
  }
};
