import React from "react";
import { useState, useEffect } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Box,
  Container,
  Typography,
  InputAdornment,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
  IconButton,
  Tooltip,
  Button,
  styled,
  useMediaQuery,
  Stack,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import RectangleGlass from "../../Assets/Rectangle_glass.png";

import { loginUserApi } from "../../state/actions/authActions";
import { ToastContainer, toast } from "react-toastify";
import validator from "validator";
import Backdrop from "../BackDrop/BackDrop";
import Cookies from "universal-cookie";
import loginScreen from '../../Assets/Doctors-bro(1).png'

const cookies = new Cookies();

const StyledLeftSide = styled("div")(() => ({
  borderRadius: "10px",
  // backgroundColor: "#0957DD",
  height: "88vh",
  position: "relative",
}));
const StyledRightSide = styled("div")(() => ({
  borderRadius: "10px",
  height: "88vh",
}));
const StyledLabel = styled("label")(() => ({
  display: "inline-block",
  fontWeight: "500",
  fontSize: "14px",
  mt: 1,
  borderRadius: "8px",
}));
const StyledLogin = styled(Button)(() => ({
  backgroundColor: "#0957DD",
  color: "white",
  fontSize: "16px",
  padding: "8px 20px",
  "&:hover": {
    backgroundColor: "#0957DD",
    color: "white",
  },
}));

const Login = () => {
  const navigate = useNavigate();
  const [openBackdrop, setOpenBreakdrop] = useState(false);

  const isAuthenticated = useSelector((store) => store.auth.isAuthenticated);
  if (isAuthenticated) {
    navigate("/dashboard");
  }
  const dispatch = useDispatch();
  const lg = useMediaQuery("@media (max-width:900px)");
  const xs = useMediaQuery("@media (max-width:600px)");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleRememberMeChange = (event) => {
    setRememberMe(event.target.checked);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const toastType = {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    };
    if (!validator.isEmail(email)) {
      toast.error("Invalid Email !", toastType);
    }

    if (password && password.length < 8) {
      toast.error("Password length should be 8 !", toastType);
    } else if (!password) {
      toast.error("Password required !", toastType);
    } else {
      setOpenBreakdrop(true);
      const response = await dispatch(
        loginUserApi({ email, password }, rememberMe)
      );
      if (response && response.status === true) {
        setOpenBreakdrop(false);

        toast.success(response.message, toastType);
      }else if (response && response.status === false) {
        setOpenBreakdrop(false);
        toast.error(
          response.message ||
            "Unexpected error occured please try again later.",
          toastType
        );
      }
    }
  };
  useEffect(() => {
    const userDetails = localStorage.getItem("token");
    const userEmail = cookies.get("myEmail");
    const userPassword = cookies.get("myPassword");
    if (userEmail !== "" && userPassword !== "") {
      setEmail(userEmail);
      setPassword(userPassword);
      
    }
  }, []);
  return (
    <>
      <Backdrop open={openBackdrop} />

      <Grid
        container
        spacing={1}
        sx={{ backgroundColor: "#F8F8F8", height: "100vh" }}
      >
        <ToastContainer />
        <Grid item lg={5} md={5} sm={5} xs={12} sx={{ margin: "35px 20px" }}>
          <StyledLeftSide
          
          >
            <Box
              sx={{
                width:'85%',
                position: "absolute",
                margin: lg ? "20px 20px 20px 10px" : " 40px 40px 40px 20px",
                color: "white",
                padding: lg ? "0px 15px" : "0px 50px",
               
              }}
            >
              <h1 style={{  color: "#0957DD",}}><b>Welcome to UrCare</b></h1>
            </Box>
            <img
              
              src={loginScreen}
              style={{
                width: "85%",
                height: "105%",
                paddingTop: "50px",
                paddingBottom: "50px",
              }}
              alt="Login Background"
            />
          </StyledLeftSide>
        </Grid>

        <Grid item lg={5} md={5} sm={5} xs={12} sx={{ margin: "35px 20px" }}>
          <StyledRightSide>
            <Container sx={xs ? {} : { pt: "20%" }}>
              <Box sx={{ mb: 3 }}>
                <Typography
                  component={"h4"}
                  sx={{
                    display: "inline-block",
                    fontSize: "36px",
                    fontWeight: "600",
                  }}
                >
                  Login
                </Typography>
                <br />
                <Typography
                  sx={{
                    display: "inline-block",
                    fontSize: "16px",
                    fontWeight: "400",
                  }}
                >
                  Login to manage your account
                </Typography>
              </Box>
              <form onSubmit={handleSubmit} autoComplete="off">
                <Box sx={{ mt: 2 }}>
                  <StyledLabel>Email</StyledLabel>
                  <TextField
                    name="email"
                    value={email}
                    onChange={handleEmailChange}
                    sx={{ mt: 1, borderRadius: "8px" }}
                    size="small"
                    fullWidth
                  />
                </Box>
                <Box sx={{ mt: 2 }}>
                  <StyledLabel>Password</StyledLabel>
                  <TextField
                    value={password}
                    onChange={handlePasswordChange}
                    type={showPassword ? "text" : "password"}
                    sx={{ mt: 1, borderRadius: "8px" }}
                    size="small"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start" sx={{ m: 0 }}>
                          <Tooltip
                            title={
                              showPassword ? "Hide Password" : "Show Password"
                            }
                          >
                            <IconButton onClick={handleClickShowPassword}>
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Stack
                  flexDirection="row"
                  justifyContent="space-between"
                  sx={{ mt: 2 }}
                >
                  <FormGroup>
                    <FormControlLabel
                      label="Remember me"
                      control={
                        <Checkbox
                          checked={rememberMe}
                          onChange={handleRememberMeChange}
                        />
                      }
                    />
                  </FormGroup>
                  {/* <Button
                    onClick={() => {
                      navigate("/forgetpassword");
                    }}
                    sx={{ textTransform: "capitalize" }}
                  >
                    Forgot Password ?
                  </Button> */}
                </Stack>
                <StyledLogin
                  // onClick={() => {
                  //   navigate("/dashboard");
                  // }}

                  sx={{ mt: 3 }}
                  type="submit"
                >
                  Login
                </StyledLogin>
              </form>
            </Container>
          </StyledRightSide>
        </Grid>
      </Grid>
    </>
  );
};
export default Login;
