import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import { Button, Grid, Box, Typography, IconButton } from "@mui/material";
import camera from "../../Assets/camera.svg";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

import { TextField, FormLabel, MenuItem, Alert, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Close as CloseIcon } from "@mui/icons-material";
import Switch from "@mui/material/Switch";

import { useForm } from "../../Utils/useForm";
import BackdropComponent from "../BackDrop/BackDrop";

import {
  addEquipmentApi,
  equipmentDetailsApi,
  editEquipmentApi,
  deleteSelectedImageApi,
  deleteSelectedspecificationApi,
  getHsnListDropdown,
} from "../../state/actions/equipmentsAction";
import { getAllServices } from "../../state/actions/equipmentsAction";
import Backdrop from "../BackDrop/BackDrop";

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
}));
const StyledTextField = styled(TextField)(() => ({
  marginTop: "10px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
  },
}));
const StyledImageUploadWrapper = styled(Box)(() => ({
  marginTop: "16px",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  height: "220px",
  borderRadius: "10px",
  textAlign: "center",
}));
const StyledIconWrapper = styled(Box)(() => ({
  margin: "20px auto",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  width: "80px",
  height: "80px",
  borderRadius: "10px",
  backgroundColor: "rgba(15, 15, 15, 0.05)",

  img: {
    marginTop: "30px",
  },
}));

const StyledInput = styled("input")({
  display: "none",
});
const StyledRequired = styled("span")({
  color: "red",
  marginLeft: "2px",
  fontSize: "20px",
});

const StyledDiv = styled("div")({
  display: "flex",
  background: "#FFFFFF",
  boxShadow:
    "  0px 20px 94px rgba(16, 24, 40, 0.01), 0px 8px 30px rgba(16, 24, 40, 0.01)",
  borderRadius: "12px !important",
  padding: "22px",
  width: "100%",
  height: "100%",
});

const StyledParagraph = styled("p")({
  // fontStyle: "normal",
  // fontWeight: "400",
  fontSize: "16px",
  lineHeight: "34px",
  marginTop: "0px",
  fontFamily: "Work Sans",
});
const StyledHeading = styled("h1")({
  // fontFamily: 'Work Sans',
  // fontStyle: "normal",
  fontWeight: "600",
  fontSize: "24px",
  lineHeight: "32px",
});

const initialValues = {
  name: "",
  description: "",
  serviceId: "",
  catId: 1,
  mrp: "",
  stock: "",
  actualPrice: "",
  hsn: "",
  taxAmount: "",
};

const AddEquipment = () => {
  const navigate = useNavigate();

  const [backdropState, setBackdropState] = useState(false);
  const [subCategories, setSubCategories] = useState([]);
  const [hsnList, sethsnList] = useState([]);
  const [images, setImages] = useState([]);
  const [files, setFiles] = useState([]);
  const [specificationarr, setSpecificationArr] = useState([
    {
      specificKey: "",
      specificValue: "",
    },
  ]);
  const [iconFlag, setIconFlag] = useState(0);
  const [openBackdrop, setOpenBreakdrop] = useState(false);

  const stopBackdrop = () => {
    setBackdropState(false);
  };
  const startBackdrop = () => {
    setBackdropState(true);
  };

  var { state, pathname } = useLocation();

  //  we have 2 routes one for adding new product and second for edit product
  pathname = pathname.split("/")[2];

  const getSubCategories = async () => {
    const toastType = {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    };
    setOpenBreakdrop(true);
    const res = await getAllServices(1);
    if (res && res.data) {
      setSubCategories(res.data);
      setOpenBreakdrop(false);
    } else if (!res.status) {
      setOpenBreakdrop(false);
    }
  };

  const getEquipmentDetails = async (id) => {
    setOpenBreakdrop(true);

    const res = await equipmentDetailsApi(id);
    if (res && res.data) {
      var data = res.data.productData;
      //  added basePrice for edit api purpose
      data.basePrice = Number(data.actualPrice) - Number(data.taxAmount);
      setValues(data);
      setFiles(res.data.productImages);
      let speciarr = res.data.productSpecification;
      speciarr.filter((val) => {
        delete val.createdAt;
        delete val.updatedAt;
        return val;
      });
      setSpecificationArr(speciarr);
      setOpenBreakdrop(false);
    }
  };

  const getHsnList = async () => {
    const res = await getHsnListDropdown();
    if (res && res.data) {
      sethsnList(res.data);
    }
  };
  useEffect(() => {
    getSubCategories();
    getHsnList();
    if (pathname === "edit") {
      if (state === null) {
        navigate("/dashboard");
      } else {
        let id = state;
        getEquipmentDetails(id);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //  to handle local files

  async function handleImageItem(e) {
    const selectedFiles = e.target.files;
    var localFiles = [...Array.from(selectedFiles)];

    setImages((preval) => {
      return [...preval, ...localFiles];
    });

    let newImagearr = localFiles.map((file) => ({
      file: file,
      image: URL.createObjectURL(file),
    }));
    setFiles([...files, ...newImagearr]);
  }

  const addClick = async (event) => {
    setSpecificationArr((s) => {
      return [
        ...s,
        {
          specificKey: "",
          specificValue: "",
        },
      ];
    });
  };
  const handleSpecificationChange = (e) => {
    e.preventDefault();

    const index = e.target.id;
    const filedname = e.target.name;
    let tempSpecification = [];
    setSpecificationArr((s) => {
      const newArr = s.slice();
      newArr[index][filedname] = e.target.value;
      return newArr;
    });
  };

  //  for validation
  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("name" in fieldValues) {
      temp.name = fieldValues.name ? "" : "This field is required.";
      if (fieldValues.name.length > 50) {
        temp.name = "name should be lessthan 50 character";
      }
    }

    if ("serviceId" in fieldValues)
      temp.serviceId = fieldValues.serviceId ? "" : "This field is required.";

    if ("description" in fieldValues)
      temp.description = fieldValues.description
        ? ""
        : "This field is required.";

    if ("hsn" in fieldValues)
      temp.hsn = fieldValues.hsn ? "" : "This field is required.";

    if ("mrp" in fieldValues) {
      temp.mrp = fieldValues.mrp ? "" : "This field is required.";
      if (fieldValues.mrp.length > 15) {
        temp.mrp = "MRP should be less than 15 digit";
      }
    }

    if ("stock" in fieldValues)
      temp.stock = fieldValues.stock ? "" : "This field is required.";

    if ("basePrice" in fieldValues) {
      temp.basePrice = fieldValues.basePrice ? "" : "This field is required.";
      if (fieldValues.basePrice.length > 15) {
        temp.basePrice = "Base Price should be less than 15 digit";
      }
    }
    if (fieldValues && fieldValues.basePrice && fieldValues.mrp) {
      if (parseInt(fieldValues.mrp) < parseInt(fieldValues.basePrice)) {
        temp.basePrice = "Actual Price should be less than MRP";
      }
    }
    // if (fieldValues.actualPrice<)

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  //  form object
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  console.log("values ====================== values", values);

  //  when base price changes , these 2 fields will also change.
  useEffect(() => {
    if (values.basePrice && values.hsn) {
      const taxAmount = Number(
        calculateTaxablePrice(values.hsn, values.basePrice)
      );

      setValues({
        ...values,
        taxAmount: taxAmount,
        actualPrice: Number(Number(values.basePrice) + taxAmount).toFixed(2),
      });
    }
  }, [values.hsn, values.basePrice]);

  //  submit function for adding or updating product
  const handleSubmit = async (e) => {
    e.preventDefault();
    const toastType = {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    };

    if (validate()) {
      if (pathname === "add" && !images.length) {
        return toast.error("Please Select a Image", toastType);
      } else if (pathname === "edit" && !files.length) {
        return toast.error("Please Select atleast a Image", toastType);
      }

      let formdata = new FormData();
      Object.keys(values).map((key) => formdata.append(key, values[key]));

      images.forEach((val, id) => {
        formdata.append("images", val);
      });
      formdata.append("specification", JSON.stringify(specificationarr));
      formdata.append("iconFlag", iconFlag);
      if (
        values.id ||
        values.rating ||
        values.numRating ||
        values.createdAt ||
        values.updatedAt ||
        values.basePrice
      ) {
        formdata.delete("id");
        formdata.delete("rating");
        formdata.delete("numRating");
        formdata.delete("createdAt");
        formdata.delete("updatedAt");
        formdata.delete("basePrice");
      }

      let res;
      if (pathname === "add") {
        setOpenBreakdrop(true);

        res = await addEquipmentApi(formdata);
      } else if (pathname === "edit") {
        setOpenBreakdrop(true);
        formdata.delete("serviceName");
        formdata.delete("hsnData");

        res = await editEquipmentApi(values.id, formdata);
      }
      if (typeof res !== undefined) {
        if (res && res.status === true) {
          setOpenBreakdrop(false);
          toast.success(res.message, toastType);
          navigate("/equipments");
        } else {
          setOpenBreakdrop(false);
          toast.error(res.message, toastType);
        }
      }
    }
  };

  // function for delete image from images and files array
  async function deleteImage(index, val) {
    if (images[index]) {
      setImages((preval) => {
        return preval.filter((val, id) => id !== index);
      });
    }
    if (files[index]) {
      setFiles((preval) => {
        return preval.filter((val, id) => id !== index);
      });
    }
    if (val.equipmentId && val.id) {
      await deleteSelectedImageApi({
        imageId: val.id,
        equipmentId: val.equipmentId,
      });
    }
  }

  const deleteSelectedspecification = async (data) => {
    setSpecificationArr((preval) => {
      preval.splice(-1);
      return [...preval];
    });
    await deleteSelectedspecificationApi(data);
  };

  const calculateTaxablePrice = (gst_rate, price) => {
    try {
      var hsnPercentage = hsnList.filter((item) => item.id == gst_rate);
      if (hsnPercentage.length) {
        hsnPercentage = hsnPercentage[0];
        return ((price * hsnPercentage.taxRatePercentage) / 100).toFixed(2);
      } else {
        return 0;
      }
    } catch (e) {
      return 0;
    }
  };
  const getGstRate = (rate) => {
    const hsnPercentage = hsnList.filter((item) => item.id == rate)[0];
    console.log("gst Rate", hsnPercentage?.taxRatePercentage);
    return hsnPercentage?.taxRatePercentage;
  };

  return (
    <>
      <Backdrop open={openBackdrop} />

      <ToastContainer limit={1} />
      <BackdropComponent open={backdropState} />
      <Box sx={{ flexGrow: 1 }}>
        <Grid container>
          <Grid item={true} xs={12}>
            <StyledHeading>
              {pathname === "add" ? "Add New Equipment" : "Edit Equipment"}
            </StyledHeading>
            <StyledParagraph>
              Equipments |{" "}
              <span style={{ color: "rgb(166 166 166)" }}>
                {pathname === "add" ? "Add New Equipment" : "Edit Equipment"}
              </span>
            </StyledParagraph>
          </Grid>

          <StyledDiv>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={0}>
                <Grid item={true} sm={12} xs={12}>
                  <Grid item={true} xs={12} container spacing={1}>
                    <Grid item={true} md={4} xs={12}>
                      <StyledFormLabel htmlFor="name">
                        Equipment Name{" "}
                        {Boolean(errors.name) && (
                          <StyledRequired> *</StyledRequired>
                        )}
                      </StyledFormLabel>
                      <StyledTextField
                        fullWidth
                        id="name"
                        name="name"
                        value={values.name}
                        onChange={handleInputChange}
                        error={Boolean(errors.name)}
                        helperText={errors.name}
                        inputProps={{ maxLength: 80 }}
                      />
                    </Grid>

                    <Grid item={true} md={4} xs={12}>
                      <StyledFormLabel htmlFor="serviceId">
                        Category
                        {Boolean(errors.serviceId) && (
                          <StyledRequired> *</StyledRequired>
                        )}
                      </StyledFormLabel>
                      <StyledTextField
                        id="serviceId"
                        name="serviceId"
                        fullWidth
                        select
                        value={values.serviceId}
                        onChange={handleInputChange}
                        error={Boolean(errors.serviceId)}
                        helperText={errors.serviceId}
                      >
                        {subCategories.map((val) => {
                          return (
                            <MenuItem key={val.id} value={val.id}>
                              {val.name}
                            </MenuItem>
                          );
                        })}
                      </StyledTextField>
                    </Grid>

                    <Grid item={true} md={4} xs={12}>
                      <StyledFormLabel htmlFor="stock">
                        Quantity
                        {Boolean(errors.stock) && (
                          <StyledRequired> *</StyledRequired>
                        )}
                      </StyledFormLabel>
                      <StyledTextField
                        fullWidth
                        id="stock"
                        type="number"
                        name="stock"
                        value={values.stock}
                        onChange={handleInputChange}
                        error={Boolean(errors.stock)}
                        helperText={errors.stock}
                        inputProps={{ min: 0, maxLength: 10 }}
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                        }}
                      />
                    </Grid>
                    <Grid item={true} xs={12}>
                      <StyledFormLabel htmlFor="description">
                        Equipment Description{" "}
                        {Boolean(errors.description) && (
                          <StyledRequired> *</StyledRequired>
                        )}
                      </StyledFormLabel>
                      <StyledTextField
                        fullWidth
                        id="description"
                        name="description"
                        multiline
                        rows={3}
                        value={values.description}
                        onChange={handleInputChange}
                        error={Boolean(errors.description)}
                        helperText={errors.description}
                        inputProps={{ maxLength: 500 }}
                      />
                    </Grid>

                    <Grid item={true} md={4} xs={12}>
                      <StyledFormLabel htmlFor="hsn">
                        HSN
                        {Boolean(errors.hsn) && (
                          <StyledRequired> *</StyledRequired>
                        )}
                      </StyledFormLabel>
                      <StyledTextField
                        fullWidth
                        select
                        id="hsn"
                        name="hsn"
                        value={values.hsn}
                        onChange={handleInputChange}
                        // onChange={getHsnPercentage}
                        error={Boolean(errors.hsn)}
                        helperText={errors.hsn}
                      >
                        {hsnList.map((list) => {
                          const { hsnCode, hsnName, id, taxRatePercentage } =
                            list;
                          return (
                            <MenuItem value={id}>
                              {hsnCode} - ({taxRatePercentage}%)
                            </MenuItem>
                          );
                        })}
                      </StyledTextField>
                    </Grid>
                    <Grid item={true} md={4} xs={12}>
                      <StyledFormLabel htmlFor="mrp">
                        MRP{" "}
                        {Boolean(errors.mrp) && (
                          <StyledRequired> *</StyledRequired>
                        )}
                      </StyledFormLabel>
                      <StyledTextField
                        fullWidth
                        type="number"
                        id="mrp"
                        name="mrp"
                        value={values.mrp}
                        onChange={handleInputChange}
                        error={Boolean(errors.mrp)}
                        helperText={errors.mrp}
                        inputProps={{ min: 0, step: 0.01, maxLength: 10 }}
                        onKeyDown={(e) => {
                          if (
                            e.key === "+" ||
                            e.key === "-" ||
                            e.key === "e" ||
                            e.key === "E"
                          ) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </Grid>

                    <Grid item={true} md={4} xs={12}>
                      <StyledFormLabel htmlFor="basePrice">
                        Base Price
                        {Boolean(errors.basePrice) && (
                          <StyledRequired> *</StyledRequired>
                        )}
                      </StyledFormLabel>
                      <StyledTextField
                        fullWidth
                        type="number"
                        id="basePrice"
                        name="basePrice"
                        value={values.basePrice}
                        onChange={handleInputChange}
                        error={Boolean(errors.basePrice)}
                        helperText={errors.basePrice}
                        inputProps={{ min: 0, step: 0.01, maxLength: 10 }}
                        onKeyDown={(e) => {
                          if (
                            e.key === "+" ||
                            e.key === "-" ||
                            e.key === "e" ||
                            e.key === "E"
                          ) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </Grid>

                    <Grid item md={4} xs={12}>
                      <StyledFormLabel htmlFor="taxAmount ">
                        Tax Amount
                        {Boolean(errors.taxAmount) && (
                          <StyledRequired> *</StyledRequired>
                        )}
                      </StyledFormLabel>
                      <StyledTextField
                        InputProps={{ readOnly: true }}
                        fullWidth
                        type="number"
                        id="taxAmount "
                        name="taxAmount "
                        value={values.taxAmount}
                        onChange={handleInputChange}
                        error={Boolean(errors.taxAmount)}
                        helperText={errors.taxAmount}
                      />
                    </Grid>
                    <Grid item={true} md={4} xs={12}>
                      <StyledFormLabel htmlFor="actualPrice">
                        Final Price
                        {Boolean(errors.actualPrice) && (
                          <StyledRequired> *</StyledRequired>
                        )}
                      </StyledFormLabel>
                      <StyledTextField
                        InputProps={{ readOnly: true }}
                        fullWidth
                        type="number"
                        id="actualPrice"
                        name="actualPrice"
                        value={values.actualPrice}
                        onChange={handleInputChange}
                        error={Boolean(errors.actualPrice)}
                        helperText={errors.actualPrice}
                        inputProps={{ min: 0, step: 0.01, maxLength: 10 }}
                        onKeyDown={(e) => {
                          if (
                            e.key === "+" ||
                            e.key === "-" ||
                            e.key === "e" ||
                            e.key === "E"
                          ) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container item={true} xs={12} spacing={2}>
                  <Grid item={true} md={6} sm={6} xs={12}>
                    <StyledImageUploadWrapper>
                      <label htmlFor="image-upload">
                        <StyledInput
                          accept="image/*"
                          id="image-upload"
                          type="file"
                          multiple
                          onChange={handleImageItem}
                          onClick={(event) => {
                            event.currentTarget.value = null;
                          }}
                        />
                        <StyledIconWrapper>
                          <img src={camera} alt="Camera" />
                        </StyledIconWrapper>
                      </label>
                      <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
                        Add Photo
                      </Typography>
                      <Typography
                        sx={{
                          color: "rgba(15, 15, 15, 0.5)",
                          mx: 2,
                          fontSize: "12px",
                        }}
                      >
                        Allowed *.jpeg, *.jpg, *.png, max size of 3.1 MB
                      </Typography>
                    </StyledImageUploadWrapper>
                    <Grid container item={true} xs={12}>
                      {files.map((file, id) => {
                        return (
                          <StyledIconWrapper
                            sx={{
                              position: "relative",
                              margin: "25px 5px 5px 5px",
                              width: "147px",
                              height: "140px",
                              backgroundImage: `url(${file.image})`,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "cover",
                            }}
                            key={id + 1}
                            onClick={() => {
                              setIconFlag(id);
                            }}
                          >
                            <IconButton
                              size="large"
                              sx={{
                                position: "absolute",
                                right: "2%",
                                top: "3%",
                                width: "36px",
                                height: "36px",
                                background: "rgba(15, 15, 15, 0.5)",
                                boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                                borderRadius: "10px",

                                color: "white",
                                "&:hover": {
                                  backgroundColor: "#0957DD",
                                },
                              }}
                            >
                              <CloseIcon
                                sx={{ width: "20px", height: "20px" }}
                                onClick={() => {
                                  deleteImage(id, file);
                                }}
                              />
                            </IconButton>
                          </StyledIconWrapper>
                        );
                      })}
                    </Grid>
                  </Grid>
                  <Grid container item={true} md={6} sm={6} xs={12}>
                    <Grid style={{ alignItems: "center", paddingTop: "10px" }}>
                      {specificationarr.map((item, i) => {
                        return (
                          <Grid
                            key={i + 1}
                            container
                            item={true}
                            spacing={2}
                            xs={12}
                          >
                            <Grid
                              item={true}
                              xs={specificationarr.length - 1 === i ? 4 : 6}
                            >
                              <StyledTextField
                                name="specificKey"
                                label="Specification Key"
                                variant="outlined"
                                size="small"
                                type="text"
                                fullWidth
                                id={i}
                                onChange={handleSpecificationChange}
                                value={item.specificKey}
                                inputProps={{ maxLength: 50 }}
                              />
                            </Grid>
                            <Grid
                              item={true}
                              xs={specificationarr.length - 1 === i ? 4 : 6}
                            >
                              <StyledTextField
                                name="specificValue"
                                label="Specification Value"
                                variant="outlined"
                                size="small"
                                type="text"
                                id={i}
                                fullWidth
                                onChange={handleSpecificationChange}
                                value={item.specificValue}
                                inputProps={{ maxLength: 50 }}
                              />
                            </Grid>
                            {specificationarr.length - 1 === i ? (
                              <Grid
                                item={true}
                                xs={specificationarr.length > 1 ? 4 : 2}
                              >
                                <Button
                                  type="button"
                                  style={{ background: "#0957DD" }}
                                  variant="contained"
                                  sx={{ mt: 1.3 }}
                                  onClick={addClick}
                                >
                                  <AddIcon />
                                </Button>
                                {specificationarr.length > 1 ? (
                                  <Button
                                    type="button"
                                    variant="contained"
                                    sx={{ mt: 1.3, ml: 1 }}
                                    style={{
                                      background: "#F0F0F0",
                                    }}
                                    onClick={() => {
                                      deleteSelectedspecification(item);
                                    }}
                                  >
                                    <DeleteIcon style={{ color: "red" }} />
                                  </Button>
                                ) : (
                                  ""
                                )}
                              </Grid>
                            ) : (
                              ""
                            )}
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid xs={12} item={true} style={{ marginTop: "25px" }}>
                <Link
                  style={{
                    textDecoration: "none",
                  }}
                  to="/equipments"
                >
                  <Button
                    type="button"
                    variant="contained"
                    sx={{
                      mb: 2,
                      px: 6,
                      backgroundColor: "white",
                      color: "black",
                      border: "1px solid #0F0F0F",
                      borderRadius: "10px",
                      padding: "10px 25px",
                      "&:hover": {
                        backgroundColor: "#ffffff",
                      },
                    }}
                  >
                    Cancel
                  </Button>
                </Link>

                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    mb: 2,
                    px: 6,
                    ml: 2,
                    backgroundColor: "#0957DD",
                    color: "white",
                    height: "48px",
                    borderRadius: "10px",
                    padding: "10px 25px",
                    "&:hover": {
                      backgroundColor: "#0957DD",
                    },
                  }}
                >
                  {pathname === "add" ? "Add Equipment" : "Save Equipment"}
                </Button>
              </Grid>
            </form>
          </StyledDiv>
        </Grid>
      </Box>
    </>
  );
};
export default AddEquipment;
