import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { FormGroup, FormControlLabel, Checkbox, colors } from "@mui/material";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Box,
  Typography,
  IconButton,
  TextField,
  FormLabel,
  MenuItem,
  Input,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Close as CloseIcon } from "@mui/icons-material";

import camera from "../../Assets/camera.svg";
import { useForm } from "../../Utils/useForm";

import {
  categoryListApiDropdown,
  addServicesApi,
  updateServiceApi,
  serviceDetailsApi,
} from "../../state/actions/serviceAction";

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
}));

const formControlLabelStyles = {
  ".MuiFormControlLabel-label": {
    display: "block",
    fontWeight: "bold",
    color: "black",
  },
};
const StyledTextField = styled(TextField)(() => ({
  marginTop: "10px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
  },
  ".css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
    padding: "14px",
  },
}));
const StyledImageUploadWrapper = styled(Box)(() => ({
  marginTop: "16px",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  height: "206px",
  borderRadius: "10px",
  textAlign: "center",
}));

const StyledIconWrapper = styled(Box)(() => ({
  position: "relative",

  margin: "20px auto",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  borderRadius: "10px",
  backgroundColor: "rgba(15, 15, 15, 0.05)",
  width: "80px",
  height: "80px",
  img: {
    marginTop: "30px",
  },
}));

const StyledInput = styled("input")({
  display: "none",
});
const StyledRequired = styled("span")({
  color: "red",
  marginLeft: "2px",
  fontSize: "20px",
});

const StyledRemoveButton = styled(Box)({
  color: "red",
  display: "inline",
  "&:hover": {
    cursor: "pointer",
  },
});

const initialValues = {
  name: "",
  categoryId: "",
  status: "",
  originalPriceShiftDay: 0,
  salePriceShiftDay: 0,
  originalPriceShiftNight: 0,
  salePriceShiftNight: 0,
  originalPrice24hr: 0,
  salePriceShift24hr: 0,
  description: "",
  availableFlag24hr: 1,
  availableFlagNight: 1,
  availableFlagDay: 1,
};

export default function AddServiceModel(props) {
  const { currentService, open, refreshScreen, handleClose } = props;
  const { action } = props;

  const dispatch = useDispatch();
  const [dropdownCategory, setDropdownCategory] = useState([]);
  const [isdisabled, setIsDisabled] = useState(false);
  const [image, setImage] = useState("");
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [isEqSelected, setIsEqSelected] = useState(false);
  const [sectionHide, setSectionHide] = useState("contents");

  const isAddMode = action === "add" ? true : false;
  const isEditMode = action === "edit" ? true : false;

  async function getCat() {
    const cat = await categoryListApiDropdown();
    setDropdownCategory(cat.data);
  }

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("name" in fieldValues)
      temp.name = fieldValues.name ? "" : "This field is required.";

    if ("categoryId" in fieldValues)
      temp.categoryId = fieldValues.categoryId ? "" : "This field is required.";

    if (fieldValues.categoryId !== 1) {
      if ("originalPriceShiftDay" in fieldValues) {
        if (/^\d+(\.\d+)*$/.test(fieldValues.originalPriceShiftDay)) {
          if (parseInt(fieldValues.originalPriceShiftDay) === 0) {
            temp.originalPriceShiftDay = "Price should not be 0";
          } else {
            temp.originalPriceShiftDay = "";
          }
        } else {
          temp.originalPriceShiftDay = "This field is required.";
        }
      }

      if ("salePriceShiftDay" in fieldValues) {
        if (/^\d+(\.\d+)*$/.test(fieldValues.salePriceShiftDay)) {
          if (parseInt(fieldValues.salePriceShiftDay) === 0) {
            temp.salePriceShiftDay = "Price should not be 0";
          } else {
            temp.salePriceShiftDay = "";
          }
        } else {
          temp.salePriceShiftDay = "This field is required.";
        }
      }

      if ("originalPriceShiftNight" in fieldValues) {
        if (/^\d+(\.\d+)*$/.test(fieldValues.originalPriceShiftNight)) {
          if (parseInt(fieldValues.originalPriceShiftNight) === 0) {
            temp.originalPriceShiftNight = "Price should not be 0";
          } else {
            temp.originalPriceShiftNight = "";
          }
        } else {
          temp.originalPriceShiftNight = "This field is required.";
        }
      }

      if ("salePriceShiftNight" in fieldValues) {
        if (/^\d+(\.\d+)*$/.test(fieldValues.salePriceShiftNight)) {
          if (parseInt(fieldValues.salePriceShiftNight) === 0) {
            temp.salePriceShiftNight = "Price should not be 0";
          } else {
            temp.salePriceShiftNight = "";
          }
        } else {
          temp.salePriceShiftNight = "This field is required.";
        }
      }

      if ("originalPrice24hr" in fieldValues) {
        if (/^\d+(\.\d+)*$/.test(fieldValues.originalPrice24hr)) {
          if (parseInt(fieldValues.originalPrice24hr) === 0) {
            temp.originalPrice24hr = "Price should not be 0";
          } else {
            temp.originalPrice24hr = "";
          }
        } else {
          temp.originalPrice24hr = "This field is required.";
        }
      }

      if ("salePriceShift24hr" in fieldValues) {
        if (/^\d+(\.\d+)*$/.test(fieldValues.salePriceShift24hr)) {
          if (parseInt(fieldValues.salePriceShift24hr) === 0) {
            temp.salePriceShift24hr = "Price should not be 0";
          } else temp.salePriceShift24hr = "";
        } else {
          temp.salePriceShift24hr = "This field is required.";
        }
      }
    }
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const closePopup = () => {
    resetForm();
    handleClose();
    setIsDisabled(false);
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);
  console.log("services-->", values, typeof values.status);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const toastType = {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    };
    try {
      if (values.image && validate()) {
        let formData = new FormData();
        Object.keys(values).map((key) => {
          formData.append(key, values[key]);
        });

        let res;
        if (currentService && currentService !== -1) {
          setOpenBreakdrop(true);
          res = await updateServiceApi(values.id, formData);
        } else {
          setOpenBreakdrop(true);
          formData.delete("status");
          res = await addServicesApi(formData);
        }

        if (res !== undefined && res.status === true) {
          setOpenBreakdrop(false);
          setIsDisabled(true);
          closePopup();
          toast.success(res.message, toastType);
          refreshScreen();
        } else if (res !== undefined && res.status === false) {
          setOpenBreakdrop(false);
          toast.error(res.message, toastType);
        }
      } else {
        if (!values.image) {
          toast.error("Please select image", toastType);
        }
      }
    } catch (err) {}
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  async function handleImageItem(e) {
    const selected = e.target.files[0];
    const ALLOWED_TYPES = ["image/png", "image/jpg", "image/jpeg"];
    if (selected && ALLOWED_TYPES.includes(selected.type)) {
      const base64String = await getBase64(selected);
      setImage(base64String);
      setValues({ ...values, image: selected });
    } else {
    }
  }

  const resetImage = () => {
    setImage("");
  };

  const removeImage = () => {
    resetImage();
    setValues((previousValues) => {
      var newValues = { ...previousValues };
      delete newValues["image"];
      return newValues;
    });
  };

  async function getServiceDetails() {
    setOpenBreakdrop(true);
    const Subcat = await serviceDetailsApi(currentService.id);
    if (Subcat.data && Subcat) {
      setOpenBreakdrop(false);

      var data = Subcat.data;
      if ("availableFlag24hr" in data && data["availableFlag24hr"] === null) {
        data["availableFlag24hr"] = 0;
      }
      if ("availableFlagDay" in data && data["availableFlagDay"] === null) {
        data["availableFlagDay"] = 0;
      }
      if ("availableFlagNight" in data && data["availableFlagNight"] === null) {
        data["availableFlagNight"] = 0;
      }

      setValues(data);
      setImage(data.image);
      if (Subcat.data.categoryId === 1) {
        setIsEqSelected(!isEqSelected);
        setSectionHide("none");
      } else {
        setIsEqSelected(false);
        setSectionHide("contents");
      }
    } else {
      setOpenBreakdrop(false);
    }
  }

  useEffect(() => {
    if (currentService && currentService !== -1) {
      getServiceDetails();
    }
    getCat();
  }, []);
  const handleCheckedValues = (e) => {
    const { name, checked } = e.target;
    setValues({ ...values, [name]: checked ? 0 : 1 });
  };

  return (
    <>
      <Dialog open={open} fullWidth={true} maxWidth="lg">
        <DialogTitle
          sx={{
            fontWeight: "bold",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {isEditMode ? "Edit " : isAddMode ? "Add " : ""}
          Category
          {/* Add Category */}
          <Tooltip title="Close">
            <IconButton onClick={() => closePopup()}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>

        <DialogContent>
          <form onSubmit={handleSubmit} id="addNewService">
            <Grid container sm={12} xs={12} spacing={2}>
              <Grid item md={4} container sm={12} xs={12}>
                <Grid item md={12} xs={12}>
                  <StyledImageUploadWrapper>
                    <label htmlFor="image-upload">
                      <StyledInput
                        accept="image/*"
                        id="image-upload"
                        type="file"
                        onChange={handleImageItem}
                        onClick={(event) => {
                          event.currentTarget.value = null;
                        }}
                      />
                      <StyledIconWrapper
                        sx={
                          image == ""
                            ? {}
                            : {
                                backgroundImage: `url(${image})`,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "cover",
                              }
                        }
                      >
                        {image === "" && <img src={camera} alt="Camera" />}
                      </StyledIconWrapper>
                    </label>

                    <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
                      {isEditMode ? "Update" : isAddMode ? "Add" : ""}
                      Photo
                    </Typography>
                    <Typography
                      sx={{
                        color: "rgba(15, 15, 15, 0.5)",
                        mx: 2,
                        fontSize: "12px",
                      }}
                    >
                      Allowed *.jpeg, *.jpg, *.png, max size of 3.1 MB
                    </Typography>
                    {image != "" && (
                      <StyledRemoveButton
                        onClick={(e) => {
                          e.preventDefault();
                          removeImage();
                        }}
                      >
                        Remove
                      </StyledRemoveButton>
                    )}
                  </StyledImageUploadWrapper>
                </Grid>
              </Grid>
              <Grid item md={8} sm={12} xs={12} container>
                <Grid item sx={{ m: 1 }} md={5.5} sm={5.5} xs={12}>
                  <StyledFormLabel htmlFor="categoryId">
                    Service
                    {Boolean(errors.categoryId) && (
                      <StyledRequired> * </StyledRequired>
                    )}
                  </StyledFormLabel>
                  <StyledTextField
                    id="categoryId"
                    name="categoryId"
                    fullWidth
                    select
                    value={values.categoryId}
                    // onChange={handleInputChange}
                    onChange={(e) => {
                      const serviceData = e.target.value;
                      if (serviceData === 1) {
                        setIsEqSelected(!isEqSelected);
                        setSectionHide("none");
                      } else {
                        setIsEqSelected(false);
                        setSectionHide("contents");
                      }
                      handleInputChange(e);
                    }}
                    error={Boolean(errors.categoryId)}
                    helperText={errors.categoryId}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {dropdownCategory.map((val) => {
                      return (
                        <MenuItem key={val.id} value={val.id}>
                          {val.name}
                        </MenuItem>
                      );
                    })}
                  </StyledTextField>
                </Grid>
                <Grid item sx={{ m: 1 }} md={5.5} sm={5.5} xs={12}>
                  <StyledFormLabel htmlFor="name">
                    Category Name
                    {Boolean(errors.name) && (
                      <StyledRequired> * </StyledRequired>
                    )}
                  </StyledFormLabel>
                  <StyledTextField
                    fullWidth
                    id="name"
                    name="name"
                    value={values.name}
                    placeholder="Enter Category Name"
                    onChange={handleInputChange}
                    error={Boolean(errors.name)}
                    helperText={errors.name}
                    inputProps={{ maxLength: 80 }}
                    onKeyPress={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                  />
                </Grid>

                {isEditMode && (
                  <Grid item container xs={12}>
                    <Grid item sx={{ m: 1 }} xs={5.5}>
                      <StyledFormLabel htmlFor="status">
                        Status
                        {Boolean(errors.status) && (
                          <StyledRequired> * </StyledRequired>
                        )}
                      </StyledFormLabel>
                      <StyledTextField
                        fullWidth
                        id="status"
                        name="status"
                        select
                        value={values?.status}
                        onChange={handleInputChange}
                        error={Boolean(errors.status)}
                        helperText={errors.status}
                        inputProps={{ maxLength: 80 }}
                      >
                        <MenuItem value={1}>Active</MenuItem>
                        <MenuItem value={2}>InActive</MenuItem>
                        <MenuItem value={3}>Deleted</MenuItem>
                      </StyledTextField>
                    </Grid>
                  </Grid>
                )}

                <div style={{ display: sectionHide }}>
                  <Grid item sx={{ m: 1 }} md={2}>
                    <FormGroup row>
                      <FormControlLabel
                        control={<Checkbox />}
                        name="availableFlagDay"
                        id="availableFlagDay"
                        label="Availability"
                        sx={formControlLabelStyles}
                        checked={values.availableFlagDay === 0 ? true : false}
                        onChange={handleCheckedValues}
                        labelPlacement="top"
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item sx={{ m: 1 }} md={4.5} xs={12}>
                    <StyledFormLabel htmlFor="originalPriceShiftDay">
                      Day Shift Original Price
                    </StyledFormLabel>
                    <StyledTextField
                      fullWidth
                      type="number"
                      id="originalPriceShiftDay"
                      name="originalPriceShiftDay"
                      placeholder="Shift Day Original Price"
                      value={
                        values.originalPriceShiftDay === 0
                          ? ""
                          : values.originalPriceShiftDay
                      }
                      onChange={handleInputChange}
                      error={Boolean(errors.originalPriceShiftDay)}
                      helperText={errors.originalPriceShiftDay}
                      inputProps={{ maxLength: 10 }}
                      disabled={isEqSelected}
                      onKeyPress={(e) => {
                        ["Enter", "e", "."].includes(e.key) &&
                          e.preventDefault();
                      }}
                    />
                  </Grid>
                  <Grid item sx={{ m: 1 }} md={4.5} xs={12}>
                    <StyledFormLabel htmlFor="salePriceShiftDay">
                      Day Shift Sale Price
                    </StyledFormLabel>

                    <StyledTextField
                      fullWidth
                      id="salePriceShiftDay"
                      type="number"
                      name="salePriceShiftDay"
                      placeholder="Shift Day Sale Price"
                      value={
                        values.salePriceShiftDay === 0
                          ? ""
                          : values.salePriceShiftDay
                      }
                      onChange={handleInputChange}
                      error={Boolean(errors.salePriceShiftDay)}
                      helperText={errors.salePriceShiftDay}
                      inputProps={{ maxLength: 10 }}
                      disabled={isEqSelected}
                      onKeyPress={(e) => {
                        ["Enter", "e", "."].includes(e.key) &&
                          e.preventDefault();
                      }}
                    />
                  </Grid>

                  <Grid item sx={{ m: 1 }} md={2}>
                    <FormGroup row>
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Availability"
                        name="availableFlagNight"
                        id="availableFlagNight"
                        sx={formControlLabelStyles}
                        checked={values.availableFlagNight === 0 ? true : false}
                        onChange={handleCheckedValues}
                        labelPlacement="top"
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item sx={{ m: 1 }} md={4.5} xs={12}>
                    <StyledFormLabel htmlFor="originalPriceShiftNight">
                      Night Slot Original Price
                    </StyledFormLabel>
                    <StyledTextField
                      fullWidth
                      id="originalPriceShiftNight"
                      type="number"
                      name="originalPriceShiftNight"
                      placeholder="Shift Night Original Price"
                      value={
                        values.originalPriceShiftNight === 0
                          ? ""
                          : values.originalPriceShiftNight
                      }
                      onChange={handleInputChange}
                      error={Boolean(errors.originalPriceShiftNight)}
                      helperText={errors.originalPriceShiftNight}
                      inputProps={{ maxLength: 10 }}
                      disabled={isEqSelected}
                      onKeyPress={(e) => {
                        ["Enter", "e", "."].includes(e.key) &&
                          e.preventDefault();
                      }}
                    />
                  </Grid>
                  <Grid item sx={{ m: 1 }} md={4.5} xs={12}>
                    <StyledFormLabel htmlFor="salePriceShiftNight">
                      Night Slot Sale Price
                    </StyledFormLabel>
                    <StyledTextField
                      fullWidth
                      type="number"
                      id="salePriceShiftNight"
                      name="salePriceShiftNight"
                      placeholder="Shift Night Sale Price"
                      value={
                        values.salePriceShiftNight === 0
                          ? ""
                          : values.salePriceShiftNight
                      }
                      onChange={handleInputChange}
                      error={Boolean(errors.salePriceShiftNight)}
                      helperText={errors.salePriceShiftNight}
                      inputProps={{ maxLength: 10 }}
                      disabled={isEqSelected}
                      onKeyPress={(e) => {
                        ["Enter", "e", "."].includes(e.key) &&
                          e.preventDefault();
                      }}
                    />
                  </Grid>

                  <Grid item sx={{ m: 1 }} md={2}>
                    <FormGroup row>
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Availability"
                        name="availableFlag24hr"
                        id="availableFlag24hr"
                        sx={formControlLabelStyles}
                        checked={values.availableFlag24hr === 0 ? true : false}
                        onChange={handleCheckedValues}
                        labelPlacement="top"
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item sx={{ m: 1 }} md={4.5} xs={12}>
                    <StyledFormLabel htmlFor="originalPrice24hr">
                      24 Hour Slot Original Price
                    </StyledFormLabel>
                    <StyledTextField
                      fullWidth
                      type="number"
                      id="originalPrice24hr"
                      name="originalPrice24hr"
                      placeholder="24 Hour Original Price"
                      value={
                        values.originalPrice24hr === 0
                          ? ""
                          : values.originalPrice24hr
                      }
                      onChange={handleInputChange}
                      error={Boolean(errors.originalPrice24hr)}
                      helperText={errors.originalPrice24hr}
                      inputProps={{ maxLength: 10 }}
                      disabled={isEqSelected}
                      onKeyPress={(e) => {
                        ["Enter", "e", "."].includes(e.key) &&
                          e.preventDefault();
                      }}
                    />
                  </Grid>
                  <Grid item sx={{ m: 1 }} md={4.5} xs={12}>
                    <StyledFormLabel htmlFor="salePriceShift24hr">
                      24 Hour Slot Sale Price
                      {Boolean(errors.salePriceShift24hr) && (
                        <StyledRequired> * </StyledRequired>
                      )}
                    </StyledFormLabel>
                    <StyledTextField
                      fullWidth
                      type="number"
                      id="salePriceShift24hr"
                      name="salePriceShift24hr"
                      placeholder="24 Hour Sale Price"
                      value={
                        values.salePriceShift24hr === 0
                          ? ""
                          : values.salePriceShift24hr
                      }
                      onChange={handleInputChange}
                      error={Boolean(errors.salePriceShift24hr)}
                      helperText={errors.salePriceShift24hr}
                      inputProps={{ maxLength: 10 }}
                      disabled={isEqSelected}
                      onKeyPress={(e) => {
                        ["Enter", "e", "."].includes(e.key) &&
                          e.preventDefault();
                      }}
                    />
                  </Grid>
                  <Grid item sx={{ m: 1 }} md={11.3} xs={12}>
                    <StyledFormLabel htmlFor="description">
                      Description
                      {Boolean(errors.description) && (
                        <StyledRequired> * </StyledRequired>
                      )}
                    </StyledFormLabel>
                    <StyledTextField
                      fullWidth
                      id="description"
                      name="description"
                      placeholder="Description"
                      value={values.description}
                      onChange={handleInputChange}
                      error={Boolean(errors.description)}
                      helperText={errors.description}
                      inputProps={{ maxLength: 500 }}
                      multiline
                      maxRows={4}
                      // onKeyPress={(e) => {
                      //     e.key === "Enter" && e.preventDefault();
                      // }}
                    />
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions sx={{ margin: "15px" }}>
          <Button
            sx={{
              backgroundColor: "white",
              color: "black",
              border: "1px solid #0F0F0F80",
              borderRadius: "10px",
              padding: "10px 25px",
              "&:hover": {},
            }}
            onClick={() => closePopup()}
          >
            Cancel
          </Button>

          <Button
            sx={{
              backgroundColor: "#0957DD",
              color: "white",
              borderRadius: "10px",
              padding: "10px 25px",
              "&:hover": {
                backgroundColor: "#0957DD",
              },
            }}
            disabled={isdisabled}
            type="submit"
            form="addNewService"
          >
            {isEditMode ? "Save" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
