import handleAuthToken from "../../Utils/handleAuthToken";
import axios from "axios";

export const equipmentsListApi = async (queryString) => {
  if (localStorage.token) {
    handleAuthToken(localStorage.token);
  }

  try {
    const res = await axios.get("/admin/av1/listEquipment" + queryString);
    return res.data;
  } catch (error) {
    return { success: false, message: error.response.data.message };
  }
};

export const getAllServices = async (id) => {
  if (localStorage.token) {
    handleAuthToken(localStorage.token);
  }

  try {
    const res = await axios.get("/admin/av1/serviceListDoropDown/" + id);
    return res.data;
  } catch (error) {
    return { success: false, message: error.response.data.message };
  }
};

export const getHsnListDropdown = async (queryString) => {
  if (localStorage.token) {
    handleAuthToken(localStorage.token);
  }

  try {
    const res = await axios.get("/admin/av1/hsnListDrop");
    return res.data;
  } catch (error) {
    return { success: false, message: error.response.data.message };
  }
};

export const addEquipmentApi = async (formdata) => {
  if (localStorage.token) {
    handleAuthToken(localStorage.token);
  }

  try {
    const res = await axios.post(`/admin/av1/addProduct`, formdata);
    return res.data;
  } catch (error) {
    return { success: false, message: error.response.data.message };
  }
};

export const equipmentDetailsApi = async (id) => {
  try {
    const res = await axios.get(`/admin/av1/detailEquipment/${id}`);
    return res.data;
  } catch (error) {
    return { success: false, message: error.response.data.message };
  }
};

export const editEquipmentApi = async (id, formdata) => {
  if (localStorage.token) {
    handleAuthToken(localStorage.token);
  }

  try {
    const res = await axios.post(`/admin/av1/editProduct/${id}`, formdata);
    return res.data;
  } catch (error) {
    return { success: false, message: error.response.data.message };
  }
};

export const deleteSelectedImageApi = async (data) => {
  if (localStorage.token) {
    handleAuthToken(localStorage.token);
  }
  try {
    const res = await axios.post(`/admin/av1/removeImage`, data);
    return res.data;
  } catch (error) {
    return { success: false, message: error.response.data.message };
  }
};
export const deleteSelectedspecificationApi = async (data) => {
  if (localStorage.token) {
    handleAuthToken(localStorage.token);
  }
  try {
    const res = await axios.post(`/admin/av1/removeSpecification`, data);
    return res.data;
  } catch (error) {
    return { success: false, message: error.response.data.message };
  }
};

export const deleteEquipmentApi = async (id) => {
  if (localStorage.token) {
    handleAuthToken(localStorage.token);
  }
  try {
    const res = await axios.delete(`/admin/av1/deleteEquipment/${id}`);
    return res.data;
  } catch (error) {
    return { success: false, message: error.response.data.message };
  }
};
