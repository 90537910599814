import * as React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Card, DialogContentText } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import moment from "moment";
import { Grid, Button, Stack, Typography, IconButton } from "@mui/material";
import Avatar from "@mui/material/Avatar";
// import avtarimage from "../../assets/Rectangle752.png";
import { Routes, Route, useParams, Link, useLocation } from "react-router-dom";
import product_image from "../../Assets/Rectangle_glass.png";
import {
  Close as CloseIcon,
  MoreVert as MoreVertIcon,
  Visibility as VisibilityIcon,
  Check as CheckIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import {
  Toolbar,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import {
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  Divider,
} from "@mui/material";
import { TableContainer } from "@mui/material";
import { orderBookingDetailsApi } from "../../state/actions/orderAction";
import {
  nursesAvailableListApi,
  assignNurseCaregive,
  removeNurseApi,
} from "../../state/actions/nursesAction";
import Backdrop from "../BackDrop/BackDrop";
import { toast } from "react-toastify";
import "./styles/details.css";
import { borderRadius } from "@mui/system";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import AssignedNurseDetails from "./AssignedNurseDetails";

const strong = {
  color: "black",
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: "#0F0F0F80",
}));
const FlexBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
}));

const StyledChip = styled(Chip)(() => ({
  borderRadius: "10px",
  fontSize: "14px",
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "10px",
  border: "1px solid grey",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  // width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    paddingRight: "15px",
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

export default function NursesDetails() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [orderDetails, setorderDetails] = useState({});
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [nurseId, setNurseId] = useState("");
  const [assignSelectedNurse, setAssignSelectedNurse] = useState();

  var id = "";
  var parent = "";

  id = state?.orderId;
  parent = state?.parent;

  const getOrderDetails = async (id) => {
    const body = {
      orderId: id,
    };
    setOpenBreakdrop(true);
    const res = await orderBookingDetailsApi(body.orderId);
    if (res && res.status && res.data) {
      console.log(res.data);
      setorderDetails(res.data);
      setOpenBreakdrop(false);
    } else if (!res.data) {
      setOpenBreakdrop(false);
      toast.error(res.message);
    }
  };

  useEffect(() => {
    getOrderDetails(id);
  }, [id]);

  const [assignedModal, setAssignedModal] = useState(false);
  const openAssignedModal = () => {
    setAssignedModal(true);
  };
  const closeAssignedModal = () => {
    setAssignedModal(false);
  };
  const [confirmation, setConfirmation] = useState(false);
  const openConfirmationModal = (id) => {
    setAction(modes.assign);
    setAssignSelectedNurse(id);
    setConfirmation(true);
  };
  const closeConfirmationModal = () => {
    setAction("");
    setConfirmation(false);
  };

  //  we have only 2 actions , assign nurse and remove nurse.
  //  we are trying to reuse confirmation modal.

  const modes = {
    assign: "assign",
    remove: "remove",
  };
  const [action, setAction] = useState("");

  const selectNurseId = (nurseId) => {
    console.log("nurseId ------- ", nurseId);
    setNurseId(nurseId);
  };

  useEffect(() => {
    if (nurseId !== "") {
      openNurseDetailsModal(true);
    }
  }, [nurseId]);

  const [nurseDetails, setNurseDetails] = useState(false);
  const openNurseDetailsModal = () => {
    setNurseDetails(true);
  };
  const closeNurseDetailsModal = () => {
    setNurseDetails(false);
    setNurseId("");
    // to fetch the latest list
    // getNurseList()
  };
  const [nursesList, setNursesList] = useState([]);

  const getNurseList = async () => {
    setOpenBreakdrop(true);
    const body = {
      orderId: id,
    };
    const res = await nursesAvailableListApi(body.orderId);
    if (res && res.status && res.data) {
      setNursesList(res.data);
      setOpenBreakdrop(false);
      setAssignedModal(true);
    } else if (!res.data) {
      setOpenBreakdrop(false);
      setAssignedModal(true);
      toast.error(res.message);
    }
  };
  useEffect(() => {
    if (assignedModal) {
      getNurseList();
    }
  }, [assignedModal]);

  const [searchValue, setSearchValue] = useState("");
  const isEmpty = (value) => {
    if (value === null || value === undefined) return "-";
    return value.trim().length === 0 ? "-" : value;
  };

  const getSlots = (status) => {
    const statusColors = {
      1: {
        styles: {
          // backgroundColor: "rgb(23 163 66 / 18%)",
          backgroundColor: "#EEA47FFF",
          color: "white",
          fontWeight: "600",
        },
        title: "Morning",
      },
      2: {
        styles: {
          // backgroundColor: "rgba(0, 0, 0, 0.08)",
          backgroundColor: "#CCF381",
          color: "black",
          fontWeight: "600",
        },
        title: "Evening",
      },
      3: {
        styles: {
          backgroundColor: "#ADD8E6",
          color: "black",
          fontWeight: "600",
        },
        title: "24 Hours",
      },
    };
    return (
      <StyledChip
        sx={statusColors[status]?.styles}
        label={statusColors[status]?.title}
      />
    );
  };

  const confirmAssignNurse = async () => {
    const body = {
      nurseId: assignSelectedNurse,
      bookingId: id,
    };
    setOpenBreakdrop(true);
    const assignNurse = await assignNurseCaregive(body);
    if (assignNurse && assignNurse.status) {
      getOrderDetails(id);
      setAssignedModal(false);
      setOpenBreakdrop(false);
    } else {
      setConfirmation(false);
    }
    closeConfirmationModal();
  };

  const getBookingStatus = (status) => {
    const statusColors = {
      1: {
        styles: {
          // backgroundColor: "rgba(18, 183, 106, 0.05)",
          color: "#12B76A",
        },
        title: "Alloted",
      },

      2: {
        styles: {
          // backgroundColor: "rgba(240, 68, 56, 0.05)",
          color: "#097ee3",
        },
        title: "Pending",
      },
      3: {
        title: "Approved",
        styles: {
          color: "green",
        },
      },
      4: {
        styles: {
          // backgroundColor: "rgba(240, 68, 56, 0.05)",
          color: "#F04438;",
        },
        title: "Cancelled",
      },
      5: {
        styles: {
          // backgroundColor: "rgba(240, 68, 56, 0.05)",
          color: "#F04438;",
        },
        title: "Completed",
      },
    };
    return (
      <StyledChip
        sx={statusColors[status]?.styles}
        label={statusColors[status]?.title}
      />
    );
  };

  const removeNurse = async (id) => {
    const toastType = {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    };

    const body = {
      bookingId: id,
    };
    setOpenBreakdrop(true);
    const res = await removeNurseApi(body);
    if (res && res.status) {
      setOpenBreakdrop(false);
      closeConfirmationModal();
      getOrderDetails(id);
    } else {
      toast.error(res.message, toastType);
    }
  };

  const getGender = (value) => {
    console.log("GENDER", value);
    const genders = {
      1: "Male",
      2: "Female",
      3: "Others",
    };
    return genders[value] ? genders[value] : "-";
  };
  const getPaymentStatus = (id) => {
    const status = {
      0: {
        label: "Failed",
        stylesheet: {
          backgroundColor: "rgb(240 68 56 / 15%)",
          color: "#e90000",
        },
      },
      1: {
        label: "Success",
        styleSheet: {
          backgroundColor: "#28a74538",
          color: "#28a745",
        },
      },
      2: {
        label: "Pending",
        stylesheet: {
          backgroundColor: "rgb(235 193 14 / 24%)",
          color: "orange",
        },
      },
      3: {
        label: "Declined",
        stylesheet: {
          backgroundColor: "rgb(115 56 240 / 8%)",
          color: "#ffc107;",
        },
      },
    };
    if (status[id] === undefined) return "-";
    const { label, stylesheet } = status[id];
    return <StyledChip sx={stylesheet} label={label} />;
  };
  return (
    <>
      <Backdrop open={openBackdrop} />
      <div style={{ display: "flex", paddingRight: "5px" }}>
        <div
          className="back-btuoon-details-equip-order"
          onClick={() => {
            if (parent) {
              navigate(parent);
            } else {
              navigate("/nurses");
            }
          }}
        >
          <ArrowBackIosIcon style={{ width: "15px" }} />
          <span>Back</span>
        </div>
      </div>
      <Card
        style={{
          padding: "20px",
          borderRadius: "12px",
          marginTop: "20px",
        }}
      >
        <Grid container>
          <Grid item md={3} container>
            <Stack>
              <Grid item mb={4}>
                <b>Booking ID :</b>{" "}
                <span className="booking-id">
                  {orderDetails?.bookingNumber}
                </span>
              </Grid>
              <Grid item mb={2}>
                <b>User Details :</b>
              </Grid>
              <Grid container my={1}>
                <Grid item>
                  <Avatar src={orderDetails?.userDetails?.profile_image} />
                </Grid>
                <Stack mx={2} rowGap={1}>
                  <Grid item>{orderDetails?.userDetails?.fname} </Grid>
                  <Grid item style={{ wordBreak: "break-all" }}>
                    {orderDetails?.userDetails?.email}
                  </Grid>
                  <Grid item>{orderDetails?.userDetails?.mobile}</Grid>
                  <Grid item>
                    <span style={{ fontWeight: 600 }}>Gender : </span>
                    {getGender(orderDetails?.userDetails?.gender)}
                  </Grid>
                </Stack>
              </Grid>
            </Stack>
          </Grid>
          <Grid item md={5} container>
            <Stack>
              <Grid item mb={4}>
                &nbsp;
                <b>Order Date :</b>{" "}
                <span className="booking-id">
                  {moment(orderDetails?.createdAt).format("LL")}
                </span>
              </Grid>
              <Grid item mb={2}>
                <b>Family Details :</b>
              </Grid>
              <Grid container my={1}>
                <Grid item>
                  <Avatar src={orderDetails?.familyMenber?.profileImage} />
                </Grid>
                <Stack mx={2} rowGap={1}>
                  <Grid item>{orderDetails?.familyMenber?.name}</Grid>
                  <Grid item>{orderDetails?.familyMenber?.email}</Grid>
                  <Grid item>{orderDetails?.familyMenber?.mobile}</Grid>
                  <Grid item>
                    Relation :{" "}
                    <span className="patient-relation-title">
                      {orderDetails?.familyMenber?.RelationName}
                    </span>
                  </Grid>
                  <Grid item>
                    Health Condition :{" "}
                    <span className="patient-relation-title">
                      {orderDetails?.familyMenber?.healthDescription}
                    </span>
                  </Grid>
                </Stack>
              </Grid>
            </Stack>
          </Grid>
          <Grid item md={4} container justifyContent="flex-end">
            <Stack>
              <Grid item mb={4}>
                {orderDetails?.nurseId == 0 &&
                orderDetails?.paymentStatus != 0 ? (
                  <Button
                    onClick={() => {
                      getNurseList();
                    }}
                    variant="contained"
                  >
                    Assign Nurse
                  </Button>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item mb={2}>
                <b>Delivery Address :</b>
              </Grid>
              {/* <Grid item>{data.deliveryAddress.userId}</Grid> */}
              <Grid item>{orderDetails?.deliveryAddress?.name}</Grid>
              <Grid item>{orderDetails?.deliveryAddress?.address1}</Grid>
              <Stack flexDirection="row" alignItems="baseline">
                <Grid item>{orderDetails?.deliveryAddress?.area}, &nbsp;</Grid>
                <Grid item>
                  {orderDetails?.deliveryAddress?.landmark} &nbsp;
                </Grid>
              </Stack>
              <Stack flexDirection="row" alignItems="baseline">
                <Grid item>
                  {orderDetails?.deliveryAddress?.stateName}, &nbsp;
                </Grid>
                <Grid item>
                  {" "}
                  {orderDetails?.deliveryAddress?.cityName}, &nbsp;
                </Grid>
                <Grid item>
                  {" "}
                  {orderDetails?.deliveryAddress?.pincode} &nbsp;
                </Grid>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
        {orderDetails?.nurseId > 0 ? (
          <>
            <Divider />
            <Grid container my={2} flexDirection="column">
              <Grid item display="flex" justifyContent="space-between">
                <b>Nurse Details : </b>
                <Tooltip title="Remove Nurse">
                  <IconButton
                    onClick={() => {
                      setAction(modes.remove);
                      setConfirmation(true);
                    }}
                    style={{ backgroundColor: "#c01515", color: "white" }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Stack flexDirection="row">
                <Grid container my={2} xs={3}>
                  <Grid item>
                    <b>Name </b>
                  </Grid>
                  <Grid container>
                    <Grid item>
                      <Avatar src={orderDetails?.nurseDetails?.profile_image} />
                    </Grid>
                    <Stack mx={2} rowGap={1}>
                      <Grid item>
                        {orderDetails?.nurseDetails?.fName}{" "}
                        {orderDetails?.nurseDetails?.lName}
                      </Grid>
                    </Stack>
                  </Grid>
                </Grid>
                <Grid container my={2} xs={3}>
                  <Grid item mb={1}>
                    <b>Mobile </b>
                  </Grid>
                  <Grid container>
                    <Grid item>
                      {orderDetails?.nurseDetails?.mobile || "N/A"}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container my={2} xs={3}>
                  <Grid item>
                    <b>Gender </b>
                  </Grid>
                  <Grid container>
                    <Grid item>
                      {orderDetails?.nurseDetails?.gender == 1
                        ? "Male"
                        : "Female" || "N/A"}{" "}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container my={2} xs={3}>
                  <Grid item>
                    <b>Experience </b>
                  </Grid>
                  <Grid container>
                    <Grid item>
                      &nbsp; {orderDetails?.nurseDetails?.experience || "N/A"}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container my={2} xs={3}>
                  <Grid item>
                    <b>Rating </b>
                  </Grid>
                  <Grid container>
                    <Grid item>
                      {orderDetails?.nurseDetails?.rating || "N/A"}{" "}
                    </Grid>
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
          </>
        ) : (
          ""
        )}
        <Divider />
        <Grid container my={2}>
          <Grid item mr={2}>
            <Grid item mb={2}>
              <b>Service </b>
            </Grid>
            <Grid container my={2}>
              <Grid item>
                <Avatar src={orderDetails?.category?.image} />
              </Grid>
              <Stack mx={2} rowGap={1}>
                <Grid item> {orderDetails?.category?.name}</Grid>
              </Stack>
            </Grid>
          </Grid>
          <Grid item mr={2}>
            <Grid item mb={2}>
              <b>Category </b>
            </Grid>
            <Grid container my={2}>
              <Grid item>
                <Avatar src={orderDetails?.service?.image} />
              </Grid>
              <Stack mx={2} rowGap={1}>
                <Grid item> {orderDetails?.service?.name}</Grid>
              </Stack>
            </Grid>
          </Grid>
          {orderDetails?.subCategory?.map((value, index) => {
            const { image, name } = value;
            return (
              <Grid item mr={2} key={index}>
                <Grid item mb={2}>
                  <b>SubCategory {index + 1}</b>
                </Grid>
                <Grid container my={2}>
                  <Grid item>
                    <Avatar src={image} />
                  </Grid>
                  <Stack mx={2} rowGap={1}>
                    <Grid item> {name}</Grid>
                  </Stack>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
        <Divider />
        <Grid container my={2}>
          <Grid item md={6} container>
            <Stack>
              <Grid item fontWeight="bold" mb={2}>
                Payment Details :
              </Grid>
              <Grid item>
                <b>BookingSubtotal: </b>Rs {orderDetails?.bookingSubtotal || 0}
              </Grid>
              <Grid item>
                <b>BookingTotal: </b>Rs {orderDetails?.bookingTotal || 0}
              </Grid>
            </Stack>
          </Grid>
          <Grid item md={6} container justifyContent="flex-end">
            <Stack mx={2} rowGap={2}>
              <Grid container alignItems="center">
                <Grid item>
                  <b>Payment Status</b> :
                </Grid>
                <Grid item mx={1}>
                  {getPaymentStatus(orderDetails?.paymentStatus)}
                </Grid>
              </Grid>
              <Grid container alignItems="center">
                <Grid item>
                  <b>Booking Status</b> :
                </Grid>
                <Grid item mx={1}>
                  {getBookingStatus(orderDetails.status)}
                </Grid>
              </Grid>
            </Stack>
          </Grid>
        </Grid>

        <Divider />
        <Grid container my={2}>
          <Grid item md={12} xs={12}>
            <b>Slots : </b>
          </Grid>
          {orderDetails?.slots?.map((value, index) => {
            return (
              <Grid item container xs="auto">
                <Stack
                  p={1.5}
                  m={1}
                  sx={{
                    // background: "#f5f5f5",
                    // borderRadius: "10px",
                    // whiteSpace:'nowrap'
                    borderBottom: "2px solid #0957DD",
                    boxShadow: "2px 2px 12px 0px #bbb",
                    borderRadius: "5px",
                  }}
                >
                  {/* <Grid item my={1}>
                    <b>Slots</b>
                  </Grid> */}
                  <Grid item className="slot-line-peding">
                    Service Price :{" "}
                    <span style={{ fontWeight: "600" }}>
                      Rs.{value.servicePrice}{" "}
                    </span>
                  </Grid>
                  <Grid item className="slot-line-peding">
                    Date :{" "}
                    <span className="slot-date">
                      {new Date(value.bookingDate).toDateString()}{" "}
                    </span>
                  </Grid>
                  <Grid item className="slot-line-peding">
                    Prefered Time : &nbsp;
                    <span
                      className="slot-date"
                      style={{ backgroundColor: "#6c757d" }}
                    >
                      {value.preferedTime ? value.preferedTime : "-"}
                    </span>
                  </Grid>
                  <Grid item className="slot-line-peding">
                    Shift :{" "}
                    {/* {value.slotType == 1
                      ? <span className="slot-bg-color">Morning</span>
                      : value.slotType == 2
                      ? <span className="slot-bg-color">Evening</span>
                      : value.slotType == 3
                      ? <span className="slot-bg-color">24 Hours</span>
                      : "N/A"}{" "} */}
                    {getSlots(value.slotType)}
                  </Grid>
                </Stack>
              </Grid>
            );
          })}
        </Grid>
      </Card>

      {/*  A Modal where Nurse has been assigned */}
      <Dialog
        open={assignedModal}
        onClose={closeAssignedModal}
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle
          sx={{
            fontWeight: "bold",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          Nurses List
          <Tooltip title="Close">
            <IconButton onClick={closeAssignedModal}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent>
          <Toolbar style={{ padding: "0px" }}>
            <Search sx={{ my: 1 }} style={{ margin: "0px" }}>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                sx={{
                  height: "50px",
                  width: "850px",
                }}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
              />
            </Search>
          </Toolbar>
          <TableContainer
            component={Paper}
            sx={{ minHeight: "593px", overflowY: "scroll", my: 1 }}
          >
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Id</StyledTableCell>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell>Gender</StyledTableCell>
                  <StyledTableCell>Category</StyledTableCell>
                  <StyledTableCell>SubCategory</StyledTableCell>
                  <StyledTableCell>Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {nursesList
                  .filter((list) => {
                    //  if value is empty allow all the list
                    if (searchValue === "") return true;
                    var { fname, gender } = list;
                    var name = fname;
                    var gender = isEmpty(gender);

                    return name
                      .toLowerCase()
                      .includes(searchValue.toLowerCase());
                  })
                  .map((row, index) => {
                    var { id, fname, gender, service, subCat } = row;
                    var name = isEmpty(fname);
                    var gender = isEmpty(gender);
                    service = isEmpty(service);
                    subCat = isEmpty(subCat);

                    return (
                      <TableRow
                        key={row.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>{id}</TableCell>
                        <TableCell>{name}</TableCell>
                        <TableCell>{gender}</TableCell>
                        <TableCell>{service}</TableCell>
                        <TableCell>{subCat}</TableCell>

                        <TableCell>
                          <Stack flexDirection="row" columnGap={1}>
                            <Tooltip title="View Details">
                              <IconButton onClick={() => selectNurseId(id)}>
                                <VisibilityIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Assign Nurse">
                              <IconButton
                                onClick={() => openConfirmationModal(id)}
                              >
                                <CheckIcon />
                              </IconButton>
                            </Tooltip>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions sx={{ margin: "15px" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={closeAssignedModal}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {/* A confirmation modal to assign Nurse */}
      <Dialog
        open={confirmation}
        // onClose={closeConfirmationModal}
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogTitle
          sx={{
            fontWeight: "bold",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          Confirmation Dialog
          <Tooltip title="Close">
            <IconButton onClick={closeConfirmationModal}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure ?</DialogContentText>
        </DialogContent>
        <DialogActions sx={{ margin: "15px" }}>
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              if (action === modes.assign) {
                confirmAssignNurse();
              } else if (action === modes.remove) {
                removeNurse(id);
              }
            }}
          >
            YES
          </Button>

          <Button
            variant="contained"
            color="error"
            onClick={closeConfirmationModal}
          >
            CANCEL
          </Button>
        </DialogActions>
      </Dialog>
      {/* A modal to display nurse details */}

      {nurseDetails && (
        <AssignedNurseDetails
          id={nurseId}
          open={nurseDetails}
          handleClose={closeNurseDetailsModal}
        />
      )}
    </>
  );
}
