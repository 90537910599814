import React,{ useState } from "react";

import { Box, Button, Stack, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";

import CityList from './CityList'
import AddCity from './AddCity'

const CategoryPage = () => {
  const [refresh, setRefresh] = useState(false);
  const refreshScreen = () => {
    setRefresh(!refresh);
  };
  // const [addCity, setAddCity] = useState(false);
  // const openCityModal = () => {
  //   setAddCity(true);
  // };
  // const closeCategoryModal = () => {
  //   setAddCity(false);
  // };
  return (
    <>
      <Stack flexDirection="row" justifyContent="space-between">
        <Typography sx={{ fontSize: "30px", fontWeight: "bold" }}>
          Cities
        </Typography>
        {/* <Button
          sx={{
            backgroundColor: "#0957DD",
            color: "white",
            borderRadius: "10px",
            padding: "15px 40px",
            "&:hover": {
              backgroundColor: "#0957DD",
            },
          }}
          onClick={openCityModal}
          startIcon={<Add />}
          color="primary"
        >
          Add City
        </Button> */}
        
      </Stack>
      
      {/* <AddCity
        open={addCity}
        setOpen={setAddCity}
        handleClickOpen={openCityModal}
        handleClose={closeCategoryModal}
        refreshScreen={refreshScreen}
      /> */}
            <Box sx={{ mt: 4 }}>
        <CityList refresh={refresh} refreshScreen={refreshScreen} />
      </Box>
    </>
  );
};
export default CategoryPage;
