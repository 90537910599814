import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";

import {
  Search as SearchIcon,
  MoreVert as MoreVertIcon,
  Close as CloseIcon,
  Info as InfoIcon,
} from "@mui/icons-material";
import {
  InputBase,
  Button,
  Typography,
  IconButton,
  Stack,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Drawer,
  Divider,
  Box,
  TextField,
  FormLabel,
  InputAdornment,
  Pagination,
} from "@mui/material";
import moment from "moment";

import filterIcon from "../../Assets/filter.svg";
import "./styles/payment.css";
import { getPaymentListApi } from "../../state/actions/paymentAction";
import { useForm } from "../../Utils/useForm";
import Backdrop from "../BackDrop/BackDrop";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  color: "black",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  borderRadius: "10px",
  backgroundColor: "white",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    paddingRight: "15px",
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: "#0F0F0F80",
}));

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
}));
const StyledTextField = styled(TextField)(() => ({
  marginTop: "10px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
  },
}));
const StyledChip = styled(Chip)(() => ({
  borderRadius: "10px",
  fontSize: "14px",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#0957dd0a",
  },
}));

export default function PaymentList(props) {
  const { refresh, refreshScreen } = props;
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [pagePerSize, setPagePerSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const [paymentlist, setPaymentList] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentPayment, setCurrentPayment] = useState(-1);
  const [userView, setUserView] = useState(false);
  const [deleteUser, setDeleteUser] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  // const [addUser, setAddUser] = useState(false);
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  // api call for get a users-list
  async function getPaymentList(page) {
    let queryString =
      "?page=" + page + "&size=" + pagePerSize + "&search=" + searchValue;
    Object.keys(values).map((key) => {
      queryString += "&" + key + "=" + values[key];
    });
    setOpenBreakdrop(true);
    let response = await getPaymentListApi(queryString);
    if (response.status === true) {
      setPaymentList(response.data);
      setTotalRecords(response.totalRecord);
      setTotalPages(Math.ceil(response.totalRecord / pagePerSize));
    } else {
      setPaymentList([]);
      setTotalRecords(0);
      setTotalPages(0);
    }

    setOpenBreakdrop(false);
  }
  useEffect(() => {
    getPaymentList(page);
  }, [refresh]);

  useEffect(() => {
    handleClose();
  }, [paymentlist]);

  const handleChangePage = (event, value) => {
    setPage(value);
    getPaymentList(value);
  };

  const open = Boolean(anchorEl);
  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setCurrentPayment(index);
  };
  const handleClose = () => {
    setAnchorEl(null);
    // setCurrentUser(-1);
  };

  const onSearch = async (search) => {
    if (search.target.value === "") {
      resetForm();
    }
    setSearchValue(search.target.value);

    let queryString =
      "?page=" + 1 + "&size=" + pagePerSize + "&search=" + search.target.value;
    setOpenBreakdrop(true);
    const res = await getPaymentListApi(queryString);
    if (res && res.status) {
      setPaymentList(res.data);
      setTotalRecords(res.totalRecord);
      setTotalPages(Math.ceil(res.totalRecord / pagePerSize));
    } else {
      setPaymentList([]);
      setTotalRecords(0);
      setTotalPages(0);
    }
    setOpenBreakdrop(false);
  };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;

        func.apply(context, args);
      }, 700);
    };
  };

  const debounceserach = debounce(onSearch);

  const initialValues = {
    orderNumber: "",
    fname: "",
    paymentStatus: "",
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);

  const [filter, setFilter] = useState(false);
  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
  };

  const onFilterSubmit = async (e) => {
    if (typeof e != "undefined") {
      e.preventDefault();
    }
    //  we are using initialValues because our values object is not resetting.
    var formValues = typeof e === "undefined" ? initialValues : values;
    let queryString = "?search=" + "&page=" + 1 + "&size=" + pagePerSize;
    Object.keys(formValues).map((key) => {
      queryString += "&" + key + "=" + formValues[key];
    });

    setOpenBreakdrop(true);
    const res = await getPaymentListApi(queryString);
    if (res && res.status) {
      setPaymentList(res.data);
      setTotalRecords(res.totalRecord);
      setTotalPages(Math.ceil(res.totalRecord / pagePerSize));
    } else {
      setPaymentList([]);
      setTotalRecords(0);
      setTotalPages(0);
    }
    setOpenBreakdrop(false);
    closeFilterDrawer();
  };

  const handleUserView = () => {
    setUserView(true);
  };
  const closeUserView = () => {
    setUserView(false);
  };

  const getPaymentStatus = (id) => {
    const status = {
      0: {
        label: "Failed",
        styleSheet: {
          backgroundColor: "#bfbdbd",
          color: "#dc3545",
        },
      },
      1: {
        label: "Success",
        styleSheet: {
          backgroundColor: "#28a74538",
          color: "#28a745",
        },
      },
      2: {
        label: "Pending",
        stylesheet: {
          backgroundColor: "rgb(0 0 0 / 10%)",
          color: "#616161",
        },
      },
      3: {
        label: "Declined",
        stylesheet: {
          backgroundColor: "rgb(115 56 240 / 8%)",
          color: "#ffc107;",
        },
      },
      4: {
        label: "Failed",
        stylesheet: {
          backgroundColor: "rgb(240 68 56 / 15%)",
          color: "#e90000",
        },
      },
    };
    if (status[id] === undefined) return "-";
    const { label, stylesheet } = status[id];
    return <StyledChip sx={stylesheet} label={label} />;
  };

  return (
    <>
      <Backdrop open={openBackdrop} />

      <ToastContainer limit={1} />

      <div
        style={{
          backgroundColor: "white",
          borderRadius: "10px",
          color: "black",
        }}
      >
        {/* search bar and filter button */}
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          sx={{ padding: "20px 0px" }}
        >
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              sx={{
                height: "50px",
              }}
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
              onKeyUp={debounceserach}
            />
          </Search>

          <Button
            sx={{
              border: "1px solid rgba(15, 15, 15, 0.15)",
              borderRadius: "10px",
              mr: 2,
              padding: "5px 20px",
              fontWeight: "bold",
              color: "black",
            }}
            endIcon={
              <IconButton sx={{ ml: 3 }}>
                <img src={filterIcon} alt="Filter Options" />
              </IconButton>
            }
            onClick={openFilterDrawer}
          >
            Filter
          </Button>
        </Stack>
        {/* drawer */}
        <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
          <form
            onSubmit={onFilterSubmit}
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                // width: 220,
                padding: "10px 20px",
              }}
            >
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography sx={{ fontWeight: "bold" }}>Filter</Typography>
                <IconButton sx={{ color: "black" }} onClick={closeFilterDrawer}>
                  <CloseIcon />
                </IconButton>
              </Stack>

              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="orderNumber">
                  Order Number{" "}
                </StyledFormLabel>
                <StyledTextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search Order Number"
                  fullWidth
                  id="orderNumber"
                  name="orderNumber"
                  value={values.orderNumber}
                  onChange={handleInputChange}
                />
              </Box>

              <Divider sx={{ mt: 2 }} />
              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="fname">Name</StyledFormLabel>
                <StyledTextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search Name"
                  fullWidth
                  id="fname"
                  name="fname"
                  value={values.fname}
                  onChange={handleInputChange}
                />
              </Box>
              <Divider sx={{ mt: 2 }} />
              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="paymentStatus">
                  Payment Status{" "}
                </StyledFormLabel>
                <StyledTextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search Payment Status"
                  fullWidth
                  id="paymentStatus"
                  name="paymentStatus"
                  value={values.paymentStatus}
                  onChange={handleInputChange}
                  select
                >
                  {["Success", "Pending", "Declined", "Failed"].map(
                    (name, index) => {
                      return (
                        <MenuItem
                          key={index}
                          sx={{ width: "250px" }}
                          value={index + 1}
                        >
                          {name}
                        </MenuItem>
                      );
                    }
                  )}
                </StyledTextField>
              </Box>
              <Divider sx={{ mt: 2 }} />
            </Box>
            <Stack
              sx={{ margin: "80px 20px 25px 20px" }}
              flexDirection="row"
              justifyContent="space-between"
            >
              <Button
                sx={{
                  backgroundColor: "white",
                  color: "black",
                  border: "1px solid #0F0F0F80",
                  borderRadius: "10px",
                  padding: "10px 25px",
                  "&:hover": {
                    // backgroundColor: "#0957DD",
                  },
                }}
                onClick={() => {
                  resetForm();
                  onFilterSubmit();
                }}
              >
                Reset
              </Button>
              <Button
                sx={{
                  backgroundColor: "#0957DD",
                  color: "white",
                  borderRadius: "10px",
                  padding: "10px 25px",
                  "&:hover": {
                    backgroundColor: "#0957DD",
                  },
                }}
                type="submit"
              >
                Filter
              </Button>
            </Stack>
          </form>
        </Drawer>
        {/* table */}
        <TableContainer
          component={Paper}
          sx={{ minHeight: "300px", overflowY: "scroll" }}
        >
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Order Number</StyledTableCell>
                <StyledTableCell>Order Total</StyledTableCell>
                <StyledTableCell>Payment Status</StyledTableCell>
                <StyledTableCell>User Name</StyledTableCell>
                <StyledTableCell>Payment Date</StyledTableCell>
                {/* <StyledTableCell>Payment Id</StyledTableCell> */}
                <StyledTableCell>Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody id="accordian">
              {paymentlist?.map((row, index) => {
                var {
                  orderId,
                  orderNumber,
                  orderTotal,
                  paymentStatus,
                  fname,
                  createdAt,
                  // paymentId,
                } = row;
                return (
                  <>
                    <StyledTableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      onClick={() => {
                        navigate("/equipmentsorder/details", {
                          state: { orderId: orderId, parent: "/payment" },
                        });
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <TableCell
                        className="payment-list-order-number"
                        component="th"
                        scope="row"
                      >
                        {orderNumber}
                      </TableCell>
                      <TableCell>Rs {orderTotal}</TableCell>
                      <TableCell>{getPaymentStatus(paymentStatus)}</TableCell>
                      <TableCell>{fname}</TableCell>
                      <TableCell>
                        {createdAt ? moment(createdAt).format("LLL") : "-"}
                      </TableCell>

                      <TableCell onClick={(e) => e.stopPropagation()}>
                        <IconButton
                          onClick={(e) => {
                            const currentPayment = paymentlist.find(
                              (role) => role.id === row.id
                            );
                            handleClick(e, currentPayment);
                          }}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </TableCell>
                    </StyledTableRow>
                  </>
                );
              })}
              {paymentlist?.length === 0 ? (
                <TableRow>
                  <TableCell
                    sx={{
                      color: "silver",
                      textAlign: "center",
                      paddingTop: "90px",
                      borderBottom: "none",
                      fontSize: "30px",
                    }}
                    colSpan="7"
                  >
                    No records to display
                  </TableCell>
                </TableRow>
              ) : (
                ""
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Menu
          sx={{ mt: 0.3 }}
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClick={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuItem
            sx={{ mt: 1 }}
            onClick={() => {
              navigate("/equipmentsorder/details", {
                state: { orderId: currentPayment.orderId, parent: "/payment" },
              });
            }}
          >
            <ListItemIcon>
              <InfoIcon />
            </ListItemIcon>
            <ListItemText>View Details</ListItemText>
          </MenuItem>
        </Menu>
      </div>
      {/* pagination */}
      <Stack
        sx={{ mt: 4, pb: 2 }}
        flexDirection="row"
        justifyContent="space-between"
      >
        <div>
          Showing{" "}
          {page * pagePerSize > totalRecords
            ? totalRecords
            : page * pagePerSize}{" "}
          of {totalRecords} Results
        </div>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
        />
      </Stack>
    </>
  );
}
