import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

import { ListItemIcon, ListItemText } from "@mui/material";
import {
  Search as SearchIcon,
  Notifications as NotificationsIcon,
  ExpandMore,
  ExpandLess,
  Person,
  Logout as LogoutIcon,
} from "@mui/icons-material";

import {
  AppBar,
  Toolbar,
  InputBase,
  IconButton,
  Badge,
  Button,
  Avatar,
  Typography,
  Menu,
  MenuItem,
} from "@mui/material";

import { store } from "../../state/store";
import { logoutApi } from "../../state/actions/authActions";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  color: "black",
  borderRadius: "10px",
  backgroundColor: "white",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    paddingRight: "15px",
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const Header = (props) => {
  const navigate = useNavigate();
  const { auth } = store.getState();

  if (!auth.isAuthenticated) {
    navigate("/login");
  }

  const dispatch = useDispatch();
  const [admin, setAdmin] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  function logoutAdmin() {
    dispatch(logoutApi());
    navigate("/login");
  }

  useEffect(() => {
    let timeout = setInterval(() => {
      let { auth } = store.getState();
      if (auth.user) {
        // if data is on store then we have to update the state of user with userdata and stop the timeout
        setAdmin(auth.user);
        stoptimeout();
      }
    }, 500);
    function stoptimeout() {
      clearInterval(timeout);
    }
  }, []);
  return (
    <AppBar
      position="static"
      sx={{
        mt: 2,
        backgroundColor: "transparent",
        boxShadow: "none",
      }}
    >
      <Toolbar sx={{ paddingLeft: "5px !important" }}>
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ display: "flex" }}>
          {/* <IconButton
            color="inherit"
            sx={{
              marginRight: "30px",
              ".MuiBadge-badge": {
                backgroundColor: "#0957DD",
              },
            }}
          >
            <Badge badgeContent={17}>
              <NotificationsIcon fontSize="large" />
            </Badge>
          </IconButton> */}
          <Button
            sx={{
              backgroundColor: "white",
              borderRadius: "10px",
              color: "black",
              alignItems: "center",
              "&:hover": {
                backgroundColor: "white",
              },
            }}
            onClick={handleClick}
          >
            <img
              style={{ width: "40px", height: "40px", borderRadius: "50%" }}
              sx={{ mx: 0.5 }}
              alt="Admin Profile"
              src={admin.profile}
            />
            <Typography sx={{ mx: 0.5 }}>{admin.name}</Typography>
            {open ? (
              <ExpandLess sx={{ mx: 0.5 }} />
            ) : (
              <ExpandMore sx={{ mx: 0.5 }} />
            )}
          </Button>
          <Menu
            sx={{
              mt: 0.3,
              "&#user-profile .MuiPaper-root ": {
                width: "190px",
              },
            }}
            id="user-profile"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem
              sx={{ mt: 1 }}
              onClick={() => {
                navigate("/profile");
                handleClose();
              }}
            >
              <ListItemIcon>
                <Person />
              </ListItemIcon>
              <ListItemText>Profile</ListItemText>
            </MenuItem>
            <MenuItem sx={{ mt: 1 }} onClick={logoutAdmin}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText>Logout</ListItemText>
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
