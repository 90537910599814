import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom'
import { useDispatch } from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import Login from '../Components/Login/Login';
import { loadUserApi } from '../state/actions/authActions'
import PrivateRoute from '../Components/PrivateRoute/PrivateRoute';
import Layout from '../Components/Layout/Layout'
import './App.css';




function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const pathname = location.pathname.substr(1);


  window.onload = async () => {
    if (pathname !== 'login') {
      await dispatch(loadUserApi());
    }
  }
 

 
  return (
    <>

      <Routes>
        <Route exact path="/login" element={<Login />} />
        <Route
          path="*"
          element={
            <PrivateRoute>
              <Layout />
            </PrivateRoute>
          }
        ></Route>
      </Routes>
    </>
  );
}

export default App;
