import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Box,
  Typography,
  IconButton,
  TextField,
  FormLabel,
  Tooltip,
  Stack,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Close as CloseIcon } from "@mui/icons-material";

import camera from "../../Assets/camera.svg";
import { useForm } from "../../Utils/useForm";

import Backdrop from "../BackDrop/BackDrop";
import {
  addCancellationReasonApi,
  updateReasonApi,
} from "../../state/actions/cancellationAction";

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
}));
const StyledTextField = styled(TextField)(() => ({
  marginTop: "10px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
  },
  ".css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
    padding: "14px",
  },
}));
const StyledImageUploadWrapper = styled(Box)(() => ({
  marginTop: "16px",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  height: "206px",
  borderRadius: "10px",
  textAlign: "center",
}));
const StyledIconWrapper = styled(Box)(() => ({
  position: "relative",

  margin: "20px auto",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  width: "80px",
  height: "80px",
  borderRadius: "10px",
  backgroundColor: "rgba(15, 15, 15, 0.05)",

  img: {
    marginTop: "30px",
  },
}));

const StyledInput = styled("input")({
  display: "none",
});
const StyledRequired = styled("span")({
  color: "red",
  marginLeft: "2px",
  fontSize: "20px",
});

const StyledRemoveButton = styled(Box)({
  color: "red",
  display: "inline",
  "&:hover": {
    cursor: "pointer",
  },
});

const AddCancellation = (props) => {
  const { open, refreshScreen, handleClose, cancellation, isEditAction } =
    props;
  console.log("cancellation body", cancellation);
  const [openBackdrop, setOpenBreakdrop] = useState(false);

  const reset = () => {
    resetForm();
  };

  const initialValues = {
    forType: "",
    type: "",
    name: "",
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("forType" in fieldValues)
      temp.forType = fieldValues.forType ? "" : "This field is required.";
    if ("type" in fieldValues)
      temp.type = fieldValues.type ? "" : "This field is required.";

    if ("name" in fieldValues)
      temp.name = fieldValues.name ? "" : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const toastType = {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    };

    if (validate()) {
      let res;
      if (isEditAction) {
        var body = { ...values, reasonId: cancellation.id };
        res = await updateReasonApi(body);
      } else {
        setOpenBreakdrop(true);
        res = await addCancellationReasonApi(values);
      }
      if (res && res.status === true) {
        close();
        toast.success(res.message, toastType);
        refreshScreen();
      } else {
        toast.error(res.message, toastType);
      }
      setOpenBreakdrop(false);
    }
  };

  const close = () => {
    reset();
    handleClose();
  };

  useEffect(() => {
    if (isEditAction) {
      var { name, type, forType } = cancellation;
      setValues({ name, type, forType });
    }
  }, [props]);
  const toastType = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
  };

  return (
    <>
      <Backdrop open={openBackdrop} />
      
      <Dialog open={open} onClose={handleClose} fullWidth={true}>
        <DialogTitle
          sx={{
            fontWeight: "bold",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {isEditAction ? "Edit " : "Add "}
          Cancellation Reason
          <Tooltip title="Close">
            <IconButton onClick={close}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit} id="addNewCategory">
            <Grid container justifyContent="center">
              <Stack my={2} spacing={2}>
                <Grid item sx={{ width: "400px" }}>
                  <StyledFormLabel htmlFor="forType">
                    Reasons For
                    {Boolean(errors.forType) && (
                      <StyledRequired> *</StyledRequired>
                    )}
                  </StyledFormLabel>
                  <StyledTextField
                    id="forType"
                    name="forType"
                    fullWidth
                    select
                    value={values.forType}
                    onChange={handleInputChange}
                    error={Boolean(errors.forType)}
                    helperText={errors.forType}
                  >
                    <MenuItem value="1">Equipments</MenuItem>
                    <MenuItem value="2">Booking</MenuItem>
                  </StyledTextField>
                </Grid>
                <Grid item sx={{ width: "400px" }}>
                  <StyledFormLabel htmlFor="type">
                    Reasons Type
                    {Boolean(errors.type) && (
                      <StyledRequired> *</StyledRequired>
                    )}
                  </StyledFormLabel>
                  <StyledTextField
                    id="type"
                    name="type"
                    fullWidth
                    select
                    value={values.type}
                    onChange={handleInputChange}
                    error={Boolean(errors.type)}
                    helperText={errors.type}
                  >
                    <MenuItem value="1">Cancel</MenuItem>
                    <MenuItem value="2">Return</MenuItem>
                  </StyledTextField>
                </Grid>
                <Grid item>
                  <StyledFormLabel htmlFor="name">
                    Name
                    {Boolean(errors.name) && (
                      <StyledRequired> * </StyledRequired>
                    )}
                  </StyledFormLabel>
                  <StyledTextField
                    fullWidth
                    id="name"
                    name="name"
                    type="text"
                    value={values.name}
                    onChange={handleInputChange}
                    error={Boolean(errors.name)}
                    helperText={errors.name}
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>
              </Stack>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions sx={{ margin: "15px" }}>
          <Button
            sx={{
              backgroundColor: "white",
              color: "black",
              border: "1px solid #0F0F0F80",
              borderRadius: "10px",
              padding: "10px 25px",
              "&:hover": {},
            }}
            onClick={close}
          >
            Cancel
          </Button>
          <Button
            sx={{
              backgroundColor: "#0957DD",
              color: "white",
              borderRadius: "10px",
              padding: "10px 25px",
              "&:hover": {
                backgroundColor: "#0957DD",
              },
            }}
            type="submit"
            form="addNewCategory"
          >
            {isEditAction ? "Save" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddCancellation;
