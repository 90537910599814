import React from "react"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';


ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);
const options = {
  responsive: true,
  interaction: {
    mode: 'index',
    intersect: false,
  },
  stacked: false,
  scales: {
    x: {
      grid: {
        drawOnChartArea: false,
      },
    },
    y: {
      type: 'linear',
      display: true,
      position: 'left',
      grid: {
        drawOnChartArea: false,
      },
    },
  },
};


const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];




export default function Bar({ graphData, graphMonthData }) {

  let finalData = [];
  if (graphData) {
    labels.forEach((x) => {
      const item = graphData.find((y) => y.months === x);
      if (item) {
        finalData.push(item.usercount)
      } else {
        finalData.push(0)
      }
    })
  }

  let finalMonthData = [];
  if (graphMonthData) {
    labels.forEach((x) => {
      const item = graphMonthData.find((y) => y.months === x);
      if (item) {
        finalMonthData.push(item.usermonthcount)
      } else {
        finalMonthData.push(0)
      }
    })
  }

  const data = {
    labels,
    datasets: [
      {
        label: 'Per User Bookings',
        data: finalData,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        yAxisID: 'y',
        tension: 0.3,
      },
      {
        label: 'Per Month Bookings',
        data: finalMonthData,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        yAxisID: 'y',
        tension: 0.3,
      },
    ],
  };

  return (
    <>
      <Line options={options} data={data} width='500px' height='300px' />

    </>
  )
}
