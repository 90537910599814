import handleAuthToken from "../../Utils/handleAuthToken";
import axios from "axios";

export const orderListApi = async (queryString) => {
  if (localStorage.token) {
    handleAuthToken(localStorage.token);
  }
  try {
    const res = await axios.get(`/admin/av1/orderList` + queryString);
    return res.data;
  } catch (error) {
    return { success: false, message: error.response.data.message };
  }
};

export const orderDetailsApi = async (body) => {
  try {
    const res = await axios.post(`/admin/av1/orderDetail`, body);
    return res.data;
  } catch (error) {
    return { success: false, message: error.response.data.message };
  }
};

export const orderBookingDetailsApi = async (bookingId) => {
  try {
    const res = await axios.get(`/admin/av1/bookingDetails/` + bookingId);
    return res.data;
  } catch (error) {
    return { success: false, message: error.response.data.message };
  }
};
export const editOrderStatusApi = async (body) => {
  if (localStorage.token) {
    handleAuthToken(localStorage.token);
  }

  try {
    const res = await axios.post(`/admin/av1/orderStatusUpdate`, body);
    return res.data;
  } catch (error) {
    return { success: false, message: error.response.data.message };
  }
};
