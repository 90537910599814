import { USER_LOADED } from '../action-types/authActionTypes';
import handleAuthToken from "../../Utils/handleAuthToken";
import axios from 'axios'

export const AdminDetailsUpdateApi =  (data)=>async(dispatch) => {
    if (localStorage.token) {
        handleAuthToken(localStorage.token);
    }
    try {

        let formdata=new FormData();
        formdata.append('profile',data.profile)
        formdata.append('name',data.name)
        formdata.append('mobile',data.mobile)

        const res = await axios.post('/admin/av1/adminUpdate',formdata);
        dispatch({
            type: USER_LOADED,
            payload: res.data.data,
          });
        return res.data;
    } catch (error) {
        return { success: false, message: error.response.data.message };
    }
}