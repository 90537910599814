import handleAuthToken from "../../Utils/handleAuthToken";
import axios from "axios";

export const getCancellationReasonsList= async () => {
  if (localStorage.token) {
    handleAuthToken(localStorage.token);
  }
  try {
    const res = await axios.get(`/admin/av1/listAllReasons`);
    return res.data;
  } catch (error) {
    return { success: false, message: error.response.data.message };
  }
};

export const addCancellationReasonApi = async (values) => {
  if (localStorage.token) {
    handleAuthToken(localStorage.token);
  }
  try {
    const res = await axios.post(`admin/av1/addReason`, values);
    console.log("cancellation reason", res)
    return res.data;
  } catch (error) {
    return { success: false, message: error.response.data.message };
  }
};


export const updateReasonApi = async (data) => {
  if (localStorage.token) {
    handleAuthToken(localStorage.token);
  }
  try {
    const res = await axios.post(`/admin/av1/editreason`, data);
    console.log("working---------------data", res.data)
    return res.data

  } catch (error) {
    return { success: false, message: error.response.data.message };
  }
}
