import handleAuthToken from "../../Utils/handleAuthToken";
import axios from "axios";

export const getUserCount = async () => {
    if (localStorage.token) {
        handleAuthToken(localStorage.token);
    }
    try {
        const res = await axios.get(`/admin/av1/userData`);
        return res.data;
    } catch (error) {
        console.log(error);
        return { success: false, message: error.response.data.message };
    }
};