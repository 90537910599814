import { applyMiddleware, compose, createStore } from "redux"
import { configureStore } from '@reduxjs/toolkit'
import thunk from "redux-thunk"
import reducers from "./reducers/index"
import authReducer from "../state/reducers/authReducer";
const initialState = {};
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


export const store = configureStore({
    reducer: {
      auth : authReducer,
    }
  })