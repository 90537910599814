import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);


export default function PieChart({ graphUserData }) {
  let month = '';
  let userMonth = '';
  if (graphUserData) {
    month = graphUserData.map((x) => x.months)
    userMonth = graphUserData.map((x) => x.usermonthcount)
  }
  const data = {
    labels: month,
    datasets: [
      {
        label: 'Register Users',
        data: userMonth,
        backgroundColor: ['#F1E15B', '#E978AA', '#61CDBB', '#5668E2', '#B20093', '#000000', '#F1E15B', '#E978AA', '#61CDBB', '#5668E2', '#B20093', '#000000'],
      },
    ],
  };
  return (
    <>
      <div>
        <Pie data={data} width='200px' height="375px"
          options={{ maintainAspectRatio: false }} />
      </div>
    </>
  )
}
