import handleAuthToken from "../../Utils/handleAuthToken";
import axios from "axios";

export const getPrivacyPolicy = async () => {
    if (localStorage.token) {
        handleAuthToken(localStorage.token);
    }
    try {
        const res = await axios.get(`/admin/av1/privacyPolicy`);
        return res.data;
    } catch (error) {
        console.log(error);
        return { success: false, message: error.response.data.message };
    }
};

export const updatePrivacyPolicy = async (id, body) => {
    if (localStorage.token) {
        handleAuthToken(localStorage.token);
    }
    try {
        const res = await axios.post(`/admin/av1/privacyPolicyUpdate/${id}`, body);
        return res.data;
    } catch (error) {
        console.log(error);
        return { success: false, message: error.response.data.message };
    }
};


export const getTermsCondition = async () => {
    if (localStorage.token) {
        handleAuthToken(localStorage.token);
    }
    try {
        const res = await axios.get(`/admin/av1/termscondition`);
        return res.data;
    } catch (error) {
        console.log(error);
        return { success: false, message: error.response.data.message };
    }
};

export const updateTermsCondition = async (id, body) => {
    if (localStorage.token) {
        handleAuthToken(localStorage.token);
    }
    try {
        const res = await axios.post(`/admin/av1/termsconditionUpdate/${id}`, body);
        return res.data;
    } catch (error) {
        console.log(error);
        return { success: false, message: error.response.data.message };
    }
};

export const getSupportNumber = async () => {
    if (localStorage.token) {
        handleAuthToken(localStorage.token);
    }
    try {
        const res = await axios.get(`/admin/av1/supportcontact`);
        return res.data;
    } catch (error) {
        console.log(error);
        return { success: false, message: error.response.data.message };
    }
};

export const updateSupportNumber = async (id, body) => {
    if (localStorage.token) {
        handleAuthToken(localStorage.token);
    }
    try {
        const res = await axios.post(`/admin/av1/supportcontactUpdate/${id}`, body);
        return res.data;
    } catch (error) {
        console.log(error);
        return { success: false, message: error.response.data.message };
    }
};