import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";

import { styled } from "@mui/material/styles";
import {
  Search as SearchIcon,
  MoreVert as MoreVertIcon,
  Close as CloseIcon,
  Person as PersonIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
} from "@mui/icons-material";
import {
  InputBase,
  Button,
  Typography,
  IconButton,
  Stack,
  TableContainer,
  Paper,
  Table,
  TableHead,
  debounce,
} from "@mui/material";
import {
  TableBody,
  TableRow,
  TableCell,
  Chip,
  Menu,
  Avatar,
  Pagination,
} from "@mui/material";
import {
  ListItemText,
  ListItemIcon,
  Drawer,
  Divider,
  Box,
  TextField,
  FormLabel,
  InputAdornment,
  CircularProgress,
  MenuItem,
} from "@mui/material";

import moment from "moment";

import edit from "../../Assets/edit.svg";
import deleteIcon from "../../Assets/delete.svg";
import filterIcon from "../../Assets/filter.svg";

import UsersDetailsModal from "./UsersDetails";
import DeleteComponent from "../DeleteComponent/DeleteComponent";

import {
  userFamilyMemberApi,
  usersListApi,
  deleteUserApi,
} from "../../state/actions/usersAction";
import { useForm } from "../../Utils/useForm";
import Backdrop from "../BackDrop/BackDrop";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../../node_modules/bootstrap/dist/js/bootstrap.min.js";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  color: "black",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  borderRadius: "10px",
  backgroundColor: "white",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    paddingRight: "15px",
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: "#0F0F0F80",
}));

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
}));
const StyledTextField = styled(TextField)(() => ({
  marginTop: "10px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
  },
}));
const StyledChip = styled(Chip)(() => ({
  borderRadius: "10px",
  fontSize: "14px",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#0957dd0a",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function UsersList(props) {
  const { refresh, refreshScreen } = props;
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentUser, setCurrentUser] = useState(-1);
  const [userView, setUserView] = useState(false);
  const [deleteValue, setDeleteValue] = useState(false);
  const [addUser, setAddUser] = useState(false);
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [userFamilyMemberData, setUserFamilyMemberData] = useState([]);

  const [searchValue, setSearchValue] = useState("");

  const [page, setPage] = useState(1);
  const [pagePerSize, setPagePerSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const handledelete = () => {
    setDeleteValue(true);
  };
  const closedelete = () => {
    setDeleteValue(false);
  };

  async function getUsers(page) {
    let queryString =
      "?page=" + page + "&size=" + pagePerSize + "&search=" + searchValue;
    Object.keys(values).map((key) => {
      queryString += "&" + key + "=" + values[key];
    });
    setOpenBreakdrop(true);
    const res = await usersListApi(queryString);
    if (res && res.status) {
      console.log("res.data ===================== res.data", res.data);
      setUsers(res.data);
      setTotalRecords(res.totalRecord);
      setTotalPages(Math.ceil(res.totalRecord / pagePerSize));
    } else {
      setUsers([]);
      setTotalRecords(0);
      setTotalPages(0);
    }
    setOpenBreakdrop(false);
    setIsExpanded(false);
  }
  // api call for get a users-list
  useEffect(() => {
    getUsers(page);
  }, [refresh]);

  const handleChangePage = (event, value) => {
    setPage(value);
    getUsers(value);
  };

  const open = Boolean(anchorEl);
  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setCurrentUser(index);
  };
  const handleClose = () => {
    setAnchorEl(null);
    // setCurrentUser(-1);
  };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 700);
    };
  };
  const onSearch = async (search) => {
    if (search.target.value === "") {
      resetForm();
    }
    setSearchValue(search.target.value);
    let queryString =
      "?page=" + 1 + "&size=" + pagePerSize + "&search=" + search.target.value;

    setOpenBreakdrop(true);
    const res = await usersListApi(queryString);
    if (res && res.status) {
      setUsers(res.data);
      setTotalRecords(res.totalRecord);
      setTotalPages(Math.ceil(res.totalRecord / pagePerSize));
    } else {
      setUsers([]);
      setTotalRecords(0);
      setTotalPages(0);
    }

    setOpenBreakdrop(false);
    setIsExpanded(false);
  };
  const debounceserach = debounce(onSearch);

  const initialValues = {
    name: "",
    email: "",
    mobile: "",
    status: "",
  };
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);

  const [filter, setFilter] = useState(false);
  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
  };

  const onFilterSubmit = async (e) => {
    if (typeof e != "undefined") {
      e.preventDefault();
    }
    //  we are using initialValues because our values object is not resetting.
    var formValues = typeof e === "undefined" ? initialValues : values;
    let queryString = "?search=" + "&page=" + 1 + "&size=" + pagePerSize;
    Object.keys(formValues).map((key) => {
      queryString += "&" + key + "=" + formValues[key];
    });

    setOpenBreakdrop(true);
    const res = await usersListApi(queryString);
    if (res && res.status) {
      setUsers(res.data);
      setTotalRecords(res.totalRecord);
      setTotalPages(Math.ceil(res.totalRecord / pagePerSize));
    } else {
      setUsers([]);
      setTotalRecords(0);
      setTotalPages(0);
    }

    setOpenBreakdrop(false);
    closeFilterDrawer();
    setIsExpanded(false);
  };

  const handleUserView = () => {
    setUserView(true);
  };
  const closeUserView = () => {
    setUserView(false);
  };

  const getStatus = (status) => {
    const statusColors = {
      0: {
        ml: 2,
        backgroundColor: "rgb(235 193 14 / 24%)",
        color: "orange",
        label: "Pending",
      },
      1: {
        backgroundColor: "rgba(18, 183, 106, 0.05)",
        color: "#12B76A",
        label: "Active",
      },

      2: {
        backgroundColor: "rgba(240, 68, 56, 0.05)",
        color: "#F04438;",
        label: "InActive",
      },
      // not a convenient way
      3: {
        backgroundColor: "rgba(240, 68, 56, 0.05)",
        color: "#F04438;",
        label: "Deleted",
      },
    };

    return (
      <StyledChip
        sx={statusColors[status]}
        // label={status ? "Active" : "InActive"}
        label={statusColors[status].label}
      />
    );
  };

  async function getUsermemberListApi(id) {
    setUserFamilyMemberData([]);
    const res = await userFamilyMemberApi(id);
    if (res.data) {
      setUserFamilyMemberData(res.data);
    } else {
      document.getElementById("msgdiv").innerText = "No Family Member Found";
    }
  }
  const isProfileImageValid = (profile) => {
    return profile && !profile.includes("null") && profile.includes("https")
      ? true
      : false;
  };

  const onSubmit = async () => {
    const toastType = {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    };

    console.log("deleted User id", currentUser);
    const id = currentUser.id;
    setOpenBreakdrop(true);
    const res = await deleteUserApi(id);
    if (res && res.status) {
      toast.success(res.message, toastType);
    } else {
      toast.error(res.message, toastType);
    }
    setOpenBreakdrop(false);
    closedelete();
    refreshScreen();
  };
  return (
    <>
      <Backdrop open={openBackdrop} />
      <ToastContainer limit={1} />
      <div
        style={{
          backgroundColor: "white",
          borderRadius: "10px",
          color: "black",
        }}
      >
        {/* search bar and filter button */}
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          sx={{ padding: "20px 0px" }}
        >
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              sx={{
                height: "50px",
              }}
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
              onKeyUp={debounceserach}
            />
          </Search>

          <Button
            sx={{
              border: "1px solid rgba(15, 15, 15, 0.15)",
              borderRadius: "10px",
              mr: 2,
              padding: "5px 20px",
              fontWeight: "bold",
              color: "black",
            }}
            endIcon={
              <IconButton sx={{ ml: 3 }}>
                <img src={filterIcon} alt="Filter Options" />
              </IconButton>
            }
            onClick={openFilterDrawer}
          >
            Filter
          </Button>
        </Stack>
        {/* drawer */}
        <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
          <form
            onSubmit={onFilterSubmit}
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                // width: 220,
                padding: "10px 20px",
              }}
            >
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography sx={{ fontWeight: "bold" }}>Filter</Typography>
                <IconButton sx={{ color: "black" }} onClick={closeFilterDrawer}>
                  <CloseIcon />
                </IconButton>
              </Stack>

              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="name">Name </StyledFormLabel>
                <StyledTextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search Name"
                  fullWidth
                  id="name"
                  name="name"
                  value={values.name}
                  onChange={handleInputChange}
                />
              </Box>

              <Divider sx={{ mt: 2 }} />
              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="email">Email </StyledFormLabel>
                <StyledTextField
                  type="email"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search Email"
                  fullWidth
                  id="email"
                  name="email"
                  value={values.email}
                  onChange={handleInputChange}
                />
              </Box>
              <Divider sx={{ mt: 2 }} />

              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="mobile">
                  Mobile Number{" "}
                </StyledFormLabel>
                <StyledTextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Mobile Number"
                  fullWidth
                  id="mobile"
                  name="mobile"
                  type="number"
                  value={values.mobile}
                  onChange={(e) => {
                    const limit = 10;
                    e.target.value = Number(e.target.value.substring(0, limit));
                    handleInputChange(e);
                  }}
                />
              </Box>
              <Divider sx={{ mt: 2 }} />

              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="status">Status</StyledFormLabel>
                <StyledTextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Status"
                  fullWidth
                  id="status"
                  name="status"
                  value={values.status}
                  select
                  onChange={handleInputChange}
                >
                  <MenuItem sx={{ width: "250px" }} value="1">
                    Active
                  </MenuItem>
                  <MenuItem value="2">In Active</MenuItem>
                  <MenuItem value="3">Deleted</MenuItem>
                </StyledTextField>
              </Box>
              <Divider sx={{ mt: 2 }} />
            </Box>
            <Stack
              sx={{ margin: "50px 20px 25px 20px" }}
              flexDirection="row"
              justifyContent="space-between"
            >
              <Button
                sx={{
                  backgroundColor: "white",
                  color: "black",
                  border: "1px solid #0F0F0F80",
                  borderRadius: "10px",
                  padding: "10px 25px",
                  "&:hover": {
                    // backgroundColor: "#0957DD",
                  },
                }}
                onClick={() => {
                  resetForm();
                  onFilterSubmit();
                }}
              >
                Reset
              </Button>
              <Button
                sx={{
                  backgroundColor: "#0957DD",
                  color: "white",
                  borderRadius: "10px",
                  padding: "10px 25px",
                  "&:hover": {
                    backgroundColor: "#0957DD",
                  },
                }}
                type="submit"
              >
                Filter
              </Button>
            </Stack>
          </form>
        </Drawer>
        {/* table */}
        <TableContainer
          component={Paper}
          sx={{ minHeight: "300px", overflowY: "scroll" }}
        >
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell className="table-header-font">
                  Name
                </StyledTableCell>
                <StyledTableCell className="table-header-font">
                  Email
                </StyledTableCell>
                <StyledTableCell className="table-header-font">
                  Mobile
                </StyledTableCell>
                <StyledTableCell className="table-header-font">
                  Status
                </StyledTableCell>
                <StyledTableCell className="table-header-font">
                  Date
                </StyledTableCell>
                <StyledTableCell className="table-header-font">
                  Action
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody id="accordian">
              {users?.map((row, index) => {
                var {
                  fname,
                  lname,
                  profile_image,
                  email,
                  mobile,
                  createdAt,
                  isActive,
                } = row;
                var name = (fname ? fname : "") + " " + (lname ? lname : "");
                name = name === " " ? "-" : name;
                email = email ? email : "-";

                return (
                  <>
                    <StyledTableRow
                      data-toggle="collapse"
                      data-target={"#demo" + index}
                      class="accordion-toggle"
                      aria-controls={"#demo" + index}
                      onClick={() => {
                        setIsExpanded((preval) => {
                          if (preval === index) {
                            return -1;
                          } else {
                            getUsermemberListApi(row.id);
                            return index;
                          }
                        });
                      }}
                    >
                      <TableCell component="th" scope="row">
                        <Stack flexDirection="row" alignItems="center">
                          {isProfileImageValid(profile_image) ? (
                            <img
                              style={{
                                width: "40px",
                                height: "40px",
                                borderRadius: "50%",
                              }}
                              sx={{ mx: 0.5 }}
                              alt="User Profile"
                              src={profile_image}
                            />
                          ) : (
                            <Avatar>
                              <PersonIcon />
                            </Avatar>
                          )}

                          <Typography sx={{ ml: 2 }}>
                            {name?.length > 15
                              ? name.substr(0, 15) + "..."
                              : name}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell>{email}</TableCell>
                      <TableCell>{mobile}</TableCell>
                      <TableCell>{getStatus(isActive)}</TableCell>
                      {/* <TableCell>{isActive}</TableCell> */}
                      <TableCell>{moment(createdAt).format("LLL")}</TableCell>
                      <TableCell>
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            const currentUser = users.find(
                              (role) => role.id === row.id
                            );
                            handleClick(e, currentUser);
                          }}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </TableCell>
                    </StyledTableRow>

                    {isExpanded === index && (
                      <StyledTableRow style={{ background: "#f0f0f0" }}>
                        <TableCell colspan="6">
                          <div
                            class=" accordion-body collapse"
                            id={"demo" + index}
                          >
                            {userFamilyMemberData.length ? (
                              userFamilyMemberData.map((data, index) => {
                                return (
                                  <>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-around",
                                      }}
                                    >
                                      <td style={{ width: "20%" }}>
                                        <Stack
                                          flexDirection="row"
                                          alignItems="center"
                                        >
                                          {isProfileImageValid(
                                            data.profileImage
                                          ) ? (
                                            <img
                                              style={{
                                                width: "40px",
                                                height: "40px",
                                                borderRadius: "50%",
                                              }}
                                              sx={{ mx: 0.5 }}
                                              alt="User Profile"
                                              src={data.profileImage}
                                            />
                                          ) : (
                                            <Avatar>
                                              <PersonIcon />
                                            </Avatar>
                                          )}

                                          <Typography sx={{ ml: 2 }}>
                                            {data.name?.length > 15
                                              ? data.name.substr(0, 15) + "..."
                                              : data.name}
                                          </Typography>
                                        </Stack>
                                      </td>
                                      <td style={{ width: "20%" }}>
                                        {data.relation}
                                      </td>
                                      <td style={{ width: "20%" }}>
                                        {data.email}
                                      </td>
                                      <td style={{ width: "20%" }}>
                                        {data.mobile}
                                      </td>

                                      <td>
                                        <IconButton
                                          style={{ paddingRight: "4px" }}
                                          onClick={(e) => {
                                            handleClick(e, data);
                                          }}
                                        >
                                          <MoreVertIcon />
                                        </IconButton>
                                      </td>
                                    </div>
                                    {index < userFamilyMemberData.length - 1 ? (
                                      <Divider />
                                    ) : (
                                      ""
                                    )}
                                  </>
                                );
                              })
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  padding: "5px 0",
                                  color: "red",
                                }}
                                id="msgdiv"
                              >
                                <CircularProgress />
                              </div>
                            )}
                          </div>
                        </TableCell>
                      </StyledTableRow>
                    )}
                  </>
                );
              })}
              {users?.length === 0 ? (
                <TableRow>
                  <TableCell
                    sx={{
                      color: "silver",
                      textAlign: "center",
                      paddingTop: "90px",
                      borderBottom: "none",
                      fontSize: "30px",
                    }}
                    colSpan="6"
                  >
                    No records to display
                  </TableCell>
                </TableRow>
              ) : (
                ""
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClick={(e) => {
            handleClose();
          }}
        >
          <MenuItem
            sx={{ mt: 1 }}
            onClick={() => {
              handleUserView();
            }}
          >
            <ListItemIcon>
              <VisibilityIcon />
            </ListItemIcon>
            <ListItemText>View Details</ListItemText>
          </MenuItem>
          {currentUser["relation"] === undefined && (
            <MenuItem
              sx={{ mt: 1 }}
              onClick={() => {
                handledelete();
              }}
            >
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
            </MenuItem>
          )}
        </Menu>
        {userView && (
          <UsersDetailsModal
            user={currentUser}
            open={userView}
            setOpen={setUserView}
            handleClose={closeUserView}
            refreshScreen={refreshScreen}
          />
        )}
        {deleteValue && (
          <DeleteComponent
            open={deleteValue}
            handleClose={closedelete}
            onSubmit={onSubmit}
          />
        )}
      </div>
      {/* pagination */}
      <Stack
        sx={{ mt: 4, pb: 2 }}
        flexDirection="row"
        justifyContent="space-between"
      >
        <div>
          Showing{" "}
          {page * pagePerSize > totalRecords
            ? totalRecords
            : page * pagePerSize}{" "}
          of {totalRecords} Results
        </div>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
        />
      </Stack>
    </>
  );
}
