import React from 'react';
import { Button, Stack, Typography, styled, IconButton, InputAdornment } from '@mui/material';

import Box from '@mui/material/Box';
import { ConstructionOutlined, Visibility, VisibilityOff } from "@mui/icons-material";
import { useState, useEffect } from "react";
import { TextField, Tooltip, } from "@mui/material";
import { Link } from "react-router-dom";
import { useForm, Form } from "../../Utils/useForm";
import { forgotPassword } from '../../state/actions/authActions'
import user from '../../Assets/user1.png'
import { ToastContainer, toast } from "react-toastify";
import {
    Avatar,
    Badge,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import Backdrop from '../BackDrop/BackDrop';


const StyledTextField = styled(TextField)(() => ({
    width: '100% !important',
    margin: '8px 0 8px 0',
}))


const adminimage = {
    width: "120px",
    height: "120px",
    borderRadius: "200px"
}

const StyledLabel = styled("label")(() => ({
    display: "inline-block",
    fontWeight: "500",
    fontSize: "14px",
    mt: 1,
    borderRadius: "8px",
}));



const initialValues = {
    oldPassword: "",
    newPassword: ""
};
const StyledLogin = styled(Button)(() => ({
    backgroundColor: "#0957DD",
    borderRadius: "10px",
    color: "white",
    fontSize: "16px",
    padding: "8px 20px",
    "&:hover": {
        backgroundColor: "#0957DD",
        color: "white"
    }
}));


export default function ResetPassword(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { user, open, refreshScreen, handleClose } = props;

    // const [newPassword, setNewPassword] = useState("");
    // const [confirmPassword, setConfirmPassword] = useState("");

    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmNewPassword] = useState(false);
    const [openBackdrop, setOpenBreakdrop] = useState(false);


    // const handleNewPasswordChange = e => {
    //     setNewPassword(e.target.value);
    // };
    // const handleConfirmPasswordChange = e => {
    //     setConfirmPassword(e.target.value);
    // };

    const handleClickShowNewPassword = () => {
        setShowNewPassword(!showNewPassword);
    };
    const handleClickShowConfirmPassword = () => {
        setShowConfirmNewPassword(!showConfirmPassword);
    };

    const validatePassword = (fieldValues, temp, key, value) => {
        if (value !== "") {
            if (value.length < 8) {
                temp[key] = "Password  must be 8 charecter";
            } else {
                if (value.length > 128) {
                    temp[key] = "Password  must be less than 128 charecter";

                } else {


                    if (!/[0-9]/.test(value)) {
                        temp[key] = "Password  must be 1 Number";
                    } else {
                        if (!/[a-z]/.test(value)) {
                            temp[key] = "Password  must be 1 Lowercase letter";
                        } else {
                            if (!/[A-Z]/.test(value)) {
                                temp[key] = "Password  must be 1 Uppercase letter";
                            } else {
                                // setPass(fieldValues.password);
                                temp[key] = "";
                            }
                        }
                    }
                }
            }
        } else {
            temp[key] = "This field is required.";
        }
    }

    const validate = (fieldValues = values) => {
        let temp = { ...errors };

        if ("oldPassword" in fieldValues) {
            validatePassword(fieldValues, temp, 'oldPassword', fieldValues.oldPassword)
        }
        if ("newPassword" in fieldValues) {
            validatePassword(fieldValues, temp, 'newPassword', fieldValues.newPassword)
        }
        setErrors({
            ...temp,
        });

        if (fieldValues === values)
            return Object.values(temp).every((x) => x === "");
    };

    const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
        useForm(initialValues, true, validate);

    const handleSubmit = async (e) => {
        const toastType = {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
        };
        e.preventDefault();



        if (validate()) {
            setOpenBreakdrop(true);
            const response = await dispatch(forgotPassword(values));
           
            if (response && response.status === true) {
                setOpenBreakdrop(false)
                toast.success(response.message, toastType);
                handleClose();
            } else if (response && response.success === false) {
                setOpenBreakdrop(false)
                toast.error(
                    response.message ||
                    "Unexpected error occured please try again later.",
                    toastType
                );
            }
        }
    }
    useEffect(() => {
        resetForm();
    }, [handleClose])


    return (
        <>
            <Backdrop open={openBackdrop} />

            <Dialog
                open={open}
                onClose={() => {

                    handleClose();
                }}
            >
                <DialogTitle
                    sx={{
                        fontWeight: "bold",
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    Change Password
                    <IconButton onClick={handleClose}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{m:1}}>

                    <Form onSubmit={handleSubmit} autoComplete="off">
                        <Box sx={{ mt: 2 }}>
                            <StyledLabel>Current Password</StyledLabel>
                            <StyledTextField
                                name='oldPassword'
                                value={values.oldPassword}
                                // onChange={handleNewPasswordChange}
                                onChange={handleInputChange}
                                type={showNewPassword ? "text" : "password"}
                                sx={{ mt: 1, borderRadius: "8px" }}
                                size="small"
                                fullWidth
                                error={Boolean(errors.oldPassword)}
                                helperText={errors.oldPassword}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start" sx={{ m: 0 }}>
                                            <Tooltip
                                                title={
                                                    showNewPassword
                                                        ? "Hide Password"
                                                        : "Show Password"
                                                }
                                            >
                                                <IconButton onClick={handleClickShowNewPassword}>
                                                    {showNewPassword ? (
                                                        <VisibilityOff />
                                                    ) : (
                                                        <Visibility />
                                                    )}
                                                </IconButton>
                                            </Tooltip>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                        <Box sx={{ mt: 2 }}>
                            <StyledLabel>New Password</StyledLabel>
                            <StyledTextField
                                name='newPassword'
                                value={values.newpassword}
                                // onChange={handleConfirmPasswordChange}
                                onChange={handleInputChange}
                                type={showConfirmPassword ? "text" : "password"}
                                sx={{ mt: 1, borderRadius: "8px" }}
                                size="small"
                                fullWidth
                                error={Boolean(errors.newPassword)}
                                helperText={errors.newPassword}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start" sx={{ m: 0 }}>
                                            <Tooltip
                                                title={
                                                    showConfirmPassword
                                                        ? "Hide Password"
                                                        : "Show Password"
                                                }
                                            >
                                                <IconButton
                                                    onClick={handleClickShowConfirmPassword}
                                                >
                                                    {showConfirmPassword ? (
                                                        <VisibilityOff />
                                                    ) : (
                                                        <Visibility />
                                                    )}
                                                </IconButton>
                                            </Tooltip>
                                        </InputAdornment>
                                    ),

                                }}
                            />
                        </Box>

                        <Box>
                            <StyledLogin sx={{ mt: 3, mr: 3 }} type="submit">
                                Reset
                            </StyledLogin>
                            <StyledLogin sx={{ mt: 3 }} onClick={() => {
                                handleClose();
                            }}>
                                Cancel
                            </StyledLogin>
                        </Box>

                    </Form>
                </DialogContent>

            </Dialog>
        </>
    )
};
