import React, { useState } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";

import NursesList from './NursesList'

const Nurses = () => {
  const [refresh, setRefresh] = useState(false);
  const refreshScreen = () => {
    setRefresh(!refresh);
  };
 
  return (
    <>
      <Stack flexDirection="row" justifyContent="space-between">
        <Typography sx={{ fontSize: "30px", fontWeight: "bold" }}>
          Nurses List
        </Typography>
        {/* <Button
          sx={{
            backgroundColor: "#0957DD",
            color: "white",
            borderRadius: "10px",
            padding: "15px 40px",
            "&:hover": {
              backgroundColor: "#0957DD",
            },
          }}
          onClick={openNurseModal}
          startIcon={<Add />}
          color="primary"
        >
          Add Caregiver
        </Button> */}
      </Stack>
      {/* <AddCaregiver
        open={addNurse}
        setOpen={setAddNurse}
        handleClickOpen={openNurseModal}
        handleClose={closeNurseModal}
        refreshScreen={refreshScreen}
      /> */}
      <Box sx={{ mt: 4 }}>
        <NursesList refresh={refresh} refreshScreen={refreshScreen} />
      </Box>
    </>
  );
};
export default Nurses;
