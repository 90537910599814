import handleAuthToken from "../../Utils/handleAuthToken";
import axios from "axios";




export const subCategoryListAll = async (queryString) => {
    if (localStorage.token) {
        handleAuthToken(localStorage.token);
    }

    try {
        const res = await axios.post(`/admin/av1/subcategoryList`+queryString);
        return res.data;

    } catch (error) {
        return { success: false, message: error.response.data.message };
    }

}

export const subCategoryListApi = async (categoryId) => {
    if (localStorage.token) {
        handleAuthToken(localStorage.token);
    }

    try {
        const res = await axios.get(`/admin/av1/subcategoryListDropdown/${categoryId}`);
        return res.data;

    } catch (error) {
        return { success: false, message: error.response.data.message };
    }

}

export const categoryListDropdown = async (id) => {
    if (localStorage.token) {
        handleAuthToken(localStorage.token);
    }

    try {
        const res = await axios.get(`/admin/av1/serviceListDoropDown/${id}`);
        return res.data;

    } catch (error) {
        return { success: false, message: error.response.data.message };
    }

}

export const categoryListApiDropdown = async (id) => {
    if (localStorage.token) {
        handleAuthToken(localStorage.token);
    }
    try {
        const res = await axios.get(`/admin/av1/categoryListCondition`);
        return res.data
    } catch (error) {
        return { success: false, message: error.response.data.message };
    }

}

export const addsubCategoryApi = async (data) => {
    if (localStorage.token) {
        handleAuthToken(localStorage.token);
    }

    try {
        const res = await axios.post(`/admin/av1/addSubCategory`, data);
        return res.data;

    } catch (error) {
        return { success: false, message: error.response.data.message };
    }

}

export const subCategoryDetailsApi = async (id) => {
    if (localStorage.token) {
        handleAuthToken(localStorage.token)
    }
    try {
        const res = await axios.post(`/admin/av1/subcategoryDetail/${id}`);
        return res.data;
    } catch (error) {
        return { success: false, message: error.response.data.message };
    }
}

export const updateSubCategoryApi = async (id, data) => {
    if (localStorage.token) {
        handleAuthToken(localStorage.token)
    }
    try {
        const res = await axios.post(`/admin/av1/subcategoryUpdate/${id}`, data);
        return res.data;
    } catch (error) {
        return { success: false, message: error.response.data.message };
    }
}

export const deleteSubCategory = async (id) => {
    if (localStorage.token) {
      handleAuthToken(localStorage.token);
    }
    try {
      const res = await axios.get(`admin/av1/deleteSubcategory/${id}`);
      return res.data;
    } catch (error) {
      return { success: false, message: error.response.data.message };
    }
  };
  