import React, { useState, useEffect } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";
import ServicesList from "./servicesList";
import AddServiceModel from "./AddServiceModel";
import Backdrop from "../BackDrop/BackDrop";

export default function Services() {
  const [AddServicePopup, setAddServicePopup] = useState(false);

  const [openBackdrop, setOpenBreakdrop] = useState(false);

  const [refresh, setRefresh] = useState(false);
  const refreshScreen = () => {
    setRefresh(!refresh);
  };

  const openServiceModal = () => {
    setAddServicePopup(true);
  };
  const closeServiceModal = () => {
    setAddServicePopup(false);
  };

  return (
    <>
      <Backdrop open={openBackdrop} />
      <Stack flexDirection="row" justifyContent="space-between">
        <Typography sx={{ fontSize: "30px", fontWeight: "bold" }}>
          Category
        </Typography>

        <Button
          sx={{
            backgroundColor: "#0957DD",
            color: "white",
            borderRadius: "10px",
            padding: "8px 15px",
            fontWeight: 500,
            "&:hover": {
              backgroundColor: "#0042b4",
            },
          }}
          onClick={openServiceModal}
          startIcon={<Add />}
          color="primary"
        >
          Add Category
        </Button>
      </Stack>
      {AddServicePopup && (
        <AddServiceModel
          open={AddServicePopup}
          handleClickOpen={openServiceModal}
          handleClose={closeServiceModal}
          refreshScreen={refreshScreen}
          action="add"
        />
      )}
      <Box sx={{ mt: 4 }}>
        <ServicesList refresh={refresh}  refreshScreen={refreshScreen} />
      </Box>
    </>
  );
}
