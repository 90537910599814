import React, { useState } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";

import HSNList from "./HSNList";
import AddHSN from "./AddHSN";

const HSNPage = () => {
  const [refresh, setRefresh] = useState(false);
  const refreshScreen = () => {
    setRefresh(!refresh);
  };
  const [addHsn, setAddHsn] = useState(false);
  const openHsnModal = () => {
    setAddHsn(true);
  };
  const closeHsnModal = () => {
    setAddHsn(false);
  };
  return (
    <>
      <Stack flexDirection="row" justifyContent="space-between">
        <Typography sx={{ fontSize: "30px", fontWeight: "bold" }}>
          HSN List
        </Typography>
        <Button
          sx={{
            backgroundColor: "#0957DD",
            color: "white",
            borderRadius: "10px",
            padding: "15px 40px",
            "&:hover": {
              backgroundColor: "#0957DD",
            },
          }}
          onClick={openHsnModal}
          startIcon={<Add />}
          color="primary"
        >
          Add HSN
        </Button>
      </Stack>
      <AddHSN
        open={addHsn}
        setOpen={setAddHsn}
        handleClickOpen={openHsnModal}
        handleClose={closeHsnModal}
        refreshScreen={refreshScreen}
      />
      <Box sx={{ mt: 4 }}>
        <HSNList refresh={refresh} refreshScreen={refreshScreen} />
      </Box>
    </>
  );
};
export default HSNPage;
