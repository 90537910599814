import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Box,
  Typography,
  IconButton,
  TextField,
  FormLabel,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Close as CloseIcon } from "@mui/icons-material";

import camera from "../../Assets/camera.svg";
import { useForm } from "../../Utils/useForm";

import Backdrop from "../BackDrop/BackDrop";
import {
  updateCategoryApi,
  categoryAddApi,
} from "../../state/actions/categoryAction";
import { addStateApi } from "../../state/actions/addressAction";

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
}));
const StyledTextField = styled(TextField)(() => ({
  marginTop: "10px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
  },
  ".css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
    padding: "14px",
  },
}));

const StyledRequired = styled("span")({
  color: "red",
  marginLeft: "2px",
  fontSize: "20px",
});

const AddStateaddress = (props) => {
  const dispatch = useDispatch();
  const { open, refreshScreen, handleClose, category } = props;

  const [isdisabled, setIsDisabled] = useState(false);
  const [openBackdrop, setOpenBreakdrop] = useState(false);

  const [image, setImage] = useState("");
  const resetImage = () => {
    setImage("");
  };
  const reset = () => {
    resetImage();
    resetForm();
  };

  const initialValues = {
    statename: "",
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("statename" in fieldValues)
      temp.statename = fieldValues.statename ? "" : "Only characters are allowed.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  useEffect(() => {
    if (props.category && props.category !== -1) {
      setValues(props.category);
      setImage(props.category.image);
    }
  }, [props]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const toastType = {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    };

    if (validate()) {
      setOpenBreakdrop(true);

      const res = await addStateApi(values);

      if (res && res.status === true) {
        setOpenBreakdrop(false);
        setIsDisabled(true);
        close();
        toast.success(res.message, toastType);
        refreshScreen();
      } else {
        setOpenBreakdrop(false);
        toast.error(res.message, toastType);
      }
    } else {
      setOpenBreakdrop(false);
    }
  };

  const close = () => {
    reset();
    handleClose();
    setIsDisabled(false);
  };

  return (
    <>
      <Backdrop open={openBackdrop} />

      <Dialog open={open} fullWidth={true}>
        <DialogTitle
          sx={{
            fontWeight: "bold",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {props.category && props.category !== -1 ? "Edit" : "Add"} State
          <Tooltip title="Close">
            <IconButton onClick={close}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit} id="addNewCategory">
            <Grid
              container
              xs={12}
              spacing={2}
              style={{
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Grid
                item={true}
                md={12}
                sm={12}
                xs={12}
                container
                style={{ alignItems: "center", justifyContent: "center" }}
              >
                <Grid item={true} md={8} xs={12}>
                  <StyledFormLabel htmlFor="statename">
                    State Name
                    {Boolean(errors.statename) && (
                      <StyledRequired> * </StyledRequired>
                    )}
                  </StyledFormLabel>
                  <StyledTextField
                    fullWidth
                    id="statename"
                    name="statename"
                    type="text"
                    value={values.statename}
                    // onChange={handleInputChange}
                    error={Boolean(errors.statename)}
                    helperText={errors.statename}
                    inputProps={{ maxLength: 50 }}
                    onChange={(e) => {
                      e.target.value = e.target.value.replace(/[^a-z]/gi, "");
                      handleInputChange(e);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions sx={{ margin: "15px" }}>
          <Button
            sx={{
              backgroundColor: "white",
              color: "black",
              border: "1px solid #0F0F0F80",
              borderRadius: "10px",
              padding: "10px 25px",
              "&:hover": {},
            }}
            onClick={close}
          >
            Cancel
          </Button>
          <Button
            sx={{
              backgroundColor: "#0957DD",
              color: "white",
              borderRadius: "10px",
              padding: "10px 25px",
              "&:hover": {
                backgroundColor: "#0957DD",
              },
            }}
            disabled={isdisabled}
            type="submit"
            form="addNewCategory"
          >
            {props.category && props.category !== -1 ? "Save" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddStateaddress;
