import handleAuthToken from '../../Utils/handleAuthToken'
import axios from "axios";


export const categoryAddApi = (data) => async (dispatch) => {
    if (localStorage.token) {
        handleAuthToken(localStorage.token);
    }


    try {
        const res = await axios.post('/admin/av1/addCategory', data);
        return res.data
    } catch (error) {
        return { success: false, message: error.response.data.message };
    }
}

export const categoryListApi = () => async (dispatch) => {
    if (localStorage.token) {
        handleAuthToken(localStorage.token);
    }
    try {
        const res = await axios.post(`/admin/av1/categoryList`);
        return res.data
    } catch (error) {
        return { success: false, message: error.response.data.message };
    }
}


export const updateCategoryApi = (id, data) => async (dispatch) => {

    try {

        const res = await axios.post(`/admin/av1/categoryUpdate/${id}`, data);
        return res.data

    } catch (error) {
        return { success: false, message: error.response.data.message };
    }
}

export const deleteCategory = async (id) => {
    if (localStorage.token) {
      handleAuthToken(localStorage.token);
    }
    try {
      const res = await axios.get(`admin/av1/deleteCategory/${id}`);
      return res.data;
    } catch (error) {
      return { success: false, message: error.response.data.message };
    }
  };
  