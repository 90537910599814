import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";

import { styled } from "@mui/material/styles";
import {
  Search as SearchIcon,
  MoreVert as MoreVertIcon,
  Close as CloseIcon,
  Person as PersonIcon,
  Info as InfoIcon,
} from "@mui/icons-material";
import {
  InputBase,
  Button,
  Typography,
  IconButton,
  Stack,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Drawer,
  Divider,
  Box,
  TextField,
  FormLabel,
  InputAdornment,
  CircularProgress,
  Avatar,
  Pagination,
} from "@mui/material";
import moment from "moment";

import eye from "../../Assets/eye.svg";
import edit from "../../Assets/edit.svg";
import deleteIcon from "../../Assets/delete.svg";
import filterIcon from "../../Assets/filter.svg";
import EditHSN from "./AddHSN";

import { getHsns } from "../../state/actions/hsnAction";
import { useForm } from "../../Utils/useForm";
import Backdrop from "../BackDrop/BackDrop";
// import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.min";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  color: "black",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  borderRadius: "10px",
  backgroundColor: "white",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    paddingRight: "15px",
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: "#0F0F0F80",
}));

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
}));
const StyledTextField = styled(TextField)(() => ({
  marginTop: "10px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
  },
}));
const StyledChip = styled(Chip)(() => ({
  borderRadius: "10px",
  fontSize: "14px",
}));

export default function HSNList(props) {
  const { refresh, refreshScreen } = props;
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [pagePerSize, setPagePerSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [searchValue, setSearchValue] = useState("");

  const [hsn, setHSNS] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentHSN, setCurrentHSN] = useState(-1);
  const [userView, setUserView] = useState(false);
  const [deleteUser, setDeleteUser] = useState(false);
  const [addUser, setAddUser] = useState(false);
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const [editHsn, setEditHsn] = useState(false);
  const openHsnModal = () => {
    setEditHsn(true);
  };
  const closeHsnModal = () => {
    setEditHsn(false);
  };

  // api call for get a users-list
  async function getHSNSList(page) {
    setOpenBreakdrop(true);
    let queryString =
      "?page=" + page + "&size=" + pagePerSize + "&search=" + searchValue;
    let response = await getHsns(queryString);
    if (response.status === true) {
      setHSNS(response.data);
      setTotalRecords(response.totalRecord);
      setTotalPages(Math.ceil(response.totalRecord / pagePerSize));
    } else {
      setHSNS([]);
      setTotalRecords(0);
      setTotalPages(0);
    }

    setOpenBreakdrop(false);
  }
  useEffect(() => {
    getHSNSList(page);
  }, [refresh]);

  useEffect(() => {
    handleClose();
  }, [hsn]);

  const handleChangePage = (event, value) => {
    setPage(value);
    getHSNSList(value);
  };

  const open = Boolean(anchorEl);
  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setCurrentHSN(index);
  };
  const handleClose = () => {
    setAnchorEl(null);
    // setCurrentUser(-1);
  };

  const onSearch = async (search) => {
    if (search.target.value === "") {
      resetForm();
    }
    setSearchValue(search.target.value);

    let queryString =
      "?page=" + 1 + "&size=" + pagePerSize + "&search=" + search.target.value;
    setOpenBreakdrop(true);
    const res = await getHsns(queryString);
    if (res && res.status) {
      setHSNS(res.data);
      setTotalRecords(res.totalRecord);
      setTotalPages(Math.ceil(res.totalRecord / pagePerSize));
    } else {
      setHSNS([]);
      setTotalRecords(0);
      setTotalPages(0);
    }
    setOpenBreakdrop(false);
  };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;

        func.apply(context, args);
      }, 700);
    };
  };

  const debounceserach = debounce(onSearch);

  const initialValues = {
    hsnName: "",
    hsnCode: "",
  };
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);

  const [filter, setFilter] = useState(false);
  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
  };

  const onFilterSubmit = async (e) => {
    if (typeof e != "undefined") {
      e.preventDefault();
    }
    //  we are using initialValues because our values object is not resetting.
    var formValues = typeof e === "undefined" ? initialValues : values;
    let queryString = "?search=" + "&page=" + 1 + "&size=" + pagePerSize;
    Object.keys(formValues).map((key) => {
      queryString += "&" + key + "=" + formValues[key];
    });
    setOpenBreakdrop(true);
    const res = await getHsns(queryString);
    if (res && res.status) {
      setHSNS(res.data);
      setTotalRecords(res.totalRecord);
      setTotalPages(Math.ceil(res.totalRecord / pagePerSize));
    } else {
      setHSNS([]);
      setTotalRecords(0);
      setTotalPages(0);
    }
    setOpenBreakdrop(false);
    closeFilterDrawer();
  };

  const handleUserView = () => {
    setUserView(true);
  };
  const closeUserView = () => {
    setUserView(false);
  };

  const getStatus = (status) => {
    const statusColors = {
      1: {
        backgroundColor: "rgba(18, 183, 106, 0.05)",
        color: "#12B76A",
      },

      0: {
        backgroundColor: "rgba(240, 68, 56, 0.05)",
        color: "#F04438;",
      },
    };
    return (
      <StyledChip
        sx={statusColors[status]}
        label={status ? "Active" : "InActive"}
      />
    );
  };

  return (
    <>
      <Backdrop open={openBackdrop} />
      <ToastContainer limit={1} />
      <div
        style={{
          backgroundColor: "white",
          borderRadius: "10px",
          color: "black",
        }}
      >
        {/* search bar and filter button */}
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          sx={{ padding: "20px 0px" }}
        >
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              sx={{
                height: "50px",
              }}
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
              onKeyUp={debounceserach}
            />
          </Search>

          <Button
            sx={{
              border: "1px solid rgba(15, 15, 15, 0.15)",
              borderRadius: "10px",
              mr: 2,
              padding: "5px 20px",
              fontWeight: "bold",
              color: "black",
            }}
            endIcon={
              <IconButton sx={{ ml: 3 }}>
                <img src={filterIcon} alt="Filter Options" />
              </IconButton>
            }
            onClick={openFilterDrawer}
          >
            Filter
          </Button>
        </Stack>
        {/* drawer */}
        <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
          <form
            onSubmit={onFilterSubmit}
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                // width: 220,
                padding: "10px 20px",
              }}
            >
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography sx={{ fontWeight: "bold" }}>Filter</Typography>
                <IconButton sx={{ color: "black" }} onClick={closeFilterDrawer}>
                  <CloseIcon />
                </IconButton>
              </Stack>

              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="hsnName">Name </StyledFormLabel>
                <StyledTextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search HSN Name"
                  fullWidth
                  id="hsnName"
                  name="hsnName"
                  value={values.hsnName}
                  onChange={handleInputChange}
                />
              </Box>

              <Divider sx={{ mt: 2 }} />
              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="hsnCode">HSN Code </StyledFormLabel>
                <StyledTextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search HSN Code"
                  fullWidth
                  id="hsnCode"
                  name="hsnCode"
                  value={values.hsnCode}
                  onChange={handleInputChange}
                />
              </Box>

              <Divider sx={{ mt: 2 }} />
            </Box>
            <Stack
              sx={{ margin: "80px 20px 25px 20px" }}
              flexDirection="row"
              justifyContent="space-between"
            >
              <Button
                sx={{
                  backgroundColor: "white",
                  color: "black",
                  border: "1px solid #0F0F0F80",
                  borderRadius: "10px",
                  padding: "10px 25px",
                  "&:hover": {
                    // backgroundColor: "#0957DD",
                  },
                }}
                onClick={() => {
                  resetForm();
                  onFilterSubmit();
                }}
              >
                Reset
              </Button>
              <Button
                sx={{
                  backgroundColor: "#0957DD",
                  color: "white",
                  borderRadius: "10px",
                  padding: "10px 25px",
                  "&:hover": {
                    backgroundColor: "#0957DD",
                  },
                }}
                type="submit"
              >
                Filter
              </Button>
            </Stack>
          </form>
        </Drawer>
        {/* table */}
        <TableContainer
          component={Paper}
          sx={{ minHeight: "300px", overflowY: "scroll" }}
        >
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Id</StyledTableCell>
                <StyledTableCell>HSN Name</StyledTableCell>
                <StyledTableCell>HSN Code</StyledTableCell>
                <StyledTableCell>GST Rate</StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
                {/* <StyledTableCell>Status</StyledTableCell> */}
                {/* <StyledTableCell>Action</StyledTableCell> */}
              </TableRow>
            </TableHead>
            <TableBody id="accordian">
              {hsn?.map((row, index) => {
                var {
                  id,
                  hsnName,
                  hsnCode,
                  taxRatePercentage,
                  category,
                  status,
                } = row;
                return (
                  <>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        {id}
                      </TableCell>
                      <TableCell>{hsnName}</TableCell>
                      <TableCell>{hsnCode}</TableCell>
                      <TableCell>{taxRatePercentage}%</TableCell>
                      {/* <TableCell>{category}</TableCell> */}
                      {/* <TableCell>{getStatus(status)}</TableCell> */}

                      <TableCell>
                        <IconButton
                          onClick={(e) => {
                            const currentHSN = hsn.find(
                              (role) => role.id === row.id
                            );
                            handleClick(e, currentHSN);
                          }}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}
              {hsn?.length === 0 ? (
                <TableRow>
                  <TableCell
                    sx={{
                      color: "silver",
                      textAlign: "center",
                      paddingTop: "90px",
                      borderBottom: "none",
                      fontSize: "30px",
                    }}
                    colSpan="7"
                  >
                    No records to display
                  </TableCell>
                </TableRow>
              ) : (
                ""
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Menu
          sx={{ mt: 0.3 }}
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClick={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {/* <MenuItem
            sx={{ mt: 1 }}
            onClick={() => {
              handleUserView();
            }}
          >
            <ListItemIcon>
              <InfoIcon />
            </ListItemIcon>
            <ListItemText>View Details</ListItemText>
          </MenuItem> */}
          <MenuItem sx={{ mt: 1 }} onClick={openHsnModal}>
            <ListItemIcon>
              <img src={eye} alt="View Details" />
            </ListItemIcon>
            <ListItemText>Edit Details</ListItemText>
          </MenuItem>
        </Menu>

        {editHsn && (
          <EditHSN
            hsn={currentHSN}
            open={editHsn}
            setOpen={setEditHsn}
            handleClickOpen={openHsnModal}
            handleClose={closeHsnModal}
            refreshScreen={refreshScreen}
          />
        )}
      </div>
      {/* pagination */}
      <Stack
        sx={{ mt: 4, pb: 2 }}
        flexDirection="row"
        justifyContent="space-between"
      >
        <div>
          Showing{" "}
          {page * pagePerSize > totalRecords
            ? totalRecords
            : page * pagePerSize}{" "}
          of {totalRecords} Results
        </div>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
        />
      </Stack>
    </>
  );
}
