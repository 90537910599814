import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Collapse,
} from "@mui/material";
import { ExpandMore, ExpandLess, Person, Dashboard } from "@mui/icons-material";
import PolicyIcon from '@mui/icons-material/Policy';
import GavelIcon from '@mui/icons-material/Gavel';
import CallIcon from '@mui/icons-material/Call';
import SettingsIcon from '@mui/icons-material/Settings';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import logo from "../../Assets/urcarelogo.png";
import products from "../../Assets/product.svg";
import hsnIcn from "../../Assets/HSn-01.svg";
import CodeIcon from '@mui/icons-material/Code';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PeopleIcon from '@mui/icons-material/People';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import CategoryIcon from '@mui/icons-material/Category';
import GradingIcon from '@mui/icons-material/Grading';
import orders from "../../Assets/order.svg";
import payment from "../../Assets/payment.svg";
import setting from "../../Assets/setting.svg";
import locationIcon from "../../Assets/maplocation.svg";

const SelectedStyles = {
  ".MuiListItemButton-root": {
    backgroundColor: "#0957DD",
    ".MuiListItemIcon-root img": {
      filter: " brightness(0) invert(1)",
    },

    ".MuiSvgIcon-root": {
      color: "white",
    },
    ".MuiListItemText-root": {
      color: "white",
    },
  },
};

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname.substr(1);

  //  if unknown route is found then redirect it to dashboard page
  const [active, setActive] = useState(pathname);

  //  state for sub menus
  const [categoryMenu, setCategoryMenu] = useState(false);
  const [usersMenu, setUsersMenu] = useState(false);

  const [citystatemenu, setCityState] = useState(false);
  const [ordersMenu, setOrdersMenu] = useState(false);
  const [settingMenu, setSettingMenu] = useState(false);

  const toggleCategoryMenu = () => {
    setCategoryMenu(!categoryMenu);
  };
  const closeCategoryMenu = () => {
    setCategoryMenu(false);
  };

  const togglelocation = () => {
    setCityState(!citystatemenu);
  };
  const closetogglelocation = () => {
    setCityState(false);
  };

  const toggleUsersMenu = () => {
    setUsersMenu(!usersMenu);
  };
  const closeUsersMenu = () => {
    setUsersMenu(false);
  };

  const toggleOrdersMenu = () => {
    setOrdersMenu(!ordersMenu);
  };
  const closeOrdersMenu = () => {
    setOrdersMenu(false);
  };

  const toggleSettingMenu = () => {
    setSettingMenu(!settingMenu);
  };
  const closeSettingMenu = () => {
    setSettingMenu(false);
  };

  //  to find out current selected tab
  const menus = [
    { menu: "dashboard", subMenu: [] },
    {
      menu: "",
      subMenu: ["users", "nurse", "caregiver"],
      toggle: toggleUsersMenu,
    },

    {
      menu: "",
      subMenu: ["category", "services", "subcategory"],
      toggle: toggleCategoryMenu,
    },
    {
      menu: "",
      subMenu: ["states", "cities"],
      toggle: togglelocation,
      alternatePath: ["address/cities"],
    },
    {
      menu: "equipments",
      subMenu: [],
      alternatePath: [
        "equipments/add",
        "equipments/edit",
        "equipments/details",
      ],
    },
    {
      menu: "",
      subMenu: ["equipmentsorder", "nurses"],
      alternatePath: ["equipmentsorder/details", "nurses/details"],
      toggle: toggleOrdersMenu,
    },
    { menu: "payment", subMenu: [] },
    { menu: "settings", subMenu: ["privacypolicy", 'termscondition', 'contactsupport', "cancellationreason"], toggle: toggleSettingMenu },
  ];

  //  after refreshing the page select the respective tab
  const getCurrentTab = () => {
    if (pathname === "") {
      navigate("/dashboard");
    }
    var flag = false;
    //  matching at menu level
    for (let index = 0; index < menus.length; index++) {
      const element = menus[index];
      if (
        //  checking alternate path
        element.menu === pathname ||
        (element.menu !== "" && element?.alternatePath?.includes(pathname))
      ) {
        setActive(pathname);
        flag = true;
        break;
      } else {
        //  matching at sub-menus level
        if (
          //  checking alternate path
          element.subMenu.includes(pathname) ||
          element?.alternatePath?.includes(pathname)
        ) {
          element.toggle();
          setActive(pathname);
          flag = true;
          break;
        }
      }
    }

    // found unknown route then redirect to dashboard page
    // if (!flag) {
    //   navigate("/dashboard");
    // }
  };

  useEffect(() => {
    getCurrentTab();
  }, []);

  useEffect(() => {
    setActive(pathname);
  }, [pathname]);

  //  we have to close all sub menus when we click on other menu items
  const closeAllMenus = (key) => {
    const subMenusList = [
      { key: "usersMenu", close: closeUsersMenu },
      { key: "categoryMenu", close: closeCategoryMenu },
      { key: "citystatemenu", close: closetogglelocation },
      { key: "ordersMenu", close: closeOrdersMenu },
      { key: "settingMenu", close: closeSettingMenu },
    ];

    subMenusList.forEach((value) => {
      if (key === value.key) {
        //  if matches we dont have to do anything
      } else {
        //  if not match then close all other open sub menus
        value.close();
      }
    });
  };

  const MenusList = [
    {
      name: "Dashboard",
      icon: Dashboard,
      path: "dashboard",
    },
    {
      name: "Users",
      icon: PeopleIcon,
      isSubMenu: true,
      subMenus: [
        {
          name: "Users List",
          icon: PeopleAltIcon,
          path: "users",
        },
        {
          name: "Nurse List",
          icon: PeopleAltIcon,
          path: "nurse",
        },
        {
          name: "Caregiver List",
          icon: PeopleAltIcon,
          path: "caregiver",
        },
      ],
      key: "usersMenu",
      state: usersMenu,
      toggle: toggleUsersMenu,
    },

    {
      name: "Services",
      icon: DesignServicesIcon,
      isSubMenu: true,
      subMenus: [
        {
          name: "Services",
          icon: MiscellaneousServicesIcon,
          path: "category",
        },
        {
          name: "Categories",
          icon: CategoryIcon,
          path: "services",
        },
        {
          name: "Sub-category",
          icon: Dashboard,
          path: "subcategory",
        },
      ],
      key: "categoryMenu",
      state: categoryMenu,
      toggle: toggleCategoryMenu,
    },
    {
      name: "Equipments",
      img: products,
      path: "equipments",
      alternatePath: [
        "equipments/add",
        "equipments/edit",
        "equipments/details",
      ],
    },
    {
      name: "Orders",
      // icon: GradingIcon,
      img: orders,
      isSubMenu: true,
      subMenus: [
        {
          name: "Equipments",
          icon: ProductionQuantityLimitsIcon,
          path: "equipmentsorder",
          alternatePath: ["equipmentsorder/details"],
        },
        {
          name: "Nurses",
          icon: Person,
          path: "nurses",
          alternatePath: ["nurses/details"],
        },
      ],
      key: "ordersMenu",
      state: ordersMenu,
      toggle: toggleOrdersMenu,
    },

    {
      name: "HSN",
      icon: CodeIcon,
      path: "hsn",
    },

    {
      name: "Locations",
      img: locationIcon,
      isSubMenu: true,
      subMenus: [
        {
          name: "Countries",
          icon: Dashboard,
          path: "countries",
          alternatePath: ["states", "address/cities"],
        },
        // {
        //   name: "Cities",
        //   icon: Dashboard,
        //   path: "cities",
        // },
      ],
      key: "citystatemenu",
      state: citystatemenu,
      toggle: togglelocation,
    },
    {
      name: "Payments",
      img: payment,
      path: "payment",
    },

    {
      name: "Settings",
      icon: SettingsIcon,
      isSubMenu: true,
      subMenus: [
        {
          name: "Privacy Policy",
          icon: PolicyIcon,
          path: "privacypolicy",
          // alternatePath: ["equipmentsorder/details"],
        },
        {
          name: "Terms & Condition",
          icon: GavelIcon,
          path: "termscondition",
          // alternatePath: ["nurses/details"],
        },
        {
          name: "Contact & Support",
          icon: SupportAgentIcon,
          path: "contactsupport",
          // alternatePath: ["nurses/details"],
        },
        {
          name: "Cancellation Reason",
          icon: SupportAgentIcon,
          path: "cancellationreason",
        },
      ],
      key: "settingMenu",
      state: settingMenu,
      toggle: toggleSettingMenu,
    },
  ];

  return (
    <>
      <Box sx={{ textAlign: "center", marginTop: "25px" }}>
        <img src={logo} alt="LOGO" width="18%" />
      </Box>
      <Box className="PE_sidebar" sx={{ height: "80%", overflowY: "auto" }}>
        <List
          sx={{
            ".MuiListItemButton-root": {
              borderRadius: "10px",
              "&:hover": {
                backgroundColor: "#0957DD",
                ".MuiListItemIcon-root img": {
                  filter: " brightness(0) invert(1)",
                },
                ".MuiSvgIcon-root": {
                  color: "white",
                },
                ".MuiListItemText-root": {
                  color: "white",
                },
              },
            },
          }}
        >
          {MenusList.map((value, i) => {
            const flag = value.isSubMenu;
            //  if it is not submenu then it is only menu no sub menus
            if (!flag) {
              return (
                <ListItem
                  key={i}
                  sx={
                    active === value.path ||
                      value?.alternatePath?.includes(active)
                      ? SelectedStyles
                      : {}
                  }
                  onClick={() => {
                    navigate("/" + value.path);
                    setActive(value.path);
                    closeAllMenus();
                  }}
                >
                  <ListItemButton>
                    <ListItemIcon>
                      {value.img ? (
                        <img src={value.img} alt={value.name} />
                      ) : (
                        <value.icon />
                      )}
                    </ListItemIcon>
                    <ListItemText primary={value.name} />
                  </ListItemButton>
                </ListItem>
              );
            }
            //  it means it is a submenus
            if (flag) {
              return (
                <>
                  <ListItem
                    key={i}
                    onClick={() => {
                      value.toggle();
                    }}
                  >
                    <ListItemButton>
                      <ListItemIcon>
                        {value.img ? (
                          <img src={value.img} alt={value.name} />
                        ) : (
                          <value.icon />
                        )}
                      </ListItemIcon>
                      <ListItemText primary={value.name} />
                      {value.state ? <ExpandMore /> : <ExpandLess />}
                    </ListItemButton>
                  </ListItem>
                  {/*  our submenus */}
                  <Collapse in={value.state} timeout="auto" unmountOnExit>
                    <List component="div" sx={{ margin: "0px 5px 0px 20px" }}>
                      {/*  our submenus list */}
                      {value.subMenus.map((subMenuButton, j) => {
                        return (
                          <ListItem

                            sx={
                              active === subMenuButton.path ||
                                subMenuButton?.alternatePath?.includes(active)
                                ? SelectedStyles
                                : {}
                            }
                            onClick={() => {
                              navigate("/" + subMenuButton.path);
                              setActive(subMenuButton.path);
                              //  close all the sub menus except this sub menu that's why passed
                              //  a key to recognize that
                              closeAllMenus(value.key);
                            }}
                          >
                            <ListItemButton>
                              <ListItemIcon>
                                {subMenuButton.img ? (
                                  <img
                                    src={subMenuButton.img}
                                    alt={subMenuButton.name}
                                  />
                                ) : (
                                  <subMenuButton.icon />
                                )}
                              </ListItemIcon>
                              <ListItemText primary={subMenuButton.name} />
                            </ListItemButton>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Collapse>
                </>
              );
            }
          })}
        </List>
      </Box>
    </>
  );
};

export default Sidebar;
