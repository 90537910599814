import axios from "axios";
import handleAuthToken from "../../Utils/handleAuthToken";

export const countryListApi = async (queryString) => {
  if (localStorage.token) {
    handleAuthToken(localStorage.token);
  }
  try {
    const res = await axios.get(`/admin/av1/countryList` + queryString);
    return res.data;
  } catch (error) {
    return { success: false, message: error.response.data.message };
  }
};

export const stateListApi = async (queryString, id) => {
  if (localStorage.token) {
    handleAuthToken(localStorage.token);
  }
  try {
    const res = await axios.get(`/admin/av1/stateList/${id}` + queryString);
    return res.data;
  } catch (error) {
    return { success: false, message: error.response.data.message };
  }
};

export const cityListApi = async (queryString, id) => {
  if (localStorage.token) {
    handleAuthToken(localStorage.token);
  }
  try {
    const res = await axios.get(`/admin/av1/cityList/${id}` + queryString);
    return res.data;
  } catch (error) {
    return { success: false, message: error.response.data.message };
  }
};

export const addStateApi = async (data) => {
  if (localStorage.token) {
    handleAuthToken(localStorage.token);
  }
  try {
    const res = await axios.post(`admin/av1/addstate`, data);
    return res.data;
  } catch (error) {
    return { success: false, message: error.response.data.message };
  }
};
export const addCityApi = async (data) => {
  console.log(data);
  if (localStorage.token) {
    handleAuthToken(localStorage.token);
  }
  try {
    const res = await axios.post(`admin/av1/addcity/`, data);
    return res.data;
  } catch (error) {
    return { success: false, message: error.response.data.message };
  }
};


//  update these 3 apis for countries, state and city
export const updateCountryStatus = async ( body) => {
  if (localStorage.token) {
    handleAuthToken(localStorage.token);
  }
  try {
    //  update this with new api
    const res = await axios.post(`admin/av1/countryStatus`, body);

    return res.data;
  } catch (error) {
    return { success: false, message: error.response.data.message };
  }
};

export const updateStateStatus = async ( body) => {
  if (localStorage.token) {
    handleAuthToken(localStorage.token);
  }
  try {
    const res = await axios.post(`admin/av1/stateStatus`, body);
    return res.data;
  } catch (error) {
    return { success: false, message: error.response.data.message };
  }
};

export const updateCityStatus = async (body) => {
  if (localStorage.token) {
    handleAuthToken(localStorage.token);
  }
  try {
    const res = await axios.post(`admin/av1/cityStatus`, body);
    return res.data;
  } catch (error) {
    return { success: false, message: error.response.data.message };
  }
};
