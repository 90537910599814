import React, { useState } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";

import PaymentList from "./PaymentList";
// import AddPayment from "./AddPayment";

const PaymentPage = () => {
  const [refresh, setRefresh] = useState(false);
  const refreshScreen = () => {
    setRefresh(!refresh);
  };

  return (
    <>
      <Stack flexDirection="row" justifyContent="space-between">
        <Typography sx={{ fontSize: "30px", fontWeight: "bold" }}>
          Payments
        </Typography>
      </Stack>

      <Box sx={{ mt: 4 }}>
        <PaymentList refresh={refresh} refreshScreen={refreshScreen} />
      </Box>
    </>
  );
};
export default PaymentPage;
