import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Box,
  Typography,
  IconButton,
  TextField,
  FormLabel,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Close as CloseIcon } from "@mui/icons-material";

import camera from "../../Assets/camera.svg";
import { useForm } from "../../Utils/useForm";

import Backdrop from "../BackDrop/BackDrop";
import {
  updateCategoryApi,
  categoryAddApi,
} from "../../state/actions/categoryAction";

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
}));
const StyledTextField = styled(TextField)(() => ({
  marginTop: "10px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
  },
  ".css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
    padding: "14px",
  },
}));
const StyledImageUploadWrapper = styled(Box)(() => ({
  marginTop: "16px",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  height: "206px",
  borderRadius: "10px",
  textAlign: "center",
}));
const StyledIconWrapper = styled(Box)(() => ({
  position: "relative",

  margin: "20px auto",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  width: "80px",
  height: "80px",
  borderRadius: "10px",
  backgroundColor: "rgba(15, 15, 15, 0.05)",

  img: {
    marginTop: "30px",
  },
}));

const StyledInput = styled("input")({
  display: "none",
});
const StyledRequired = styled("span")({
  color: "red",
  marginLeft: "2px",
  fontSize: "20px",
});

const StyledRemoveButton = styled(Box)({
  color: "red",
  display: "inline",
  "&:hover": {
    cursor: "pointer",
  },
});

const AddUser = (props) => {
  const dispatch = useDispatch();
  const { open, refreshScreen, handleClose, category } = props;

  const [isdisabled, setIsDisabled] = useState(false);
  const [openBackdrop, setOpenBreakdrop] = useState(false);

  const [image, setImage] = useState("");
  const resetImage = () => {
    setImage("");
  };
  const reset = () => {
    resetImage();
    resetForm();
  };

  const initialValues = {
    name: "",
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("name" in fieldValues)
      temp.name = fieldValues.name ? "" : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  useEffect(() => {
    if (props.category && props.category !== -1) {
      setValues(props.category);
      setImage(props.category.image);
    }
  }, [props]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const toastType = {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    };

    if (validate() && values.image) {
      let res;
      let formData = new FormData();
      formData.append("name", values.name);
      formData.append("image", values.image);
      if (props.category && props.category !== -1) {
        setOpenBreakdrop(true);

        res = await dispatch(updateCategoryApi(values.id, formData));
      } else {
        setOpenBreakdrop(true);

        res = await dispatch(categoryAddApi(formData));
      }

      if (res && res.status === true) {
        setOpenBreakdrop(false);
        setIsDisabled(true);
        close();
        toast.success(res.message, toastType);
        refreshScreen();
      } else {
        setOpenBreakdrop(false);
        toast.error(res.message, toastType);
      }
    } else if (values.name && !values.image) {
      setOpenBreakdrop(false);

      toast.error("Please Select a Image", toastType);
    }
  };


  const close = () => {
    reset();
    handleClose();
    setIsDisabled(false);
  };
  const removeImage = () => {
    resetImage();
    setValues((previousValues) => {
      var newValues = { ...previousValues };
      delete newValues["image"];
      return newValues;
    });
  };
  async function handleImageItem(e) {
    const selected = e.target.files[0];
    const ALLOWED_TYPES = ["image/png", "image/jpg", "image/jpeg"];
    if (selected && ALLOWED_TYPES.includes(selected.type)) {
      const base64String = await getBase64(selected);
      setImage(base64String);
      setValues({ ...values, image: selected });
    } else {
    }
  }
  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  return (
    <>
      <Backdrop open={openBackdrop} />

      <Dialog open={open} fullWidth={true}>
        <DialogTitle
          sx={{
            fontWeight: "bold",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {props.category && props.category !== -1 ? "Edit" : "Add"} Nurse
          <Tooltip title="Close">
            <IconButton onClick={close}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit} id="addNewCategory">
            <Grid
              container
              sm={12}
              xs={12}
              spacing={2}
              style={{
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Grid
                item
                md={12}
                container
                sm={12}
                xs={12}
                style={{ alignItems: "center", justifyContent: "center" }}
              >
                <Grid item md={8} xs={12}>
                  <StyledImageUploadWrapper>
                    <label htmlFor="image-upload">
                      <StyledInput
                        accept="image/*"
                        id="image-upload"
                        type="file"
                        onChange={handleImageItem}
                        onClick={(event) => {
                          event.currentTarget.value = null;
                        }}
                      />
                      <StyledIconWrapper
                        sx={
                          image === ""
                            ? {}
                            : {
                                backgroundImage: `url(${image})`,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "cover",
                              }
                        }
                      >
                        {image === "" && <img src={camera} alt="Camera" />}
                      </StyledIconWrapper>
                    </label>
                    <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
                      Add Photo
                    </Typography>
                    <Typography
                      sx={{
                        color: "rgba(15, 15, 15, 0.5)",
                        mx: 2,
                        fontSize: "12px",
                      }}
                    >
                      Allowed *.jpeg, *.jpg, *.png, max size of 3.1 MB
                    </Typography>
                    {image !== "" && (
                      <StyledRemoveButton
                        onClick={(e) => {
                          e.preventDefault();
                          removeImage();
                        }}
                      >
                        Remove
                      </StyledRemoveButton>
                    )}
                  </StyledImageUploadWrapper>
                </Grid>
              </Grid>
              <Grid
                item
                md={12}
                sm={12}
                xs={12}
                container
                style={{ alignItems: "center", justifyContent: "center" }}
              >
                <Grid item md={8} xs={12}>
                  <StyledFormLabel htmlFor="name">
                    Name
                    {Boolean(errors.name) && (
                      <StyledRequired> * </StyledRequired>
                    )}
                  </StyledFormLabel>
                  <StyledTextField
                    fullWidth
                    id="name"
                    name="name"
                    type="text"
                    value={values.name}
                    onChange={handleInputChange}
                    error={Boolean(errors.name)}
                    helperText={errors.name}
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions sx={{ margin: "15px" }}>
          <Button
            sx={{
              backgroundColor: "white",
              color: "black",
              border: "1px solid #0F0F0F80",
              borderRadius: "10px",
              padding: "10px 25px",
              "&:hover": {},
            }}
            onClick={close}
          >
            Cancel
          </Button>
          <Button
            sx={{
              backgroundColor: "#0957DD",
              color: "white",
              borderRadius: "10px",
              padding: "10px 25px",
              "&:hover": {
                backgroundColor: "#0957DD",
              },
            }}
            disabled={isdisabled}
            type="submit"
            form="addNewCategory"
          >
            {props.category && props.category !== -1 ? "Save" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddUser;
