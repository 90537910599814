import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { FormGroup, FormControlLabel, Checkbox, colors } from "@mui/material";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Box,
  Typography,
  IconButton,
  TextField,
  FormLabel,
  MenuItem,
  Input,
  Tooltip,
  Stack,
  Chip,
  Avatar,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  Close as CloseIcon,
  Person,
  Person as PersonIcon,
} from "@mui/icons-material";

import camera from "../../Assets/camera.svg";
import { useForm } from "../../Utils/useForm";

import {
  categoryListApiDropdown,
  addServicesApi,
  updateServiceApi,
  serviceDetailsApi,
} from "../../state/actions/serviceAction";

const StyledChip = styled(Chip)(() => ({
  borderRadius: "10px",
  fontSize: "14px",
}));

const initialValues = {
  name: "",
  categoryId: "",
  originalPriceShiftDay: 0,
  salePriceShiftDay: 0,
  originalPriceShiftNight: 0,
  salePriceShiftNight: 0,
  originalPrice24hr: 0,
  salePriceShift24hr: 0,
  description: "",
};

export default function ServiceDetails(props) {
  const { currentService, open, refreshScreen, handleClose } = props;

  const [serviceData, setServiceData] = useState({});
  const [dropdownCategory, setDropdownCategory] = useState([]);
  const [openBackdrop, setOpenBreakdrop] = useState(false);

  async function getCat() {
    const cat = await categoryListApiDropdown();
    setDropdownCategory(cat.data);
  }

  async function getServiceDetails() {
    setOpenBreakdrop(true);
    const Subcat = await serviceDetailsApi(currentService.id);
    console.log("test", Subcat);
    if (Subcat.data && Subcat) {
      setOpenBreakdrop(false);
      setServiceData(Subcat.data);
    } else {
      setOpenBreakdrop(false);
    }
  }

  useEffect(() => {
    if (currentService && currentService !== -1) {
      getServiceDetails();
    }
    getCat();
  }, []);

  const isEmpty = (value) => {
    if (
      value === null ||
      value === "null" ||
      value === undefined ||
      value.trim().length === 0
    ) {
      return "-";
    } else {
      return value;
    }
  };
  const getCategoryName = (id) => {
    const index = dropdownCategory.findIndex((list) => list.id === id);
    return index === -1 ? "" : dropdownCategory[index].name;
  };
  const getCategoryImage = (id) => {
    const index = dropdownCategory.findIndex((list) => list.id === id);
    return index === -1 ? "" : dropdownCategory[index].image;
  };
  const serviceImage = getCategoryImage(serviceData.categoryId);

  const getAvailability = (status) => {
    const statusColors = {
      1: {
        backgroundColor: "rgba(18, 183, 106, 0.05)",
        color: "#12B76A",
      },

      0: {
        backgroundColor: "rgba(240, 68, 56, 0.05)",
        color: "#F04438;",
      },
    };
    return (
      <StyledChip
        sx={statusColors[status]}
        label={status ? "Available" : "Not Available"}
      />
    );
  };

  return (
    <>
      <Dialog
        open={open}
        fullWidth={true}
        onClose={handleClose}
        maxWidth={serviceData.categoryId === 1 ? "md" : "lg"}
      >
        <DialogTitle
          sx={{
            fontWeight: "bold",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          Category Details
          <Tooltip title="Close">
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>

        <DialogContent>
          <Grid container my={2} columnGap={1}>
            <Grid item sm={3}>
              <Stack spacing={2}>
                <Grid item>
                  <b>Service</b>
                </Grid>
                <Grid item>
                  <Stack flexDirection="row" alignItems="center" columnGap={2}>
                    <Avatar
                      src={serviceImage ? serviceImage : <PersonIcon />}
                    />
                    <Grid item>
                      {isEmpty(getCategoryName(serviceData.categoryId))}
                    </Grid>
                  </Stack>
                </Grid>
              </Stack>
            </Grid>
            <Grid item sm={3}>
              <Stack spacing={2}>
                <Grid item>
                  <b>Category Name</b>
                </Grid>
                <Grid item>
                  <Stack flexDirection="row" alignItems="center" columnGap={2}>
                    <Avatar
                      src={
                        serviceData.image ? serviceData.image : <PersonIcon />
                      }
                    />
                    <Grid item>{isEmpty(serviceData.name)}</Grid>
                  </Stack>
                </Grid>
              </Stack>
            </Grid>
          </Grid>

          {serviceData.categoryId != 1 && (
            <>
              <Grid container my={2} spacing={2}>
                <Grid item md={4} sm={6}>
                  <Stack spacing={1}>
                    <Typography>
                      <b>Day Shift Original Price : </b>{" "}
                      {isEmpty(serviceData.originalPriceShiftDay)}
                    </Typography>
                    <Typography>
                      <b>Day Shift Sale Price : </b>{" "}
                      {isEmpty(serviceData.salePriceShiftDay)}
                    </Typography>
                    <Typography>
                      <b>Availability : </b>{" "}
                      {getAvailability(serviceData.availableFlagDay)}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item md={4} sm={6}>
                  <Stack spacing={1}>
                    <Typography>
                      <b>Night Slot Original Price : </b>{" "}
                      {isEmpty(serviceData.originalPriceShiftNight)}
                    </Typography>
                    <Typography>
                      <b>Night Slot Sale Price : </b>{" "}
                      {isEmpty(serviceData.salePriceShiftNight)}
                    </Typography>
                    <Typography>
                      <b>Availability : </b>{" "}
                      {getAvailability(serviceData.availableFlagNight)}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item md={4} sm={6}>
                  <Stack spacing={1}>
                    <Typography>
                      <b>24 Hour Slot Original Price : </b>{" "}
                      {isEmpty(serviceData.originalPrice24hr)}
                    </Typography>
                    <Typography>
                      <b>24 Hour Slot Sale Price : </b>{" "}
                      {isEmpty(serviceData.salePriceShift24hr)}
                    </Typography>
                    <Typography>
                      <b>Availability : </b>{" "}
                      {getAvailability(serviceData.availableFlag24hr)}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>

              <Grid container my={3}>
                <Grid item>
                  <b>Description : </b>
                </Grid>
                <Grid item ml={1}>
                  {isEmpty(serviceData.description)}
                </Grid>
              </Grid>
            </>
          )}
        </DialogContent>
        <DialogActions sx={{ margin: "15px" }}>
          <Button
            sx={{
              backgroundColor: "white",
              color: "black",
              border: "1px solid #0F0F0F80",
              borderRadius: "10px",
              padding: "10px 25px",
              "&:hover": {},
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
