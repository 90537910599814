import React from "react";
import { Box } from "@mui/material";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Main from "./Main";

const LayoutPage = (props) => {
  return (
    <Box sx={{ display: "flex" }}>
      <Box sx={{ width: "17%", height: "100vh", overflowY: "visible" }}>
        <Sidebar {...props} />
      </Box>
      <Box
        sx={{
          width: "83%",
          height: "100vh",
          backgroundColor: "#E6E6E6",
          overflowY: "scroll",
        }}
      >
        <Header {...props} />
        <Main {...props} />
      </Box>
    </Box>
  );
};

export default LayoutPage;
