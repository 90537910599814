import handleAuthToken from "../../Utils/handleAuthToken";
import axios from "axios";

export const getHsns = async (queryString) => {
  if (localStorage.token) {
    handleAuthToken(localStorage.token);
  }
  try {
    const res = await axios.get(`admin/av1/hsnList`+queryString);
    return res.data;
  } catch (error) {
    return { success: false, message: error.response.data.message };
  }
};

export const addHSNApi = (values) => async () => {
  if (localStorage.token) {
    handleAuthToken(localStorage.token);
  }
  try {
    const res = await axios.post(`admin/av1/addHSN`, values);
    console.log("working", res)
    return res.data;
  } catch (error) {
    return { success: false, message: error.response.data.message };
  }
};


export const deleteHSN = async (hsnId) => {
  if (localStorage.token) {
    handleAuthToken(localStorage.token);
  }
  // try {
  //   const res = await axios.delete(`/api/v1/hsn/${hsnId}`);
  //   return res.data;
  // } catch (error) {
  //   return { success: false, message: error.response.data.message };
  // }
};

export const updateHSNApi = (data) => async (dispatch) => {
  if (localStorage.token) {
    handleAuthToken(localStorage.token);
  }
  try {
    // console.log("data909090909090", id)
    const res = await axios.post(`/admin/av1/editHsn/${data.id}`, data);
    console.log("working---------------data", res.data)
    return res.data

  } catch (error) {
    return { success: false, message: error };
  }
}
