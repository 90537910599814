import handleAuthToken from '../../Utils/handleAuthToken'
import axios from "axios";

export const categoryListApiDropdown = async ()  => {
    if (localStorage.token) {
        handleAuthToken(localStorage.token);
    }
    console.log('localStorage.token', localStorage.token);
    try {
        const res = await axios.post(`/admin/av1/categoryList`);
        return res.data
    } catch (error) {
        return { success: false, message: error.response.data.message };
    }
}

export const ServicesListApi = async (queryString) => {
    if (localStorage.token) {
        handleAuthToken(localStorage.token);
    }

    try {
        const res = await axios.post(`/admin/av1/serviceList`+queryString);
        return res.data;

    } catch (error) {
        return { success: false, message: error.response.data.message };
    }

}

export const addServicesApi = async (data) => {
    if (localStorage.token) {
        handleAuthToken(localStorage.token);
    }

    try {
        const res = await axios.post(`/admin/av1/addService`, data);
        return res.data;

    } catch (error) {
        return { success: false, message: error.response.data.message };
    }

}

export const updateServiceApi = async (id, data) => {
    if (localStorage.token) {
        handleAuthToken(localStorage.token)
    }
    try {
        const res = await axios.post(`/admin/av1/serviceEdit/${id}`, data);
        return res.data;
    } catch (error) {
        return { success: false, message: error.response.data.message };
    }
}

export const serviceDetailsApi = async (id) => {
    if (localStorage.token) {
        handleAuthToken(localStorage.token)
    }
    try {
        const res = await axios.post(`/admin/av1/serviceDetail/${id}`);
        return res.data;
    } catch (error) {
        return { success: false, message: error.response.data.message };
    }
}

export const deleteService = async (id) => {
    if (localStorage.token) {
      handleAuthToken(localStorage.token);
    }
    try {
      const res = await axios.get(`admin/av1/deleteService/${id}`);
      return res.data;
    } catch (error) {
      return { success: false, message: error.response.data.message };
    }
  };
  