import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";

import {
  Button,
  Stack,
  Grid,
  Box,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Avatar,
  Typography,
} from "@mui/material";

import Rating from "@mui/material/Rating";
import { styled } from "@mui/material/styles";
import Carousel from "react-material-ui-carousel";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Divider from "@mui/material/Divider";

import "./style/EquipmentsDetails.css";
import rect from "../../Assets/user1.png";

// import DeleteProductModel from './DeleteProduct'
import "@fontsource/work-sans";
import "@fontsource/rubik";
import { useEffect } from "react";
import moment from "moment";
import { equipmentDetailsApi } from "../../state/actions/equipmentsAction";
import Backdrop from "../BackDrop/BackDrop";

const StyledParagraph = styled("p")({
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "34px",
  marginTop: "0px",
  fontFamily: "Work Sans",
});
const StyledHeading = styled("h1")({
  fontFamily: "Work Sans",
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "24px",
  lineHeight: "32px",
});
const StyledDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
  background: "#FFFFFF",
  boxShadow:
    "  0px 20px 94px rgba(16, 24, 40, 0.01), 0px 8px 30px rgba(16, 24, 40, 0.01)",
  borderRadius: "12px !important",
  padding: "22px",
  width: "100%",
  height: "100%",
});
const StyledImg = styled("img")({
  borderRadius: "12px !important",
  height: "400px",
  width: "100%",
});

export default function ProductDetails() {
  const { state, pathname } = useLocation();
  const navigate = useNavigate();

  const [deleteProduct, setDeleteProduct] = useState(false);
  const [equipmentData, setEquipmentData] = useState({});
  const [files, setFiles] = useState([]);
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [specificationarr, setSpecificationArr] = useState([]);
  const [review, setReviews] = useState([]);

  const id = state;
  const getEquipmentDetails = async (id) => {
    setOpenBreakdrop(true);
    const res = await equipmentDetailsApi(id);
    if (res && res.data) {
      setEquipmentData(res.data.productData);
      let prodImages = [];
      res.data.productImages.filter((data) => prodImages.push(data.image));
      setFiles(prodImages);
      setSpecificationArr(res.data.productSpecification);
      setReviews(res.data.reviews);
      setOpenBreakdrop(false);
    }
  };
  useEffect(() => {
    getEquipmentDetails(id);
  }, [id]);

  return (
    <>
      <Backdrop open={openBackdrop} />

      <Box sx={{ flexGrow: 1 }}>
        <Grid container>
          <Grid container item xs={12} className="pd_heading_grid">
            <Grid>
              <StyledHeading>Equipment Details</StyledHeading>
              <StyledParagraph>
                Equipment |{" "}
                <span style={{ color: "rgb(166 166 166)" }}>
                  {" "}
                  Equipment Details
                </span>
              </StyledParagraph>
            </Grid>
            <Grid>
              {/* <Button
                            type="button"
                            variant="contained"
                            // onClick={() => {
                            //     handleDeleteProduct();
                            // }}
                            sx={{
                                mb: 2, px: 6,
                                backgroundColor: "white",
                                color: "black",
                                border: "1px solid #0F0F0F",
                                borderRadius: "10px",
                                padding: "10px 25px",
                                "&:hover": {
                                    backgroundColor: "#ffffff",
                                },
                            }}
                        >
                            Delete
                        </Button> */}

              <Button
                variant="contained"
                sx={{
                  mb: 2,
                  px: 6,
                  ml: 2,
                  backgroundColor: "#0957dd",
                  color: "white",
                  height: "48px",
                  borderRadius: "10px",
                  padding: "10px 25px",
                  "&:hover": {
                    backgroundColor: "#0957dd",
                  },
                }}
                onClick={() => {
                  navigate("/equipments/edit", {
                    state: id,
                  });
                }}
              >
                Edit
              </Button>
            </Grid>
          </Grid>
          <StyledDiv>
            <Grid className="pd_slider_and_details_grid" container xs={12}>
              <Grid
                item
                xs={12}
                lg={6}
                spacing={2}
                style={{ paddingRight: "22px" }}
              >
                <Grid
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Carousel
                    className="pd_img_slider"
                    navButtonsProps={{
                      style: {
                        background: "rgba(15, 15, 15, 0.5)",
                        boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                        transform: " matrix(-1, 0, 0, 1, 0, 0)",
                        borderRadius: "10px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "8px",
                        gap: "8px",
                        width: "36px",
                        height: "36px",
                      },
                    }}
                    indicatorIconButtonProps={{
                      style: {
                        display: "none",
                      },
                    }}
                    NextIcon={<ChevronLeftIcon />}
                    PrevIcon={<ChevronRightIcon />}
                    // animation='slide'
                    // duration={700}
                    autoPlay={false}
                    navButtonsAlwaysVisible={true}
                  >
                    {files.map((imgsrc, i) => (
                      <StyledImg key={i} src={imgsrc} alt={imgsrc} />
                    ))}
                  </Carousel>
                </Grid>

                <Grid
                  container
                  item
                  sx={{ my: 1.5 }}
                  className="pd_parentspecialborderGrid_img"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  {files.map((item, i) =>
                    i < 3 ? (
                      <Grid item>
                        {" "}
                        <img
                          className="pd_product_img"
                          key={i}
                          src={item}
                          alt={item}
                        />{" "}
                      </Grid>
                    ) : (
                      ""
                    )
                  )}
                </Grid>
              </Grid>
              {/* details and description */}
              <Grid item xs={12} lg={6}>
                <Grid className="pd_details_desc_grid">
                  <Grid>
                    <h1 className="pd_h1">
                      Equipment name :- {equipmentData.name}
                    </h1>
                    <p className="pd_category_text">
                      Category name:- {equipmentData?.serviceName}
                    </p>
                    <p className="pd_prod_prop_value_p">
                      <span className="pd_prod_prop"> Equipment Id </span>
                      <span className="pd_prop_value">
                        {" "}
                        : {equipmentData.id} |{" "}
                      </span>

                      <span className="pd_prod_prop"> Created Date </span>
                      <span className="pd_prop_value">
                        {" "}
                        :{moment(equipmentData.createdAt).format("LLL")} |{" "}
                      </span>

                      {/* <span className="pd_prod_prop" > Size </span>
                                        <span className="pd_prop_value">   : XL  | </span>
                                        <span className="pd_prod_prop" >    Color </span>
                                        <span className="pd_prop_value">  : </span> */}
                    </p>
                  </Grid>
                  <Grid container className="pd_parentspecialborderGrid">
                    <Grid item xs={3.7} className="pd_speicalborderGrid">
                      <p className="pd_p">Base Price</p>
                      <h1 className="pd_h1">
                        ₹{" "}
                        {Number(
                          equipmentData.actualPrice -
                            Number(
                              equipmentData.actualPrice -
                                equipmentData.actualPrice /
                                  (1 +
                                    equipmentData?.hsnData?.taxRatePercentage /
                                      100)
                            ).toFixed(0)
                        ).toFixed(0)}
                      </h1>
                    </Grid>
                    {/* <Grid item xs={3.7} className="pd_speicalborderGrid">
                      <p className="pd_p">MRP</p>
                      <h1 className="pd_h1">₹ {equipmentData.mrp}</h1>
                    </Grid> */}

                    <Grid item xs={3.7} className="pd_speicalborderGrid">
                      <p className="pd_p">Tax Amount</p>
                      <h1 className="pd_h1">
                        ₹{" "}
                        {Number(
                          equipmentData.actualPrice -
                            equipmentData.actualPrice /
                              (1 +
                                equipmentData?.hsnData?.taxRatePercentage / 100)
                        ).toFixed(2)}
                      </h1>
                    </Grid>
                    <Grid item xs={3.7} className="pd_speicalborderGrid">

                      <p className="pd_p">Final Price</p>
                      <h1 className="pd_h1">
                        ₹{" "}
                        {Number(
                          equipmentData.actualPrice +
                            Number(
                              equipmentData.actualPrice -
                                equipmentData.actualPrice /
                                  (1 +
                                    equipmentData?.hsnData?.taxRatePercentage /
                                      100)
                            ).toFixed(0)
                        ).toFixed(0)}
                      </h1>

                    </Grid>
                    <Grid item xs={3.7} className="pd_speicalborderGrid">
                      <p className="pd_p">Stock</p>
                      <h1 className="pd_h1">{equipmentData.stock}</h1>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid sx={{ mt: 0.5 }}>
                  <Table
                    stickyHeader
                    aria-label="simple table"
                    style={{
                      display: `${specificationarr.length ? "table" : "none"}`,
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          style={{
                            border: "1px solid #f0f0f0",
                            borderCollapse: "collapse",
                          }}
                        >
                          Specification Title
                        </TableCell>
                        <TableCell
                          style={{
                            border: "1px solid #f0f0f0",
                            borderCollapse: "collapse",
                          }}
                        >
                          Specification Value
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {specificationarr.map((val) => {
                        return (
                          <TableRow>
                            <TableCell
                              style={{
                                border: "1px solid #f0f0f0",
                                borderCollapse: "collapse",
                              }}
                            >
                              {val.specificKey || "test"}
                            </TableCell>
                            <TableCell
                              style={{
                                border: "1px solid #f0f0f0",
                                borderCollapse: "collapse",
                              }}
                            >
                              {val.specificValue || "test"}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12}>
              <Grid sx={{ py: 1 }}>
                <p className="pd_review_text_heading">Description</p>
                <p className="pd_p">{equipmentData.description}</p>
              </Grid>
              <Divider />

              <Grid sx={{ pt: 1 }}>
                <p className="pd_review_text_heading"> Reviews</p>
              </Grid>
              {/* review comp */}
              <Grid className="pd_review_grid">
                <Grid container style={{ margin: "0" }}>
                  {/* <Grid className="pd_reviewimage">
                    <img className="pb_reviewUser_img" src={rect} alt="" />
                  </Grid> */}
                  <Grid style={{ marginLeft: "10px" }}>
                    {/* <p className="pd_review_title">Courtney Henry</p>
                    <Stack spacing={0}>
                      <Rating
                        name="half-rating-read"
                        defaultValue={equipmentData.rating}
                        precision={0.5}
                        readOnly
                      />
                    </Stack> */}
                  </Grid>
                </Grid>
                <Grid>
                  {review.length === 0 ? (
                    <p className="pd_p">No Reviews</p>
                  ) : (
                    review.map((list, index) => {
                      const {
                        fname,
                        profile_image,
                        rating,
                        review: description,
                      } = list;

                      return (
                        <Stack
                          key={index}
                          flexDirection="row"
                          columnGap={2}
                          alignItems="center"
                        >
                          <Avatar src={profile_image} />{" "}
                          <Stack>
                            <Stack flexDirection="row" columnGap={1}>
                              <Typography>{fname}</Typography>
                              <Rating
                                name="read-only"
                                value={rating}
                                readOnly
                              />
                            </Stack>
                            <Typography>{description}</Typography>
                          </Stack>
                        </Stack>
                      );
                    })
                  )}
                </Grid>
              </Grid>
              <Divider />
            </Grid>
          </StyledDiv>
        </Grid>
      </Box>
    </>
  );
}
