import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  CHANGE_PASSWORD,
} from "../action-types/authActionTypes";
import handleAuthToken from "../../Utils/handleAuthToken";
import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();



///////////////////////////////////////////////////////
export const loadUserApi = () => async (dispatch) => {
  if (localStorage.token) {
    handleAuthToken(localStorage.token);
  }
  try {
    const res = await axios.post("/admin/av1/adminDetail");
    if (res.data && res.data.status === true) {
      dispatch({
        type: USER_LOADED,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: AUTH_ERROR,
      });
    }
    return res.data;
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
    return { success: false, message: err.response.data.message };
  }
};

////////////////////////////////

//Login user

export const loginUserApi = ({ email, password }, rememberMe) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify({ email, password });
  try {
    const res = await axios.post("/admin/av1/adminlogin", body, config);

    if (res.data && res.data.status === true) {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data.data,
      });
      if (rememberMe === true) {
        cookies.set("myEmail", email, { path: process.env.REACT_APP_URL });
        cookies.set("myPassword", password, {
          path: process.env.REACT_APP_URL,

        });
      }
    } else {
      dispatch({
        type: LOGIN_FAIL,
      });
    }
    return res.data;
  } catch (err) {
    dispatch({
      type: LOGIN_FAIL,
    });
    return { ...err.response.data };
  }
};



export const logoutApi = () => (dispatch) => {
  dispatch({ type: LOGOUT });
};

export const forgotPassword = (data) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify(data);
  try {
    const res = await axios.post("/admin/av1/adminChangePassword", body, config);
    return res.data;
  } catch (err) {
    return { success: false, message: err.response.data.message };
  }
};

