import React from 'react';
import { useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';

import {
    Grid,  Typography, styled, Table,
    TableBody,
    TableCell,
    TableRow,
} from '@mui/material';
import Card from '@mui/material/Card';

import Button from '@mui/material/Button';
import Camera from '../../Assets/camera.svg'
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import { useState, useEffect } from "react";
import { TextField, } from "@mui/material";
import Badge from '@mui/material/Badge';
import { store } from '../../state/store'

import ResetPasswordDialog from './ResetPassword'
import Backdrop from '../BackDrop/BackDrop';

import { useForm,Form } from '../../Utils/useForm';
import { AdminDetailsUpdateApi } from '../../state/actions/adminAction';

const StyledTextField = styled(TextField)(() => ({
    margin: '8px 0 8px 0',
}))
const StyledImageUploadWrapper = styled(Box)(() => ({
    marginTop: "16px",
    border: "1px solid rgba(15, 15, 15, 0.15)",
    height: "206px",
    borderRadius: "10px",
    textAlign: "center",
}));
const StyledIconWrapper = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: "relative",
    border: "1px solid rgba(15, 15, 15, 0.15)",
    width: "150px",
    height: "150px",
    borderRadius: "50%",
    backgroundColor: "rgba(15, 15, 15, 0.05)",

    img: {
        marginTop: "30px",
    },
}));

const StyledInput = styled("input")({
    display: "none",
});

const StyledRemoveButton = styled(Box)({
    marginTop: "10px",
    color: "red",
    display: "inline",
    "&:hover": {
        cursor: "pointer",
    },
});

const adminimage = {
    width: "120px",
    height: "120px",
    borderRadius: "200px"
}



const strong = {
    color: "white",
    fontSize: "16px",
    fontWeight: "400px"
}

const initialValues = {
    name: '',
    profile: '',
    email: '',
    mobile: '',
};


export default function Profile() {
    const fileInputRef = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [openBackdrop, setOpenBreakdrop] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [ResetPasswordOpen, setResetPasswordOpen] = useState(null);
    const [image, setImage] = useState("");
    const [disableInput, setDiableInput] = useState(true);

    const resetImage = () => {
        setImage("");
    };
    const reset = () => {
        resetImage();
        resetForm();
    };
    const ResetPasswordClose = () => {
        setResetPasswordOpen(false)
    }

    useEffect(() => {
        setOpenBreakdrop(true);
    }, [])

    const openFileInput = () => {
        setDiableInput(false);
        fileInputRef.current.click();
    }
    const close = () => {
        reset();
    };
    const removeImage = () => {
        resetImage();
        setValues((previousValues) => {
            var newValues = { ...previousValues };
            delete newValues["profile"];
            return newValues;
        });
    };
    async function handleImageItem(e) {
        const selected = e.target.files[0];
        const ALLOWED_TYPES = ["image/png", "image/jpg", "image/jpeg"];
        if (selected && ALLOWED_TYPES.includes(selected.type)) {
            const base64String = await getBase64(selected);
            setImage(base64String);
            setValues({ ...values, profile: selected });
        } else {
        }
    }
    const getBase64 = (file) => {
        return new Promise((resolve) => {
            let baseURL = "";
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                baseURL = reader.result;
                resolve(baseURL);
            };
        });
    };

    const { auth } = store.getState();

    // we are setting interval because we have to check if data is stored on store or not so we can access it
    useEffect(() => {


        let timeout = setInterval(() => {
            let { auth } = store.getState();
            if (auth.user) {
                // if data is on store then we have to update the state of user with userdata and stop the timeout
                setValues(auth.user);
                setOpenBreakdrop(false)
                stoptimeout()
            }
        }, 500);
        function stoptimeout() {
            clearInterval(timeout);
        }
    }, [])


    const validate = (fieldValues = values) => {
        let temp = { ...errors };
        if ("mobile" in fieldValues) {
            if (fieldValues.mobile !== "") {
                if (fieldValues.mobile.length > 15) {
                    temp.mobile = "Please enter the number less than 15";

                } else {
                    if (/^[0-9\s]*$/.test(fieldValues.mobile)) {
                        temp.mobile = "";
                    } else {
                        temp.mobile = "Only Number allow";
                    }
                }
            } else {
                temp.mobile = "This field is required.";
            }
        }

        if ("name" in fieldValues)
            temp.name = fieldValues.name ? "" : "This field is required.";


        setErrors({
            ...temp,
        });

        if (fieldValues === values)
            return Object.values(temp).every((x) => x === "");
    };

    const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
        useForm(initialValues, true, validate);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const toastType = {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
        };
        if (!isEdit&& (!errors.mobile && !errors.name)) {
            if (validate() && values.profile) {
                setOpenBreakdrop(true);
                const res = await dispatch(AdminDetailsUpdateApi(values))
                if (res && res.status === true) {
                    setOpenBreakdrop(false);
                    toast.success(res.message, toastType);
                    navigate('/dashboard')
                    window.location.reload();
                } else {
                    setOpenBreakdrop(false);
                    toast.error(res.message, toastType);
                }
            } else if (!values.profile) {
                
                toast.error("Please Select a Image", toastType);
                setIsEdit(true);
            }

        }
    }




    const [showPassword, setShowPassword] = useState(false);


    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (


        <>
            <ToastContainer limit={1} />

            <Backdrop open={openBackdrop} />
            <ResetPasswordDialog
                open={ResetPasswordOpen}
                handleClose={ResetPasswordClose} />
            <Typography sx={{ fontSize: "28px", fontWeight: "bold", fontWeight: '600' }}>
                Profile
            </Typography>

            <Card style={{
                height: "450px",
                marginTop: "20px",
                padding: '15px'
            }}>
                <Form onSubmit={handleSubmit}>
                    <Grid item md={12} sm={12} xs={12} container spacing={1}
                        style={{
                            marginTop: "10px",
                            marginRight: "15px",
                            marginLeft: "15px"
                        }}
                    >
                        <Grid item md={3.5} xs={12} style={{
                            border: "1px solid rgba(15, 15, 15, 0.15)",
                            textAlign: "center",
                            padding: "40px",
                            borderRadius: "10px"
                        }}>

                            <Box style={{
                                display: "flex",
                                flexDirection: 'column',
                                justifyContent: "center",

                            }}>

                                <Box>
                                    <Badge badgeContent={isEdit ? <EditIcon onClick={openFileInput} sx={{ color: '#ffffff', background: '#000000', borderRadius: '50%', margin: '55px 35px 0px 0px', fontSize: '25px', padding: '5px', cursor: 'pointer' }} /> : ''} >
                                        <label htmlFor="image-upload">
                                            <StyledInput
                                                ref={fileInputRef}
                                                accept="image/*"
                                                id="image-upload"
                                                // type="file"
                                                onChange={handleImageItem}
                                                onClick={(event) => {
                                                    event.currentTarget.value = null;
                                                }}
                                                type={isEdit ? "file" : ''}
                                            />
                                            <StyledIconWrapper
                                                sx={
                                                    image !== ""
                                                        ? {
                                                            backgroundImage: `url(${image})`,
                                                            backgroundRepeat: "no-repeat",
                                                            backgroundSize: "cover",
                                                        }
                                                        : values.profile !== ""
                                                            ? {
                                                                backgroundImage: `url(${values.profile})`,
                                                                backgroundRepeat: "no-repeat",
                                                                backgroundSize: "cover",
                                                            }
                                                            : {}
                                                }
                                            >
                                                {image === "" &&
                                                    ((values.profile &&
                                                        values.profile === "") ||
                                                        !values["profile"]) && (
                                                        <img src={Camera} alt="Camera" style={{ margin: '0' }} />
                                                    )}
                                            </StyledIconWrapper>
                                        </label>

                                    </Badge>
                                </Box>
                                {isEdit ?
                                    <StyledRemoveButton
                                        onClick={(e) => {
                                            e.preventDefault();
                                            removeImage();
                                        }}
                                    >
                                        Remove
                                    </StyledRemoveButton> : ""}


                            </Box>

                        </Grid>

                        <Grid item md={8} xs={12} style={{
                            border: "1px solid rgba(15, 15, 15, 0.15)",
                            textAlign: "center",
                            padding: "20px",
                            borderRadius: "10px"
                        }} >
                            <Box style={{ textAlign: 'right' }}>
                                <Button variant="contained" size="large"
                                    type='submit'
                                    style={{
                                        backgroundColor: '#0957DD',
                                        borderRadius: '10px',
                                        marginBottom: "10px",
                                        fontWeight: "400px",

                                    }}
                                    onClick={() => {
                                        setIsEdit(!isEdit)
                                    }}
                                >
                                    <span style={strong}>{isEdit ? 'Save' : "Edit"}</span>
                                </Button>
                            </Box>
                            <Table>
                                <TableBody>

                                    <TableRow key={"name"}>
                                        <TableCell sx={{ pl: 2 }}>Name</TableCell>
                                        {isEdit ?
                                            <TableCell>
                                                <StyledTextField
                                                    name="name"
                                                    label="Name"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    onChange={handleInputChange}
                                                    value={values.name}
                                                    inputProps={{ maxLength: 100 }}
                                                    error={Boolean(errors.name)}
                                                    helperText={errors.name}
                                                />
                                            </TableCell> : <TableCell>{values.name || ''}</TableCell>}
                                    </TableRow>
                                    <TableRow key={"email"}>
                                        <TableCell sx={{ pl: 2 }}>Email</TableCell>

                                        <TableCell>{values.email}</TableCell>
                                    </TableRow>
                                    <TableRow key={'phone'}>
                                        <TableCell sx={{ pl: 2 }}>Phone</TableCell>
                                        {isEdit ?
                                            <TableCell>
                                                <StyledTextField
                                                    name="mobile"
                                                    label="Phone"
                                                    variant="outlined"
                                                    size="small"
                                                    type='number'
                                                    fullWidth
                                                    onChange={handleInputChange}
                                                    value={values.mobile}
                                                    error={Boolean(errors.mobile)}
                                                    helperText={errors.mobile}
                                                    onKeyDown={(e) => {
                                                        if (e.key === "+" || e.key === "-" || e.key === "e" || e.key === "E" ) {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                />
                                            </TableCell> : <TableCell>{values.mobile}</TableCell>}
                                    </TableRow>


                                    <TableRow>
                                        <TableCell>
                                            PassWord
                                        </TableCell>
                                        <TableCell>
                                            ***********
                                            {!isEdit ?
                                                <Button variant="contained" sx={{
                                                    color: 'white',
                                                    backgroundColor: '#0957DD',
                                                    cursor: 'pointer',
                                                    ml: 2,
                                                    minWidth: '56px!important'
                                                }}
                                                    onClick={() => {
                                                        setResetPasswordOpen(true)

                                                    }}
                                                >
                                                    <EditIcon />
                                                </Button>
                                                : ''}
                                        </TableCell>

                                    </TableRow>

                                </TableBody>
                            </Table>

                        </Grid>


                    </Grid>
                </Form>
            </Card>

        </>


    )
};
