import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";

import { styled } from "@mui/material/styles";
import {
  Search as SearchIcon,
  MoreVert as MoreVertIcon,
  Close as CloseIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import {
  InputBase,
  Button,
  Typography,
  IconButton,
  Stack,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Drawer,
  Divider,
  Box,
  TextField,
  FormLabel,
  InputAdornment,
} from "@mui/material";
import moment from "moment";

import filterIcon from "../../Assets/filter.svg";

import CategoryDetailsModal from "./CategoryDetails";
import AddCategory from "./AddCategory";
import {
  categoryListApi,
  deleteCategory,
} from "../../state/actions/categoryAction";
import { useForm } from "../../Utils/useForm";
import { getBase64FromUrl } from "../../Utils/getBase64FromUrl";
import Backdrop from "../BackDrop/BackDrop";
import DeleteComponent from "../DeleteComponent/DeleteComponent";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  color: "black",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  borderRadius: "10px",
  backgroundColor: "white",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    paddingRight: "15px",
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: "#0F0F0F80",
}));

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
}));
const StyledTextField = styled(TextField)(() => ({
  marginTop: "10px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
  },
}));
const StyledChip = styled(Chip)(() => ({
  borderRadius: "10px",
  fontSize: "14px",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#0957dd0a",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function CategoryList(props) {
  const { refresh, refreshScreen } = props;
  const dispatch = useDispatch();
  const [categories, setCategories] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentCategory, setCurrentCategory] = useState(-1);
  const [categoryView, setCategoryView] = useState(false);
  const [addCategory, setAddCategory] = useState(false);
  const [openBackdrop, setOpenBreakdrop] = useState(false);

  const [deleteValue, setDeleteValue] = useState(false);

  const handledelete = () => {
    setDeleteValue(true);
  };
  const closedelete = () => {
    setDeleteValue(false);
  };
  // api call for get a category-list
  useEffect(() => {
    setOpenBreakdrop(true);
    async function getCat() {
      const res = await dispatch(categoryListApi());
      if (res && res.status && res.data) {
        setCategories(res.data);
      } else {
      }
      setOpenBreakdrop(false);
    }
    getCat();
  }, [refresh]);

  useEffect(() => {
    handleClose();
  }, [categories]);

  const open = Boolean(anchorEl);
  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setCurrentCategory(index);
  };
  const handleClose = () => {
    setAnchorEl(null);
    // setCurrentCategory(-1);
  };

  const handlecategoryView = () => {
    setCategoryView(true);
  };
  const closecategoryView = () => {
    setCategoryView(false);
  };

  const openCategoryModal = () => {
    setAddCategory(true);
  };
  const closeCategoryModal = () => {
    setAddCategory(false);
  };
  const getStatus = (status) => {
    const statusColors = {
      1: {
        backgroundColor: "rgba(18, 183, 106, 0.05)",
        color: "#12B76A",
      },

      0: {
        backgroundColor: "rgba(240, 68, 56, 0.05)",
        color: "#F04438;",
      },
    };
    if (statusColors[status] === undefined) return "-";

    return (
      <StyledChip
        sx={statusColors[status]}
        label={status ? "Active" : "InActive"}
      />
    );
  };

  const handletableClick = (index) => {
    setCurrentCategory(index);
    setCategoryView(true);
  };

  const onSubmit = async () => {
    const toastType = {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    };

    console.log("deleted subcategory id", currentCategory);
    const id = currentCategory.id;
    setOpenBreakdrop(true);
    const res = await deleteCategory(id);
    if (res && res.status) {
      toast.success(res.message, toastType);
    } else {
      toast.error(res.message, toastType);
    }
    setOpenBreakdrop(false);
    closedelete();
    refreshScreen();
  };
  return (
    <>
      <Backdrop open={openBackdrop} />

      <ToastContainer limit={1} />

      <div
        style={{
          backgroundColor: "white",
          borderRadius: "10px",
          color: "black",
        }}
      >
        {/* table */}
        <TableContainer
          component={Paper}
          sx={{ height: "fit-content", overflowY: "scroll" }}
        >
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell className="table-header-font">
                  Name
                </StyledTableCell>
                <StyledTableCell className="table-header-font">
                  Status
                </StyledTableCell>
                <StyledTableCell className="table-header-font">
                  Date
                </StyledTableCell>
                <StyledTableCell className="table-header-font">
                  Action
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {categories.map((row, index) => {
                return (
                  <StyledTableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    onClick={() => handletableClick(row)}
                    style={{ cursor: "pointer" }}
                  >
                    <TableCell component="th" scope="row">
                      <Stack flexDirection="row" alignItems="center">
                        <img
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "50%",
                          }}
                          sx={{ mx: 0.5 }}
                          alt="Admin Profile"
                          src={row.image}
                        />
                        <Typography sx={{ ml: 2 }}>
                          {row.name.length > 15
                            ? row.name.substr(0, 15) + "..."
                            : row.name}
                        </Typography>
                      </Stack>
                    </TableCell>
                    <TableCell>{getStatus(row.status)}</TableCell>

                    <TableCell>{moment(row.createdAt).format("LLL")}</TableCell>
                    <TableCell onClick={(e) => e.stopPropagation()}>
                      <IconButton
                        onClick={(e) => {
                          const currentCategory = categories.find(
                            (role) => role.id === row.id
                          );
                          handleClick(e, currentCategory);
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </TableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Menu
          sx={{ mt: 0.3 }}
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          onClick={() => {
            handleClose();
          }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuItem
            sx={{ mt: 1 }}
            onClick={() => {
              handlecategoryView();
            }}
          >
            <ListItemIcon>
              <VisibilityIcon />
            </ListItemIcon>
            <ListItemText>View Details</ListItemText>
          </MenuItem>
          <MenuItem
            sx={{ mt: 1 }}
            onClick={() => {
              openCategoryModal();
            }}
          >
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            <ListItemText>Edit</ListItemText>
          </MenuItem>

          <MenuItem sx={{ mt: 1 }} onClick={handledelete}>
            {" "}
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            <ListItemText>Delete</ListItemText>
          </MenuItem>
        </Menu>
        {categoryView && (
          <CategoryDetailsModal
            category={currentCategory}
            open={categoryView}
            setOpen={setCategoryView}
            handleClose={closecategoryView}
          />
        )}
        {addCategory && (
          <AddCategory
            category={currentCategory}
            open={addCategory}
            setOpen={setAddCategory}
            handleClickOpen={openCategoryModal}
            handleClose={closeCategoryModal}
            refreshScreen={refreshScreen}
          />
        )}
        {deleteValue && (
          <DeleteComponent
            open={deleteValue}
            handleClose={closedelete}
            onSubmit={onSubmit}
          />
        )}
      </div>
    </>
  );
}
