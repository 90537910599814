import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { styled } from "@mui/material/styles";
import {
  Search as SearchIcon,
  MoreVert as MoreVertIcon,
  Delete as DeleteIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import {
  InputBase,
  Button,
  Typography,
  IconButton,
  Stack,
  Pagination,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Drawer,
  Divider,
  Box,
  TextField,
  FormLabel,
  InputAdornment,
} from "@mui/material";
import moment from "moment";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import eye from "../../Assets/eye.svg";
import edit from "../../Assets/edit.svg";
import deleteIcon from "../../Assets/delete.svg";
import filterIcon from "../../Assets/filter.svg";

import { useForm } from "../../Utils/useForm";
import {
  equipmentsListApi,
  getAllServices,
  deleteEquipmentApi,
} from "../../state/actions/equipmentsAction";
import Backdrop from "../BackDrop/BackDrop";
import DeleteComponent from "../DeleteComponent/DeleteComponent";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  color: "black",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  borderRadius: "10px",
  backgroundColor: "white",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    paddingRight: "15px",
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: "#0F0F0F80",
}));

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
}));
const StyledTextField = styled(TextField)(() => ({
  marginTop: "10px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
  },
}));
const StyledChip = styled(Chip)(() => ({
  borderRadius: "10px",
  fontSize: "14px",
}));

const StyledRequired = styled("span")({
  color: "red",
  marginLeft: "2px",
  fontSize: "20px",
});

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#0957dd0a",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function EquipmentsList(props) {
  const navigate = useNavigate();

  const { refresh, refreshScreen } = props;
  const [page, setPage] = useState(1);
  const [pagePerSize, setPagePerSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const [currentEquipment, setCurrentEquipment] = useState({});
  const [filter, setFilter] = useState(false);
  const [equipments, setEquipments] = useState([]);
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [deleteValue, setDeleteValue] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const handledelete = () => {
    setDeleteValue(true);
  };
  const closedelete = () => {
    setDeleteValue(false);
  };

  const getEquipmentList = async (page) => {
    setOpenBreakdrop(true);
    let queryString =
      "?page=" + page + "&size=" + pagePerSize + "&search=" + searchValue;
    Object.keys(values).map((key) => {
      queryString += "&" + key + "=" + values[key];
    });

    const res = await equipmentsListApi(queryString);
    if (res && res.status) {
      setEquipments(res.data);
      setTotalRecords(res.totalRecord);
      setTotalPages(Math.ceil(res.totalRecord / pagePerSize));
    } else {
      setEquipments([]);
      setTotalRecords(0);
      setTotalPages(0);
    }
    setOpenBreakdrop(false);
  };

  //  whenever admin list change fetch the list again
  useEffect(() => {
    getEquipmentList(page);
  }, [refresh]);

  const handleChangePage = (event, value) => {
    setPage(value);
    getEquipmentList(value);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event, current) => {
    setAnchorEl(event.currentTarget);
    setCurrentEquipment(current);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    handleClose();
  }, [equipments]);

  const onSearch = async (search) => {
    if (search.target.value === "") {
      resetForm();
    }
    setSearchValue(search.target.value);
    let queryString =
      "?page=" + 1 + "&size=" + pagePerSize + "&search=" + search.target.value;
    setOpenBreakdrop(true);
    const res = await equipmentsListApi(queryString);
    if (res && res.status) {
      setEquipments(res.data);
      setTotalRecords(res.totalRecord);
      setTotalPages(Math.ceil(res.totalRecord / pagePerSize));
      // setPage(1)
    } else {
      setEquipments([]);
      setTotalRecords(0);
      setTotalPages(0);
    }
    setOpenBreakdrop(false);
  };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 700);
    };
  };

  const debounceserach = debounce(onSearch);

  const initialValues = {
    name: "",
    serviceId: "",
    minPrice: "",
    maxPrice: "",
    status: "",
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);

  const openFilterDrawer = () => {
    setFilter(true);
  };

  const closeFilterDrawer = () => {
    setFilter(false);
  };
  const onFilterSubmit = async (e) => {
    if (typeof e != "undefined") {
      e.preventDefault();
    }
    //  we are using initialValues because our values object is not resetting.
    var formValues = typeof e === "undefined" ? initialValues : values;
    let queryString = "?search=" + "&page=" + 1 + "&size=" + pagePerSize;
    Object.keys(formValues).map((key) => {
      queryString += "&" + key + "=" + formValues[key];
    });
    setOpenBreakdrop(true);
    const res = await equipmentsListApi(queryString);
    if (res && res.status) {
      setEquipments(res.data);
      setTotalRecords(res.totalRecord);
      setTotalPages(Math.ceil(res.totalRecord / pagePerSize));
    } else {
      setEquipments([]);
      setTotalRecords(0);
      setTotalPages(0);
    }
    setOpenBreakdrop(false);
    closeFilterDrawer();
  };

  const handleDeleteEquipment = () => {};

  //  when admin successfully delete we have to close menu

  const [addCategory, setAddCategory] = useState(false);

  const [categoryList, setCategoryList] = useState([]);

  const getCategories = async () => {
    setOpenBreakdrop(true);
    const res = await getAllServices(1);
    if (res && res.data) {
      setCategoryList(res.data);
      setOpenBreakdrop(false);
    } else if (!res.status) {
      setOpenBreakdrop(false);
      // toast.error(res.message, toastType);
    }
  };
  useEffect(() => {
    getCategories();
  }, []);

  const onSubmit = async () => {
    const toastType = {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    };

    console.log("deleted equipment id", currentEquipment);
    const id = currentEquipment.id;
    setOpenBreakdrop(true);
    const res = await deleteEquipmentApi(id);
    if (res && res.status) {
      toast.success(res.message, toastType);
    } else {
      toast.error(res.message, toastType);
    }
    setOpenBreakdrop(false);
    closedelete();
    refreshScreen();
  };

  const getStatus = (status) => {
    const statusColors = {
      1: {
        backgroundColor: "rgba(18, 183, 106, 0.05)",
        color: "#12B76A",
      },

      0: {
        backgroundColor: "rgba(240, 68, 56, 0.05)",
        color: "#F04438;",
      },
    };
    return (
      <StyledChip
        sx={statusColors[status]}
        label={status ? "Active" : "InActive"}
      />
    );
  };

  return (
    <>
      <Backdrop open={openBackdrop} />
      <ToastContainer limit={1} />

      <div
        style={{
          backgroundColor: "white",
          borderRadius: "10px",
          color: "black",
        }}
      >
        {/* search bar and filter button */}
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          sx={{ padding: "20px 0px" }}
        >
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              sx={{
                height: "50px",
              }}
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
              onKeyUp={debounceserach}
            />
          </Search>

          <Button
            sx={{
              border: "1px solid rgba(15, 15, 15, 0.15)",
              borderRadius: "10px",
              mr: 2,
              padding: "5px 20px",
              fontWeight: "bold",
              color: "black",
            }}
            endIcon={
              <IconButton sx={{ ml: 3 }}>
                <img src={filterIcon} alt="Filter Options" />
              </IconButton>
            }
            onClick={openFilterDrawer}
          >
            Filter
          </Button>
        </Stack>
        <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
          <form
            onSubmit={onFilterSubmit}
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                // width: 220,
                padding: "10px 20px",
              }}
            >
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography sx={{ fontWeight: "bold" }}>Filter</Typography>
                <IconButton sx={{ color: "black" }} onClick={closeFilterDrawer}>
                  <CloseIcon />
                </IconButton>
              </Stack>

              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="name">Name </StyledFormLabel>
                <StyledTextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search Name"
                  fullWidth
                  id="name"
                  name="name"
                  value={values.name}
                  onChange={handleInputChange}
                />
              </Box>
              <Divider sx={{ mt: 2 }} />
              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="serviceId">Category</StyledFormLabel>
                <StyledTextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="serviceId"
                  fullWidth
                  id="serviceId"
                  name="serviceId"
                  value={values.serviceId}
                  select
                  onChange={handleInputChange}
                >
                  {categoryList.map((list, index) => {
                    return (
                      <MenuItem
                        key={index}
                        sx={{ width: "250px" }}
                        value={list.id}
                      >
                        {list.name}
                      </MenuItem>
                    );
                  })}
                </StyledTextField>
              </Box>
              <Divider sx={{ mt: 2 }} />
              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="minPrice">
                  Minimum Price{" "}
                </StyledFormLabel>
                <StyledTextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Minimum Price"
                  fullWidth
                  id="minPrice"
                  name="minPrice"
                  type="number"
                  value={values.minPrice}
                  onChange={handleInputChange}
                />
              </Box>
              <Divider sx={{ mt: 2 }} />
              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="maxPrice">
                  Maximum Price{" "}
                </StyledFormLabel>
                <StyledTextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Maximum Price"
                  fullWidth
                  id="maxPrice"
                  type="number"
                  name="maxPrice"
                  value={values.maxPrice}
                  onChange={handleInputChange}
                />
              </Box>
              <Divider sx={{ mt: 2 }} />
              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="status">Status</StyledFormLabel>
                <StyledTextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Status"
                  fullWidth
                  id="status"
                  name="status"
                  value={values.status}
                  select
                  onChange={handleInputChange}
                >
                  <MenuItem sx={{ width: "250px" }} value="1">
                    Active
                  </MenuItem>
                  <MenuItem value="2">In Active</MenuItem>
                  <MenuItem value="3">Deleted</MenuItem>
                </StyledTextField>
              </Box>
              <Divider sx={{ mt: 2 }} />
            </Box>
            <Stack
              sx={{ margin: "20px 20px 20px 20px", paddingBottom: "10px" }}
              flexDirection="row"
              justifyContent="space-between"
            >
              <Button
                sx={{
                  backgroundColor: "white",
                  color: "black",
                  border: "1px solid #0F0F0F80",
                  borderRadius: "10px",
                  padding: "10px 25px",
                  "&:hover": {
                    // backgroundColor: "#0957DD",
                  },
                }}
                onClick={() => {
                  resetForm();
                  onFilterSubmit();
                }}
              >
                Reset
              </Button>
              <Button
                sx={{
                  backgroundColor: "#0957DD",
                  color: "white",
                  borderRadius: "10px",
                  padding: "10px 25px",
                  "&:hover": {
                    backgroundColor: "#0957DD",
                  },
                }}
                type="submit"
              >
                Filter
              </Button>
            </Stack>
          </form>
        </Drawer>
        <TableContainer
          component={Paper}
          sx={{ minHeight: "300px", overflowY: "scroll" }}
        >
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell className="table-header-font">
                  Name
                </StyledTableCell>
                <StyledTableCell className="table-header-font">
                  Category
                </StyledTableCell>
                <StyledTableCell className="table-header-font">
                  Price
                </StyledTableCell>
                <StyledTableCell className="table-header-font">
                  Rating
                </StyledTableCell>
                <StyledTableCell className="table-header-font">
                  Status
                </StyledTableCell>
                <StyledTableCell className="table-header-font">
                  Date
                </StyledTableCell>
                <StyledTableCell className="table-header-font">
                  Action
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {equipments.length === 0 ? (
                <TableRow>
                  <TableCell
                    sx={{
                      color: "silver",
                      textAlign: "center",
                      paddingTop: "90px",
                      borderBottom: "none",
                      fontSize: "30px",
                    }}
                    colSpan="7"
                  >
                    No records to display
                  </TableCell>
                </TableRow>
              ) : (
                equipments.map((row, index) => (
                  <StyledTableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    onClick={() => {
                      navigate("/equipments/details", {
                        state: row.equipmentId,
                      });
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <TableCell component="th" scope="row">
                      <Stack flexDirection="row" alignItems="center">
                        <img
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "50%",
                          }}
                          sx={{ mx: 0.5 }}
                          alt="Equipment"
                          src={
                            !row.image
                              ? "https://urcare.mobiginie.com/uploads/admin/admin_1658383567974.jpg"
                              : row.image
                          }
                        />

                        <Typography sx={{ ml: 2 }}>
                          {row.equipmentName.length > 30
                            ? row.equipmentName.substr(0, 30) + "..."
                            : row.equipmentName}
                        </Typography>
                      </Stack>
                    </TableCell>
                    <TableCell>{row.categoryName}</TableCell>
                    <TableCell>
                      <span>
                        <span style={{ textDecoration: "line-through" }}>
                          <CurrencyRupeeIcon style={{ fontSize: "14px" }} />
                          {row.mrp}{" "}
                        </span>{" "}
                        <br />{" "}
                        <CurrencyRupeeIcon style={{ fontSize: "14px" }} />
                        {row.actualPrice}
                      </span>
                    </TableCell>
                    <TableCell>{row.numRating}</TableCell>
                    <TableCell>{getStatus(row.status)}</TableCell>
                    <TableCell>{moment(row.createdAt).format("LLL")}</TableCell>
                    <TableCell onClick={(e) => e.stopPropagation()}>
                      <IconButton
                        onClick={(e) => {
                          const current = equipments.find(
                            (role) => role.equipmentId === row.equipmentId
                          );

                          handleClick(e, current);
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </TableCell>
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Menu
          sx={{ mt: 0.3 }}
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuItem
            sx={{ mt: 1 }}
            onClick={() => {
              navigate("/equipments/details", {
                state: currentEquipment.equipmentId,
              });
            }}
          >
            <ListItemIcon>
              <img src={eye} alt="View Details" />
            </ListItemIcon>
            <ListItemText>View Details</ListItemText>
          </MenuItem>
          <MenuItem
            sx={{ mt: 1 }}
            onClick={() => {
              navigate("/equipments/edit", {
                state: currentEquipment.equipmentId,
              });
            }}
          >
            <ListItemIcon>
              <img src={edit} alt="Edit" />
            </ListItemIcon>
            <ListItemText>Edit</ListItemText>
          </MenuItem>
          <MenuItem
            sx={{ mt: 1 }}
            onClick={() => {
              handledelete();
              handleClose();
            }}
          >
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            <ListItemText>Delete</ListItemText>
          </MenuItem>
        </Menu>
      </div>

      {deleteValue && (
        <DeleteComponent
          open={deleteValue}
          handleClose={closedelete}
          onSubmit={onSubmit}
        />
      )}
      {/* pagination */}
      <Stack
        sx={{ mt: 4, pb: 2 }}
        flexDirection="row"
        justifyContent="space-between"
      >
        <div>
          Showing{" "}
          {page * pagePerSize > totalRecords
            ? totalRecords
            : page * pagePerSize}{" "}
          of {totalRecords} Results
        </div>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
        />
      </Stack>
    </>
  );
}
