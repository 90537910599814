import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
  Stack,
  Grid,
  IconButton,
  TextField,
  FormLabel,
  Select,
  MenuItem,
  InputAdornment,
  Avatar,
  Typography,
  Divider,
  Paper,
  Chip,
  DialogContentText,
} from "@mui/material";
import { Link } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  Close as CloseIcon,
  Person as PersonIcon,
  Edit as EditIcon,
  Download as DownloadIcon,
} from "@mui/icons-material";
import Backdrop from "../BackDrop/BackDrop";
import {
  categoryListDropdown,
  subCategoryListApi,
} from "../../state/actions/subCategoryAction";
import { editUserStatusApi } from "../../state/actions/usersAction";
import {
  nurseDetailsApi,
  saveNurseDetailsApi,
} from "../../state/actions/nurseAction";
import { toast } from "react-toastify";

const StyledChip = styled(Chip)(() => ({
  borderRadius: "10px",
  fontSize: "14px",
}));

const NurseDetails = (props) => {
  const { id, open, handleClose, refreshScreen } = props;

  const [isEditing, setEditing] = useState(false);
  const [currentStatus, setCurrentStatus] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [currentCategory, setCurrentCategory] = useState(-1);
  const [currentSubCategory, setCurrentSubCategory] = useState(-1);
  const [updatedSalary, setUpdatedSalary] = useState(0);

  const toggleEditing = () => {
    setEditing(!isEditing);
  };

  const [nurseDetail, setNurseDetail] = useState({});

  var {
    fname,
    profile_image,
    email,
    gender,
    mobile,
    isActive,
    docName,
    docType,
    educationCertificate,
    docPan,
    docAadhar,
    experience,
    resume,
    rating,
    categoryName,
    subcategory,
    height,
    weight,
    specialistExp,
    salary,
    emergencyPersonName,
    emergencyPersonNumber,
    refrenceDetail,
    address,
  } = nurseDetail;
  fname = fname ? fname : "-";
  email = email ? email : "-";
  address = address ? address : "-";
  experience = experience ? experience : "-";
  resume = resume ? resume : "-";
  rating = rating ? rating : "-";

  var dob = "23-05-1990";

  var communicationAddress = "Ward no 20, House No 123";

  const getNurseDetails = async (nurseId) => {
    const toastType = {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    };

    setOpenBreakdrop(true);
    const res = await nurseDetailsApi(nurseId);
    if (res.status) {
      setNurseDetail(res.data);
      setOpenBreakdrop(false);
    } else {
      setOpenBreakdrop(false);
      toast.error(res.message, toastType);
    }
  };

  const getCategories = async () => {
    const toastType = {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    };
    setOpenBreakdrop(true);
    // 2 for nurse
    const res = await categoryListDropdown(3);
    if (res && res.status && res.data) {
      setCategoryList(res.data);
      setOpenBreakdrop(false);
    } else if (!res.status) {
      setOpenBreakdrop(false);
      // toast.error(res.message,toastType)
    }
  };
  const getSubCategories = async (categoryId) => {
    const toastType = {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    };
    setOpenBreakdrop(true);
    const res = await subCategoryListApi(categoryId);
    if (res && res.status && res.data) {
      setSubCategoryList(res.data);
      setOpenBreakdrop(false);
    } else if (!res.status) {
      setOpenBreakdrop(false);
      // toast.error(res.message,toastType)
    }
  };

  useEffect(() => {
    if (salary && salary !== 0 && salary !== "") {
      setUpdatedSalary(salary);
    }
  }, [salary]);

  //  get nurse details
  useEffect(() => {
    if (id) {
      getNurseDetails(id);
    }
  }, [id]);

  useEffect(() => {
    setCurrentStatus(isActive);
  }, [isActive]);

  useEffect(() => {
    if (isEditing) {
      getCategories();
    }
  }, [isEditing]);

  //  get list of categories list
  useEffect(() => {
    if (typeof categoryName === "object" && "id" in categoryName) {
      var list = categoryList.find((list) => list.id === categoryName.id);
      if (list !== undefined) {
        setCurrentCategory(list.id);
      }
    }
  }, [categoryList]);

  //  category has been selected fetch list of subcategories
  useEffect(() => {
    if (currentCategory !== -1) {
      //  empty the subcategories list
      setSubCategoryList([]);
      setCurrentSubCategory(subcategory?.id);
      if (currentCategory) {
        const categoryId = currentCategory;
        //  fetch subcategories list
        getSubCategories(categoryId);
      }
    }
  }, [currentCategory]);

  //  get list of subcategories
  useEffect(() => {
    if (typeof subcategory === "object" && "id" in subcategory) {
      var list = subCategoryList.find((list) => list.id === subcategory.id);
      if (list !== undefined) {
        setCurrentSubCategory(list.id);
      }
    }
  }, [subCategoryList]);

  const handleCurrentCategory = async (event) => {
    var value = event.target.value;
    setCurrentCategory(value);
  };

  const handleCurrentSubCategory = async (event) => {
    var value = event.target.value;
    setCurrentSubCategory(value);
  };
  const saveNurseDetails = async () => {
    const body = {
      salary: updatedSalary,
      categoryId: currentCategory,
      subCategory: currentSubCategory,
    };
    const toastType = {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    };

    setOpenBreakdrop(true);
    const res = await saveNurseDetailsApi(id, body);
    if (res && res.status) {
      toast.success(res.message, toastType);
      getNurseDetails(id);
    } else if (!res.status) {
      toast.error(res.message, toastType);
    }
    setEditing(false);
    setOpenBreakdrop(false);
  };

  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const handleCurrentStatus = async (event) => {
    var value = event.target.value;
    var body = {
      isActive: value,
    };
    const toastType = {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    };
    setOpenBreakdrop(true);
    const res = await editUserStatusApi(id, body);
    if (res.status) {
      const { isActive: active } = res.data;
      setCurrentStatus(active);
      toast.success(res.message, toastType);
      refreshScreen();
    } else {
      toast.error(res.message, toastType);
    }
    setEditing(false);
    setOpenBreakdrop(false);
  };

  const getGender = (value) => {
    const genders = {
      1: "Male",
      2: "Female",
    };
    return genders[value] ? genders[value] : "-";
  };

  const getStatus = (value) => {
    const status = {
      0: {
        title: "Pending",
        styles: {
          ml: 2,
          backgroundColor: "rgb(235 193 14 / 24%)",
          color: "orange",
        },
      },
      1: {
        title: "Active",
        styles: {
          ml: 2,
          backgroundColor: "rgba(18, 183, 106, 0.05)",
          color: "#12B76A",
        },
      },
      2: {
        title: "Rejected",
        styles: {
          ml: 2,
          backgroundColor: "rgba(240, 68, 56, 0.05)",
          color: "#F04438;",
        },
      },
      3: {
        title: "Delete",
        styles: {
          ml: 2,
          backgroundColor: "rgba(240, 68, 56, 0.05)",
          color: "#F04438",
        },
      },
    };
    return status[value];
  };

  const statusList = [
    {
      name: "Pending",
      value: 0,
    },
    {
      name: "Active",
      value: 1,
    },
    {
      name: "Rejected",
      value: 2,
    },
    {
      name: "Delete",
      value: 3,
    },
  ];

  const status = getStatus(currentStatus);

  const isProfileImageValid = (profile) => {
    return profile && !profile.includes("null") && profile.includes("https")
      ? true
      : false;
  };

  const getResumeName = (url) => {
    var str = url.split("/");
    str = str[str.length - 1];
    return str === "null" ? "-" : str;
  };

  const isSaveBtnDisabled = () => {
    // var previousCategory = categoryName?.id;
    var previousSubCategory = subcategory?.id;
    return isEditing === true &&
      ((previousSubCategory !== currentSubCategory &&
        currentSubCategory != -1) ||
        (salary !== updatedSalary &&
          currentSubCategory !== undefined &&
          currentSubCategory !== -1))
      ? false
      : true;
  };
  const isEmpty = (value) => {
    if (value === null || value === undefined || value.trim().length === 0) {
      return "-";
    } else {
      return value;
    }
  };

  const defaultReferenceValues = (value) => {
    return value == undefined || value === null || value === ""
      ? "No Reference"
      : value;
  };

  return (
    <div>
      <Backdrop open={openBackdrop} />
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="lg">
        <DialogTitle
          sx={{
            fontWeight: "bold",
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => toggleEditing()}
          >
            Edit
          </Button>{" "}
          &nbsp;
          <Tooltip title="Close">
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>

        <DialogContent>
          <Grid container justifyContent="space-between">
            <Grid item xs={3.5}>
              <Stack spacing={2}>
                <Typography>
                  <b>Personal Details </b>
                </Typography>
                <Stack flexDirection="row" columnGap={2}>
                  <Grid item>
                    {isProfileImageValid(profile_image) ? (
                      <img
                        src={profile_image}
                        style={{ width: "50px", height: "50px" }}
                        alt={fname}
                      />
                    ) : (
                      <Avatar sx={{ width: "50px", height: "50px" }}>
                        <PersonIcon
                          sx={{ width: "inherit", height: "inherit" }}
                        />
                      </Avatar>
                    )}
                  </Grid>
                  <Stack columnGap={2}>
                    <Grid item>{fname ? fname : "-"}</Grid>
                    <Grid item>{email ? email : "-"}</Grid>
                    <Grid item>{mobile ? mobile : "-"}</Grid>
                    <Grid item>{getGender(gender)}</Grid>
                    <Grid item>{dob ? dob : "-"}</Grid>
                    <Grid item>{height ? `${height} Feet` : "-"}</Grid>
                    <Grid item>{weight ? `${weight} Kg` : "-"}</Grid>
                  </Stack>
                </Stack>
              </Stack>
            </Grid>

            <Grid item xs={4}>
              <Stack spacing={2}>
                <Typography>
                  <b>Family Details (Emergency)</b>
                </Typography>
                <Stack flexDirection="row" columnGap={2}>
                  {/* <Grid item>
                    {isProfileImageValid(profile_image) ? (
                      <img
                        src={profile_image}
                        style={{ width: "50px", height: "50px" }}
                        alt={fname}
                      />
                    ) : (
                      <Avatar sx={{ width: "50px", height: "50px" }}>
                        <PersonIcon
                          sx={{ width: "inherit", height: "inherit" }}
                        />
                      </Avatar>
                    )}
                  </Grid> */}
                  <Stack columnGap={2}>
                    <Grid item>
                      {emergencyPersonName ? emergencyPersonName : "-"}
                    </Grid>
                    <Grid item>
                      {emergencyPersonNumber ? emergencyPersonNumber : "-"}
                    </Grid>
                  </Stack>
                </Stack>
              </Stack>
            </Grid>

            <Grid item xs={4.5}>
              <Stack spacing={2}>
                <Typography>
                  <b>Status :</b>
                  {isEditing ? (
                    <Select
                      variant="outlined"
                      size="small"
                      fullWidth
                      id="status"
                      name="status"
                      value={currentStatus}
                      onChange={handleCurrentStatus}
                      sx={{ width: "150px", mx: "20px" }}
                    >
                      {statusList.map((value) => {
                        return (
                          <MenuItem key={value.key} value={value.value}>
                            {value.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  ) : (
                    <StyledChip label={status?.title} sx={status?.styles} />
                  )}
                </Typography>
                <Stack columnGap={2} rowGap={1}>
                  <Stack flexDirection="row" columnGap={2}>
                    <Button
                      variant="outlined"
                      size="small"
                      disabled={!isProfileImageValid(resume)}
                      onClick={() => {
                        window.open(resume);
                      }}
                    >
                      {isProfileImageValid(resume) ? "See" : "No"}  Resume
                    </Button>

                    <Button
                      variant="outlined"
                      size="small"
                      disabled={!isProfileImageValid(educationCertificate)}
                      onClick={() => {
                        window.open(educationCertificate, "_blank");
                      }}
                    >
                      {isProfileImageValid(educationCertificate) ? "See" : "No"}  Certificate
                    </Button>
                  </Stack>

                  <Stack flexDirection="row" columnGap={2}>
                    <Button
                      variant="outlined"
                      size="small"
                      disabled={!isProfileImageValid(docPan)}
                      onClick={() => {
                        window.open(docPan);
                      }}
                    >
                     {isProfileImageValid(docPan) ? "See" : "No"} Pan Card
                    </Button>
                    <Button
                      variant="outlined"
                      size="small"
                      disabled={!isProfileImageValid(docAadhar)}
                      onClick={() => {
                        window.open(docAadhar, "_blank");
                      }}
                    >
                      {isProfileImageValid(docAadhar) ? "See" : "No"} Aadhar Card
                    </Button>
                  </Stack>
                  <Typography sx={{ display: "flex", alignItems: "center" }}>
                    <b>Salary : </b>

                    {isEditing ? (
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        id="salary"
                        name="salary"
                        type="number"
                        value={updatedSalary}
                        onChange={(e) => {
                          setUpdatedSalary(e.target.value);
                        }}
                        sx={{ width: "150px", mx: "20px" }}
                      />
                    ) : (
                      `  Rs ${updatedSalary}`
                    )}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
          </Grid>

          <Grid container justifyContent="space-between" my={2}>
            <Grid item xs={6}>
              <Stack flexDirection="row" marginY={2} columnGap={2}>
                <Stack mt={1}>
                  <Grid item mb={2}>
                    <b>Category </b>
                  </Grid>
                  {isEditing ? (
                    <Select
                      variant="outlined"
                      size="small"
                      fullWidth
                      id="category"
                      name="category"
                      value={currentCategory}
                      onChange={handleCurrentCategory}
                      sx={{ width: "150px" }}
                    >
                      {categoryList?.map((value, index) => {
                        return (
                          <MenuItem key={index} value={value.id}>
                            {value.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  ) : (
                    <Grid container my={1}>
                      <Grid item>
                        <Avatar src={categoryName?.image} />
                      </Grid>
                      <Stack mx={2}>
                        <Grid item> {categoryName?.name}</Grid>
                      </Stack>
                    </Grid>
                  )}
                </Stack>
                <Stack mt={1}>
                  <Grid item mb={2}>
                    <b>Sub Category </b>
                  </Grid>
                  {isEditing ? (
                    <Select
                      variant="outlined"
                      size="small"
                      fullWidth
                      id="subcategory"
                      name="subcategory"
                      value={currentSubCategory}
                      onChange={handleCurrentSubCategory}
                      sx={{ width: "250px" }}
                    >
                      {subCategoryList?.map((value) => {
                        return (
                          <MenuItem key={value.id} value={value.id}>
                            {value.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  ) : (
                    <Grid container my={1}>
                      <Grid item>
                        <Avatar src={subcategory?.image} />
                      </Grid>
                      <Stack mx={2}>
                        <Grid item> {subcategory?.name}</Grid>
                      </Stack>
                    </Grid>
                  )}
                </Stack>
              </Stack>
            </Grid>

            <Grid item xs={6}>
              <Stack spacing={2} my={2} mr={15}>
                <Typography>
                  <b>Work Experience </b>
                </Typography>
                <Stack columnGap={2} rowGap={1}>
                  <Stack flexDirection="row" columnGap={2}>
                    <DialogContentText>
                      <b>Experience : </b>
                    </DialogContentText>
                    <Typography>{experience}</Typography>
                  </Stack>
                  <Stack flexDirection="row" columnGap={2}>
                    <DialogContentText>
                      <b>Specialist Experience :</b>
                    </DialogContentText>

                    <Typography>{specialistExp}</Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Grid>
          </Grid>

          <Grid container>
            {refrenceDetail?.map((list, index) => {
              var { rName, rPosition, rMobile, email: rEmail } = list;
              rName = defaultReferenceValues(rName);
              rPosition = defaultReferenceValues(rPosition);
              rMobile = defaultReferenceValues(rMobile);
              rEmail = defaultReferenceValues(rEmail);
              return (
                <Grid item xs={4} key={index} mb={3}>
                  <Stack spacing={2}>
                    <Typography>
                      <b>Reference Contact {index + 1} </b>
                    </Typography>
                    <Stack flexDirection="row" columnGap={2}>
                      <Stack columnGap={2}>
                        <Grid item>{rName}</Grid>
                        <Grid item>{rPosition}</Grid>
                        <Grid item>{rMobile}</Grid>
                        <Grid item>{rEmail}</Grid>
                      </Stack>
                    </Stack>
                  </Stack>
                </Grid>
              );
            })}

            <Grid item xs={4}>
              <Stack spacing={2}>
                <Typography>
                  <b>Communication Address </b>
                </Typography>
                <Stack columnGap={2}>
                  <div>{address}</div>
                  {/* <div>Jammu, India</div> */}
                </Stack>
              </Stack>
            </Grid>
          </Grid>

          <Divider sx={{ my: 4 }} />
        </DialogContent>
        <DialogActions sx={{ margin: "15px" }}>
          <Button
            variant="contained"
            color="success"
            size="large"
            disabled={isSaveBtnDisabled()}
            onClick={saveNurseDetails}
          >
            Save
          </Button>
          <Button
            sx={{
              backgroundColor: "#0957dd",
              color: "white",
              borderRadius: "10px",
              padding: "10px 25px",
              "&:hover": {
                backgroundColor: "#0957dd",
              },
            }}
            onClick={handleClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NurseDetails;
