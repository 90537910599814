import React from "react";
import { Box } from "@mui/material";
import { Routes, Route } from "react-router-dom";

import Dashboard from "../Dashboard/Dashboard";
import Profile from "../Profile/Profile";
import Category from "../Category/Category";
import SubCategory from "../SubCategory/Subcategory";
import Users from "../Users/User";

import Nurse from "../Nurse/Nurse";
import Caregiver from "../Caregiver/Caregiver";
import Equipments from "../Equipments/Equipments";
import AddEquipments from "../Equipments/AddEquipments";
import EquipmentsDetails from "../Equipments/EquipmentsDetails";
import Services from "../Services/Services";

import EquipmentsOrder from "../EquipmentsOrder/EquipmentsOrder";
import EquipmentsOrderDetails from "../EquipmentsOrder/EquipmentsOrderDetails";

import Nurses from "../Nurses/Nurses";
import NursesDetails from "../Nurses/NursesDetails";

import PrivacyPolicy from "../privacyPolicy/privacyPolicy";
import TermsCondition from "../termsCondition/termsCondiation";
import ContactSupport from "../contactSupport/contactSupport";
import CancellationReasons from "../CancellationReason/Cancellation";

import HSN from "../HSN/HSN";
import Countries from "../Address/Countries";
import Address from "../Address/Address";
import City from "../Address/City";
import Payment from "../Payment/Payment";

const Main = (props) => {
  return (
    <Box
      component="main"
      sx={{ backgroundColor: "revert", mt: 2, padding: "0px 25px 10px 30px" }}
    >
      <Routes>
        <Route exact path="/dashboard" element={<Dashboard />} />

        <Route exact path="/profile" element={<Profile />} />
        <Route exact path="/category" element={<Category />} />
        <Route exact path="/services" element={<Services />} />
        <Route exact path="/subcategory" element={<SubCategory />} />

        <Route exact path="/users" element={<Users />} />
        <Route exact path="/nurse" element={<Nurse />} />
        <Route exact path="/caregiver" element={<Caregiver />} />

        <Route exact path="/equipments" element={<Equipments />} />
        <Route exact path="/equipments/add" element={<AddEquipments />} />
        <Route exact path="/equipments/edit" element={<AddEquipments />} />
        <Route
          exact
          path="/equipments/details"
          element={<EquipmentsDetails />}
        />

        <Route exact path="/equipmentsorder" element={<EquipmentsOrder />} />
        <Route
          exact
          path="/equipmentsorder/details"
          element={<EquipmentsOrderDetails />}
        />

        <Route exact path="/nurses" element={<Nurses />} />
        <Route exact path="/nurses/details" element={<NursesDetails />} />

        <Route exact path="/hsn" element={<HSN />} />
        <Route exact path="/countries" element={<Countries />} />
        <Route exact path="/states" element={<Address />} />
        <Route exact path="/address/cities" element={<City />} />

        <Route exact path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route exact path="/termscondition" element={<TermsCondition />} />
        <Route exact path="/contactsupport" element={<ContactSupport />} />
        <Route
          exact
          path="/cancellationreason"
          element={<CancellationReasons />}
        />

        <Route exact path="/payment" element={<Payment />} />
      </Routes>
    </Box>
  );
};

export default Main;
