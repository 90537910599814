import React, { useState, useEffect } from "react";
import Backdrop from "../BackDrop/BackDrop";
import { getSupportNumber, updateSupportNumber } from "../../state/actions/generalAction";
import { ToastContainer, toast } from "react-toastify";
import { styled } from "@mui/material/styles";
import { Button, Grid, Box, Typography, IconButton, Stack, FormLabel, TextField } from "@mui/material";
import { useForm } from "../../Utils/useForm";

const toastType = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
};

const StyledHeading = styled("h1")({
    // fontFamily: 'Work Sans',
    // fontStyle: "normal",
    fontWeight: "600",
    fontSize: "24px",
    lineHeight: "32px",
});

const StyledDiv = styled("div")({
    display: "flex",
    background: "#FFFFFF",
    boxShadow:
        "  0px 20px 94px rgba(16, 24, 40, 0.01), 0px 8px 30px rgba(16, 24, 40, 0.01)",
    borderRadius: "12px !important",
    padding: "22px",
    width: "100%",
    height: "100%",
});

const StyledFormLabel = styled(FormLabel)(() => ({
    display: "block",
    fontWeight: "bold",
    color: "black",
}));

const StyledRequired = styled("span")({
    color: "red",
    marginLeft: "2px",
    fontSize: "20px",
});

const StyledTextField = styled(TextField)(() => ({
    marginTop: "10px",
    ".MuiOutlinedInput-root": {
        borderRadius: "10px",
    },
}));

const initialValues = {
    descriptions: "",
};


export default function TermsCondition() {

    const [openBackdrop, setOpenBreakdrop] = useState(false);
    const [policyId, setpolicyId] = useState(0);

    const getPolicy = async () => {
        setOpenBreakdrop(true);
        const res = await getSupportNumber();
        if (res && res.status) {
            setpolicyId(res.data.id);
            setValues({
                descriptions: res.data.description,
            })
            setOpenBreakdrop(false);
        } else {
            setOpenBreakdrop(false);
        }
    }

    const validate = (fieldValues = values) => {
        let temp = { ...errors };

        if ("descriptions" in fieldValues) {
            if (fieldValues.descriptions != "") {
                if (/^\d+(\.\d+)*$/.test(fieldValues.descriptions)) {
                    temp.descriptions = "";
                } else {
                    temp.descriptions = "Only Number allow";
                }
            } else {
                temp.descriptions = "This field is required."
            }

        }

        setErrors({
            ...temp,
        });

        if (fieldValues === values)
            return Object.values(temp).every((x) => x === "");
    };

    const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
        useForm(initialValues, true, validate);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setOpenBreakdrop(true);
        const payload = {
            descriptions: values.descriptions
        };
        const res = await updateSupportNumber(policyId, payload);
        if (res && res.status) {
            setOpenBreakdrop(false);
            toast.success(res.message, toastType);
        } else {
            setOpenBreakdrop(false);
        }
    }


    useEffect(() => {
        getPolicy();

    }, [])

    return (
        <>
            <Backdrop open={openBackdrop} />
            <ToastContainer limit={1} />
            <Stack flexDirection="row" justifyContent="space-between">
                <Typography sx={{ fontSize: "30px", fontWeight: "bold" }}>
                    Contact & Support
                </Typography>
            </Stack>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container>
                    <StyledDiv>
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={0}>
                                <Grid item={true} sm={12} xs={12}>
                                    <Grid item={true} xs={12} container spacing={1}>
                                        <Grid item={true} md={12} xs={12}>
                                            <StyledFormLabel htmlFor="name">
                                                Contact & Support Number
                                                {Boolean(errors.name) && (
                                                    <StyledRequired> *</StyledRequired>
                                                )}
                                            </StyledFormLabel>
                                            <StyledTextField
                                                fullWidth
                                                id="descriptions"
                                                name="descriptions"
                                                value={values.descriptions}
                                                onChange={handleInputChange}
                                                error={Boolean(errors.descriptions)}
                                                helperText={errors.descriptions}
                                                inputProps={{ maxLength: 10 }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Stack flexDirection="row" justifyContent="space-between" style={{ paddingTop: "10px" }}>
                                <Button
                                    sx={{
                                        backgroundColor: "#0957DD",
                                        color: "white",
                                        borderRadius: "10px",
                                        padding: "15px 40px",
                                        "&:hover": {
                                            backgroundColor: "#0957DD",
                                        },
                                    }}
                                    type="submit"
                                    // onClick={() => updatePolicy()}
                                    color="primary"
                                >
                                    Save
                                </Button>
                            </Stack>
                        </form>
                    </StyledDiv>
                </Grid>
            </Box>


        </>
    )
}
