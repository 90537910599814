import React from "react";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
  Stack,
  Grid,
  IconButton,
  TextField,
  FormLabel,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Close } from "@mui/icons-material";

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "rgba(15, 15, 15, 0.5)",
}));
const StyledTextField = styled(TextField)(() => ({
  marginTop: "10px",
  ".MuiInput-root": {
    // paddingBottom: "20px",
  },
}));

const SubCateogryDetails = (props) => {
  const { subCategory, open, handleClose } = props;
  var description = subCategory.description;
  if (description === "NaN") {
    description = "";
  }
  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="md">
        <DialogTitle
          sx={{
            fontWeight: "bold",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          SubCategory Details
          <Tooltip title="Close">
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Tooltip>
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={2}>
            <Grid item md={3} sm={4} xs={12}>
              <Stack
                sx={{
                  mt: 2,
                  padding: "30px 0px",
                  border: "1px solid rgba(15, 15, 15, 0.15)",
                  // height: "206px",
                  borderRadius: "10px",
                }}
                flexDirection="column"
                alignItems="center"
                spacing={2}
              >
                <img
                  src={subCategory.image}
                  style={{ width: "100px", height: "100px" }}
                  alt="subCategory profile"
                />
              </Stack>
            </Grid>
            <Grid item md={9} sm={8} xs={12} container spacing={1}>
              <>
                <Grid item sx={{ m: 1 }} xs={12}>
                  <StyledFormLabel htmlFor="name">Name </StyledFormLabel>
                  <StyledTextField
                    inputProps={{ readOnly: true }}
                    variant="standard"
                    fullWidth
                    id="name"
                    name="name"
                    defaultValue={subCategory.name}
                  />
                </Grid>
                <Grid item sx={{ m: 1 }} xs={12}>
                  <StyledFormLabel htmlFor="serviceName">
                    Category Name
                  </StyledFormLabel>
                  <StyledTextField
                    inputProps={{ readOnly: true }}
                    variant="standard"
                    fullWidth
                    id="serviceName"
                    defaultValue={subCategory.serviceName}
                  />
                </Grid>

                <Grid item sx={{ m: 1 }} xs={12}>
                  <StyledFormLabel htmlFor="categoryName">
                    Service Name
                  </StyledFormLabel>
                  <StyledTextField
                    inputProps={{ readOnly: true }}
                    variant="standard"
                    fullWidth
                    id="categoryName"
                    defaultValue={subCategory.categoryName}
                  />
                </Grid>
                <Grid item sx={{ m: 1 }} xs={12}>
                  <StyledFormLabel htmlFor="description">
                    Description
                  </StyledFormLabel>
                  <StyledTextField
                    inputProps={{ readOnly: true }}
                    variant="standard"
                    fullWidth
                    id="description"
                    defaultValue={description}
                  />
                </Grid>
              </>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ margin: "15px" }}>
          <Button
            sx={{
              backgroundColor: "#0957dd",
              color: "white",
              borderRadius: "10px",
              padding: "10px 25px",
              "&:hover": {
                backgroundColor: "#0957dd",
              },
            }}
            onClick={handleClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SubCateogryDetails;
