import * as React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Card } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import moment from "moment";
import { Grid, Button, Stack, Typography, IconButton } from "@mui/material";
import Avatar from "@mui/material/Avatar";
// import avtarimage from "../../assets/Rectangle752.png";
import { Routes, Route, useParams, Link, useLocation } from "react-router-dom";
import product_image from "../../Assets/Rectangle_glass.png";
import { Edit as EditIcon } from "@mui/icons-material";
import {
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  Select,
  MenuItem,
} from "@mui/material";
import {
  orderDetailsApi,
  editOrderStatusApi,
} from "../../state/actions/orderAction";
import Backdrop from "../BackDrop/BackDrop";
import { toast } from "react-toastify";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import "./styles/equipmentdetails.css";

const strong = {
  color: "black",
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: "#0F0F0F80",
}));
const FlexBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
}));

const StyledChip = styled(Chip)(() => ({
  borderRadius: "10px",
  fontSize: "14px",
}));

export default function OrderDetailspage() {
  const navigate = useNavigate();
  const { state } = useLocation();
  var orderId = "";
  var parent = "";

  orderId = state?.orderId;
  parent = state?.parent;

  const [orderDetails, setorderDetails] = useState({});
  const [openBackdrop, setOpenBreakdrop] = useState(false);

  const [isStatusEditing, setStatusEditing] = useState(false);
  const startStatusEditing = () => {
    setStatusEditing(true);
  };
  const stopStatusEditing = () => {
    setStatusEditing(false);
  };
  const [currentStatus, setCurrentStatus] = useState("");

  const handleCurrentStatus = async (event) => {
    const toastType = {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    };

    var value = event.target.value;
    var body = {
      orderId: orderId,
      status: value,
    };
    setOpenBreakdrop(true);
    // console.log("status", body);
    const res = await editOrderStatusApi(body);
    if (res && res.status) {
      setCurrentStatus(value);
      setOpenBreakdrop(false);
      toast.success(res.message, toastType);
    } else {
      toast.error(res.message, toastType);
    }
  };

  const statusList = [
    { name: "Pending", value: 1 },
    {
      name: "Cancelled",
      value: 2,
    },
    {
      name: "Delivered",
      value: 3,
    },
    {
      name: "Returned",
      value: 4,
    },
    {
      name: "Completed",
      value: 5,
    },
    {
      name: "Shipped",
      value: 6,
    },
  ];
  const getStatus = (value) => {
    const status = {
      1: "Pending",
      2: "Cancelled",
      3: "Delivered",
      4: "Returned",
      5: "Completed",
      6: "Shipped",
    };
    return status[value];
  };

  useEffect(() => {
    if (orderDetails?.orderData?.status) {
      setCurrentStatus(orderDetails.orderData.status);
    }
  }, [orderDetails]);

  const [statusMenu, setStatusMenu] = useState(true);

  const openStatusMenu = () => {
    setStatusMenu(true);
  };
  const closeStatusMenu = () => {
    setStatusMenu(false);
  };

  const getOrderDetails = async (id) => {
    const body = {
      orderId: id,
    };
    setOpenBreakdrop(true);
    const res = await orderDetailsApi(body);
    if (res && res.status && res.data) {
      setorderDetails(res.data);
      setOpenBreakdrop(false);
    } else if (res.status === false) {
      setOpenBreakdrop(false);
      toast.error(res.message);
    }
  };
  useEffect(() => {
    getOrderDetails(orderId);
  }, [orderId]);

  const paymentDetails = [
    {
      name: "Subtotal :",
      value: Math.ceil(orderDetails?.itemtotal),
    },
  ];
  const finalDetails = [
    {
      name: "Total :",
      value: Math.ceil(orderDetails?.grandTotal),
    },
  ];
  const cancelReturnDataList = orderDetails?.cancelReturnDataList;
  const cancelReturnDetails = [
    {
      name: "Type ",
      value: cancelReturnDataList?.type ? cancelReturnDataList.type : "-",
    },
    {
      name: "Reason",
      value: cancelReturnDataList?.type ? cancelReturnDataList.reasonId : "-",
    },
    {
      name: "Description",
      value: cancelReturnDataList?.type
        ? cancelReturnDataList.description
        : "-",
    },
  ];

  const getPaymentStatus = (id) => {
    const status = {
      0: {
        label: "Failed",
        styleSheet: {
          backgroundColor: "#bfbdbd",
          color: "#dc3545",
        },
      },
      1: {
        label: "Success",
        styleSheet: {
          backgroundColor: "#28a74538",
          color: "#28a745",
        },
      },
      2: {
        label: "Pending",
        stylesheet: {
          backgroundColor: "rgb(0 0 0 / 10%)",
          color: "#616161",
        },
      },
      3: {
        label: "Declined",
        stylesheet: {
          backgroundColor: "rgb(115 56 240 / 8%)",
          color: "#ffc107;",
        },
      },
      4: {
        label: "Failed",
        stylesheet: {
          backgroundColor: "rgb(240 68 56 / 15%)",
          color: "#e90000",
        },
      },
    };
    if (status[id] === undefined) return "-";
    const { label, stylesheet } = status[id];
    return <StyledChip sx={stylesheet} label={label} />;
  };

  return (
    <>
      <Backdrop open={openBackdrop} />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", paddingRight: "5px" }}>
          <div
            className="back-btuoon-details-equip-order"
            onClick={() => {
              if (parent) {
                navigate(parent);
              } else {
                navigate("/equipmentsorder");
              }
            }}
          >
            <ArrowBackIosIcon style={{ width: "15px" }} />
            <span>Back</span>
          </div>
        </div>
        <Box borderRadius="12px">
          {/* <Link to={`/order/invoice/${id}`}
                        style={{
                            textDecoration: 'none',
                        }}
                    >
                        <Button variant="contained" size="large"
                            style={{
                                backgroundColor: '#0957DD',
                                borderRadius: '10px',
                            }}
                        >
                            <strong style={{ color: "white",fontWeight:'500',fontSize:"16px" }}>Convert to Invoice</strong>
                        </Button>
                    </Link> */}
        </Box>
      </div>
      <Card
        style={{
          padding: "20px",
          borderRadius: "12px",
          marginTop: "20px",
        }}
      >
        <Grid item md={12} sm={8} xs={12} container spacing={1}>
          <Grid item md={6} xs={12}>
            <Grid item md={12} sm={12} xs={12}>
              <Typography
                variant="h6"
                style={{
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "24px",
                  lineHeight: "32px",
                }}
              >
                Order Id :{" "}
                <span className="booking-id">
                  {orderDetails?.orderData?.orderNumber}
                </span>
              </Typography>
            </Grid>
            {/* <Grid item md={12} sm={12} xs={12}>
              <Typography
                variant="h6"
                style={{
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "16px",
                  color: "rgba(40, 45, 38, 0.5)",
                  lineHeight: "34px",
                }}
              >
                Equipments Order #{orderDetails?.orderData?.orderNumber}
              </Typography>
            </Grid> */}
            <Grid item md={12} sm={12} xs={12}>
              <Typography
                variant="p"
                style={{
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "16px",
                  color: "rgba(40, 45, 38, 0.5)",
                  lineHeight: "34px",
                }}
              ></Typography>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Typography
                variant="h6"
                style={{
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "16px",
                  color: "rgba(40, 45, 38, 0.5)",
                  marginTop: "20px",
                  lineHeight: "34px",
                  marginBottom: "25px",
                }}
              >
                Status
              </Typography>
            </Grid>

            <Grid container md={12} sm={12} xs={12}>
              <Grid
                item
                md={3}
                sm={12}
                xs={12}
                style={{
                  borderLeft: "4px solid #0957dd",
                }}
              >
                <Typography
                  variant="h6"
                  style={{
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "16px",
                    lineHeight: "24px",
                    marginTop: "4px",
                    marginLeft: "8px",
                  }}
                >
                  Order
                </Typography>
              </Grid>
              <Grid item md={9} sm={12} xs={12}>
                {isStatusEditing ? (
                  <Select
                    size="small"
                    variant="outlined"
                    fullWidth
                    id="status"
                    name="status"
                    value={currentStatus}
                    open={statusMenu}
                    onOpen={openStatusMenu}
                    onClose={() => {
                      closeStatusMenu();
                      stopStatusEditing();
                    }}
                    onChange={handleCurrentStatus}
                    sx={{ width: "200px" }}
                  >
                    {statusList.map((value) => {
                      return (
                        <MenuItem key={value.key} value={value.value}>
                          {value.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                ) : (
                  <Button
                    onClick={() => {
                      startStatusEditing();
                      openStatusMenu();
                    }}
                    variant="contained"
                    size="large"
                    style={{
                      backgroundColor: "#0957DD",
                      borderRadius: "10px",
                      fontSize: "14px",
                      boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                      padding: "8px 14px",
                      // width: "10px",
                      // height: "36px",
                    }}
                    endIcon={<EditIcon />}
                  >
                    <strong
                      style={{
                        color: "white",
                        fontSize: "14px",
                        fontWeight: "500",
                        lineHeight: "20px",
                      }}
                    >
                      {getStatus(currentStatus)}
                    </strong>
                  </Button>
                )}
              </Grid>
              <Grid
                item
                md={3}
                sm={12}
                xs={12}
                style={{
                  borderLeft: "4px solid #0957dd",
                }}
              >
                <Typography
                  variant="h6"
                  style={{
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "16px",
                    lineHeight: "24px",
                    marginTop: "8px",
                    marginLeft: "8px",
                  }}
                ></Typography>
              </Grid>
              <Grid item md={9} sm={12} xs={12}>
                <Typography
                  variant="h6"
                  style={{
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "16px",
                    lineHeight: "24px",
                    marginTop: "8px",
                  }}
                ></Typography>
              </Grid>
              <Grid
                item
                md={3}
                sm={12}
                xs={12}
                style={{
                  marginTop: "20px",
                }}
              >
                <Typography
                  variant="h6"
                  style={{
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "34px",
                    marginTop: "8px",
                    color: "rgba(40, 45, 38, 0.5)",
                  }}
                >
                  Order Date
                </Typography>
              </Grid>
              <Grid
                item
                md={9}
                sm={12}
                xs={12}
                style={{
                  marginTop: "20px",
                }}
              >
                {/* <Typography
                  variant="h6"
                  style={{
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "34px",
                    marginTop: "8px",
                    color: "rgba(40, 45, 38, 0.5)",
                  }}
                >
                  Expected Shipping Date
                </Typography> */}
              </Grid>
              <Grid item md={3} sm={12} xs={12}>
                <Typography
                  variant="h6"
                  style={{
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}
                >
                  {moment(orderDetails?.orderData?.createdAt).format("LL")}
                </Typography>
              </Grid>
              {/* <Grid item md={9} sm={12} xs={12}>
                <Typography
                  variant="h6"
                  style={{
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}
                >
                  4/21/14
                </Typography>
              </Grid> */}
            </Grid>
          </Grid>

          <Grid
            item
            md={6}
            xs={12}
            style={{
              // textAlign: "right",
              paddingLeft: "100px",
            }}
          >
            <Grid item md={12} sm={12} xs={12}>
              <Typography
                variant="h6"
                style={{
                  fontStyle: "normal",

                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                <b>Name : </b>
                <span style={{ color: "rgba(40, 45, 38, 0.5)" }}>
                  {orderDetails?.deliveryAddress?.name}
                </span>
              </Typography>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Typography
                variant="h6"
                style={{
                  fontStyle: "normal",
                  fontSize: "16px",
                  lineHeight: "34px",
                }}
              >
                <b>Mobile : </b>{" "}
                <span style={{ color: "rgba(40, 45, 38, 0.5)" }}>
                  {orderDetails?.userData?.mobile}
                </span>
              </Typography>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Typography
                variant="h6"
                style={{
                  fontStyle: "normal",
                  fontSize: "16px",
                  lineHeight: "34px",
                }}
              >
                <b>Email : </b>
                <span style={{ color: "rgba(40, 45, 38, 0.5)" }}>
                  {" "}
                  {orderDetails?.userData?.email}
                </span>
              </Typography>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Typography
                variant="h6"
                style={{
                  fontStyle: "normal",
                  fontSize: "16px",
                  lineHeight: "34px",
                }}
              >
                <b>Address : </b>
                <span style={{ color: "rgba(40, 45, 38, 0.5)" }}>
                  {orderDetails?.deliveryAddress?.address1 + ", "}
                  {orderDetails?.deliveryAddress?.address2 + ", "}
                  {orderDetails?.deliveryAddress?.area + ", "}
                  {orderDetails?.deliveryAddress?.landmark + ", "}
                  {orderDetails?.deliveryAddress?.state + ", "}
                  {orderDetails?.deliveryAddress?.city + ", "}
                  {orderDetails?.deliveryAddress?.pincode}
                </span>
              </Typography>
            </Grid>
            <br />

            <div>
              <b>Payment Status : </b> &nbsp;{" "}
              {getPaymentStatus(orderDetails?.orderData?.paymentStatus)}
            </div>
            <div>
              <b>Payment Type : </b> &nbsp; {orderDetails?.paymentDetail}
            </div>
            <div>
              <b>Payment Amount :</b> &nbsp; Rs{" "}
              {Math.ceil(orderDetails?.grandTotal)}
            </div>
          </Grid>
        </Grid>

        {/* <TableContainer
          component={Paper}
          sx={{ height: "auto" }}
        > */}
        <Table
          stickyHeader
          aria-label="simple table"
          style={{ marginTop: "25px" }}
        >
          <TableHead>
            <TableRow>
              <StyledTableCell>Product Name</StyledTableCell>
              <StyledTableCell>Quantity</StyledTableCell>
              <StyledTableCell>Total</StyledTableCell>
              <StyledTableCell>Order Place via</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orderDetails?.itemDetails?.map((row, index) => {
              var { image, name, mrp, actualPrice, quantity } = row;
              return (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <Stack flexDirection="row" alignItems="center">
                      <Avatar
                        component="span"
                        variant="rounded"
                        alt="profile_image"
                        src={image}
                      />
                      <Typography sx={{ ml: 2 }}>{name}</Typography>
                    </Stack>
                  </TableCell>

                  <TableCell>
                    <StyledChip
                      sx={{
                        backgroundColor: "rgba(18, 183, 106, 0.05)",
                        color: "#12B76A",
                      }}
                      label={quantity}
                    />
                  </TableCell>
                  <TableCell>{actualPrice * quantity}</TableCell>
                  <TableCell>Online</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <div
          style={{
            borderTop: "1px solid rgba(15, 15, 15, 0.15)",
            marginTop: "5px",
          }}
        ></div>

        <Grid container>
          {paymentDetails.map((details, index) => {
            const { name, value } = details;
            return (
              <Grid item container justifyContent="flex-end" my={2} key={index}>
                <Grid
                  item
                  // mr={8}
                  lg={2}
                  md={3}
                  sm={4}
                  xs={8}
                >
                  {name}
                </Grid>
                <Grid item xs={1.5} fontWeight="bold">
                  Rs {value}
                </Grid>
              </Grid>
            );
          })}
        </Grid>
        <div
          style={{
            borderTop: "1px solid rgba(15, 15, 15, 0.15)",
            marginTop: "5px",
          }}
        />
        <Grid container>
          {finalDetails.map((details, index) => {
            const { name, value } = details;
            return (
              <Grid item container justifyContent="flex-end" my={2} key={index}>
                <Grid
                  item
                  // mr={8}
                  lg={2}
                  md={3}
                  sm={4}
                  xs={8}
                >
                  {name}
                </Grid>
                <Grid item xs={1.5} fontWeight="bold">
                  Rs {value}
                </Grid>
              </Grid>
            );
          })}
        </Grid>
        {/*  cancel reaturn data list */}
        <Grid container>
          {cancelReturnDetails.map((list, index) => {
            return (
              <Grid item sm={3} key={index}>
                <div>
                  <b> {list.name}</b>
                </div>
                <div>{list.value}</div>
              </Grid>
            );
          })}
        </Grid>
      </Card>
    </>
  );
}
