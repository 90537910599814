import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";

import { styled } from "@mui/material/styles";
import {
  Search as SearchIcon,
  MoreVert as MoreVertIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import {
  InputBase,
  Button,
  Typography,
  IconButton,
  Stack,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Drawer,
  Divider,
  Box,
  TextField,
  FormLabel,
  InputAdornment,
  Pagination
} from "@mui/material";
import moment from "moment";

import eye from "../../Assets/eye.svg";
import edit from "../../Assets/edit.svg";
import deleteIcon from "../../Assets/delete.svg";
import filterIcon from "../../Assets/filter.svg";

import CancellationDetailsModal from "./CancellationDetails";
import AddCancellation from "./AddCancellation";
import DeleteCancellation from "./DeleteCancellation";
import { getCancellationReasonsList } from "../../state/actions/cancellationAction";
import { useForm } from "../../Utils/useForm";
import { getBase64FromUrl } from "../../Utils/getBase64FromUrl";
import Backdrop from "../BackDrop/BackDrop";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  color: "black",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  borderRadius: "10px",
  backgroundColor: "white",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    paddingRight: "15px",
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: "#0F0F0F80",
}));

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
}));
const StyledTextField = styled(TextField)(() => ({
  marginTop: "10px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
  },
}));
const StyledChip = styled(Chip)(() => ({
  borderRadius: "10px",
  fontSize: "14px",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#0957dd0a",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function CancellationList(props) {
  const { refresh, refreshScreen } = props;
  const dispatch = useDispatch();
  const [cancellationReasons, setCancellationReasons] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentCancellationReason, setCurrentCancellationReason] =
    useState(-1);

  const [cancellationView, setCancellationView] = useState(false);
  const [deleteCancellation, setDeleteCancellation] = useState(false);

  const [editCancellation, setEditCancellation] = useState(false);
  const [openBackdrop, setOpenBreakdrop] = useState(false);

  const [page, setPage] = useState(1);
  const [pagePerSize, setPagePerSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(0);


  async function getCancellationReasons(page) {
    let queryString = "?page=" + page + "&size=" + pagePerSize;

    setOpenBreakdrop(true);
    const res = await getCancellationReasonsList(queryString);
    if (res && res.status) {
      setCancellationReasons(res.data);
      setTotalRecords(res.totalRecord);
      setTotalPages(Math.ceil(res.totalRecord / pagePerSize));
    } else {
      setCancellationReasons([]);
      setTotalRecords(res.totalRecord);
      setTotalPages(Math.ceil(res.totalRecord / pagePerSize));
    }
    setOpenBreakdrop(false);
  }

  const handleChangePage = (event, value) => {
    setPage(value);
    getCancellationReasons(value);
  };
  // api call for get a cancellation-list
  useEffect(() => {
    getCancellationReasons();
  }, [refresh]);

  useEffect(() => {
    handleClose();
  }, [cancellationReasons]);

  const open = Boolean(anchorEl);
  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setCurrentCancellationReason(index);
  };

  const handleClose = () => {
    setAnchorEl(null);
    // setCurrentCategory(-1);
  };

  const openCancellationModal = () => {
    setEditCancellation(true);
  };
  const closeCancellationModal = () => {
    setEditCancellation(false);
  };

  const handletableClick = (index) => {
    setCurrentCancellationReason(index);
    setCancellationView(true);
  };

  return (
    <>
      <Backdrop open={openBackdrop} />

      <ToastContainer limit={1} />

      <div
        style={{
          backgroundColor: "white",
          borderRadius: "10px",
          color: "black",
        }}
      >
        {/* table */}
        <TableContainer
          component={Paper}
          sx={{ minHeight: "395px", overflowY: "scroll" }}
        >
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell className="table-header-font">
                  Type
                </StyledTableCell>
                <StyledTableCell className="table-header-font">
                  For
                </StyledTableCell>
                <StyledTableCell className="table-header-font">
                  Reason
                </StyledTableCell>
                <StyledTableCell className="table-header-font">
                  Date
                </StyledTableCell>
                <StyledTableCell className="table-header-font">
                  Action
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cancellationReasons?.map((row, index) => {
                const { forTypeName, typeName, name, createdAt } = row;
                return (
                  <StyledTableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    // onClick={() => handletableClick(row)}
                    // style={{ cursor: "pointer" }}
                  >
                    <TableCell>{forTypeName}</TableCell>
                    <TableCell>{typeName}</TableCell>
                    <TableCell>{name}</TableCell>
                    <TableCell>
                      {createdAt ? moment(createdAt).format("LLL") : "-"}
                    </TableCell>

                    <TableCell onClick={(e) => e.stopPropagation()}>
                      <IconButton
                        onClick={(e) => {
                          const currentCancellation = cancellationReasons.find(
                            (role) => role.id === row.id
                          );
                          handleClick(e, currentCancellation);
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </TableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Menu
          sx={{ mt: 0.3 }}
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuItem
            sx={{ mt: 1, width: "130px" }}
            onClick={() => {
              openCancellationModal();
            }}
          >
            <ListItemIcon>
              <img src={edit} alt="Edit" />
            </ListItemIcon>
            <ListItemText>Edit</ListItemText>
          </MenuItem>
        </Menu>

        {editCancellation && (
          <AddCancellation
            isEditAction={true}
            open={editCancellation}
            cancellation={currentCancellationReason}
            handleClose={closeCancellationModal}
            refreshScreen={refreshScreen}
          />
        )}
      </div>
      {/* pagination */}
      <Stack
        sx={{ mt: 4, pb: 2 }}
        flexDirection="row"
        justifyContent="space-between"
      >
        <div>
          Showing{" "}
          {page * pagePerSize > totalRecords
            ? totalRecords
            : page * pagePerSize}{" "}
          of {totalRecords} Results
        </div>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
        />
      </Stack>
    </>
  );
}
