import React, { useState } from "react";

import { Button, Stack, Typography, Box } from "@mui/material";
import { Add } from "@mui/icons-material";

import SubCategoryList from "./SubCategoryList";
import AddSubCategoryModal from "./AddSubCategory";

const SubCategoryPage = () => {
  const [refresh, setRefresh] = useState(false);
  const refreshScreen = () => {
    setRefresh(!refresh);
  };
  const [AddSubCategory, setAddSubCategory] = useState(false);
  const openSubCategoryModal = () => {
    setAddSubCategory(true);
  };
  const closeSubCategoryModal = () => {
    setAddSubCategory(false);
  };
  return (
    <>
      <Stack flexDirection="row" justifyContent="space-between">
        <Typography sx={{ fontSize: "30px", fontWeight: "bold" }}>
          Sub Category
        </Typography>
        <Button
          sx={{
            backgroundColor: "#0957DD",
            color: "white",
            borderRadius: "10px",
            padding: "15px 40px",
            "&:hover": {
              backgroundColor: "#0957DD",
            },
          }}
          onClick={openSubCategoryModal}
          startIcon={<Add />}
          color="primary"
        >
          Sub-Category
        </Button>
      </Stack>
      <AddSubCategoryModal
        open={AddSubCategory}
        setOpen={setAddSubCategory}
        handleClickOpen={openSubCategoryModal}
        handleClose={closeSubCategoryModal}
        refreshScreen={refreshScreen}
      />
      <Box sx={{ mt: 4 }}>
        <SubCategoryList refresh={refresh} refreshScreen={refreshScreen} />
      </Box>
    </>
  );
};
export default SubCategoryPage;
