import React, { useState } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";

import UsersList from "./UsersList";
import AddUsers from "./AddUser";

const UsersPage = () => {
  const [refresh, setRefresh] = useState(false);
  const refreshScreen = () => {
    setRefresh(!refresh);
  };
  // const [addUser, setAddUser] = useState(false);
  // const openUserModal = () => {
  //   setAddUser(true);
  // };
  // const closeUserModal = () => {
  //   setAddUser(false);
  // };
  return (
    <>
      <Stack flexDirection="row" justifyContent="space-between">
        <Typography sx={{ fontSize: "30px", fontWeight: "bold" }}>
          Users
        </Typography>
        {/* <Button
          sx={{
            backgroundColor: "#0957DD",
            color: "white",
            borderRadius: "10px",
            padding: "15px 40px",
            "&:hover": {
              backgroundColor: "#0957DD",
            },
          }}
          onClick={openUserModal}
          startIcon={<Add />}
          color="primary"
        >
          Add User
        </Button> */}
      </Stack>
      {/* <AddUsers
        open={addUser}
        setOpen={setAddUser}
        handleClickOpen={openUserModal}
        handleClose={closeUserModal}
        refreshScreen={refreshScreen}
      /> */}
      <Box sx={{ mt: 4 }}>
        <UsersList refresh={refresh} refreshScreen={refreshScreen} />
      </Box>
    </>
  );
};
export default UsersPage;
