import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { styled } from "@mui/material/styles";
import {
  Search as SearchIcon,
  MoreVert as MoreVertIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import {
  InputBase,
  Button,
  Typography,
  IconButton,
  Stack,
  Pagination,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Drawer,
  Divider,
  Box,
  TextField,
  FormLabel,
  InputAdornment,
} from "@mui/material";
import moment from "moment";

import eye from "../../Assets/eye.svg";
import edit from "../../Assets/edit.svg";
import deleteIcon from "../../Assets/delete.svg";
import filterIcon from "../../Assets/filter.svg";

import { useForm } from "../../Utils/useForm";
import { orderListApi } from "../../state/actions/orderAction";
import Backdrop from "../BackDrop/BackDrop";
import "./styles/equipmentdetails.css";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  color: "black",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  borderRadius: "10px",
  backgroundColor: "white",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    paddingRight: "15px",
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: "#0F0F0F80",
}));

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
}));
const StyledTextField = styled(TextField)(() => ({
  marginTop: "10px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
  },
}));
const StyledChip = styled(Chip)(() => ({
  borderRadius: "10px",
  fontSize: "14px",
}));

const StyledRequired = styled("span")({
  color: "red",
  marginLeft: "2px",
  fontSize: "20px",
});

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#0957dd0a",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function OrdersList(props) {
  const navigate = useNavigate();

  const { refresh, refreshScreen } = props;

  const [currentOrder, setCurrentOrder] = useState({});
  const [filter, setFilter] = useState(false);
  const [orders, setOrders] = useState([]);
  const [openBackdrop, setOpenBreakdrop] = useState(false);

  const [page, setPage] = useState(1);
  const [pagePerSize, setPagePerSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [searchValue, setSearchValue] = useState("");

  const getOrderList = async (page) => {
    let queryString =
      "?page=" + page + "&size=" + pagePerSize + "&search=" + searchValue;

    Object.keys(values).map((key) => {
      queryString += "&" + key + "=" + values[key];
    });
    setOpenBreakdrop(true);
    const res = await orderListApi(queryString);
    if (res && res.status) {
      setOrders(res.data);
      setTotalRecords(res.totalRecord);
      setTotalPages(Math.ceil(res.totalRecord / pagePerSize));
    } else {
      setOrders([]);
      setTotalRecords(0);
      setTotalPages(0);
    }
    setOpenBreakdrop(false);
  };

  //  whenever admin list change fetch the list again
  useEffect(() => {
    getOrderList(page);
  }, [refresh]);

  const handleChangePage = (event, value) => {
    setPage(value);
    getOrderList(value);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event, current) => {
    setAnchorEl(event.currentTarget);
    setCurrentOrder(current);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSearch = async (search) => {
    if (search.target.value === "") {
      resetForm();
    }
    setSearchValue(search.target.value);

    let queryString =
      "?page=" + 1 + "&size=" + pagePerSize + "&search=" + search.target.value;

    setOpenBreakdrop(true);
    const res = await orderListApi(queryString);
    if (res && res.status) {
      setOrders(res.data);
      setTotalRecords(res.totalRecord);
      setTotalPages(Math.ceil(res.totalRecord / pagePerSize));
    } else {
      setOrders([]);
      setTotalRecords(0);
      setTotalPages(0);
    }
    setOpenBreakdrop(false);
  };
  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 700);
    };
  };

  const debounceserach = debounce(onSearch);

  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
  };

  const initialValues = {
    orderNumber: "",
    status: "",
    paymentStatus: "",
  };
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);

  const onFilterSubmit = async (e) => {
    if (typeof e != "undefined") {
      e.preventDefault();
    }
    //  we are using initialValues because our values object is not resetting.
    var formValues = typeof e === "undefined" ? initialValues : values;
    let queryString = "?search=" + "&page=" + 1 + "&size=" + pagePerSize;
    Object.keys(formValues).map((key) => {
      queryString += "&" + key + "=" + formValues[key];
    });

    setOpenBreakdrop(true);
    const res = await orderListApi(queryString);
    if (res && res.status) {
      setOrders(res.data);
      setTotalRecords(res.totalRecord);
      setTotalPages(Math.ceil(res.totalRecord / pagePerSize));
    } else {
      setOrders([]);
      setTotalRecords(0);
      setTotalPages(0);
    }
    setOpenBreakdrop(false);
    closeFilterDrawer();
  };

  const getStatus = (status) => {
    const statusColors = {
      1: {
        backgroundColor: "rgba(18, 183, 106, 0.05)",
        color: "#12B76A",
      },

      0: {
        backgroundColor: "rgba(240, 68, 56, 0.05)",
        color: "#F04438;",
      },
    };
    return (
      <StyledChip
        sx={statusColors[status]}
        label={status ? "Active" : "InActive"}
      />
    );
  };

  const statusList = [
    { name: "Pending", value: 1 },
    {
      name: "Cancelled",
      value: 2,
    },
    {
      name: "Delivered",
      value: 3,
    },
    {
      name: "Returned",
      value: 4,
    },
    {
      name: "Completed",
      value: 5,
    },
    {
      name: "Shipped",
      value: 6,
    },
  ];
  const paymentStatusList = [
    {
      label: "Failed",
      id: 0,
    },
    {
      label: "Success",
      id: 1,
    },
  ];
  const getPaymentStatus = (id) => {
    const status = {
      0: {
        label: "Failed",
        stylesheet: {
          backgroundColor: "rgb(240 68 56 / 15%)",
          color: "#e90000",
        },
      },
      1: {
        label: "Success",
        styleSheet: {
          backgroundColor: "#28a74538",
          color: "#28a745",
        },
      },
      2: {
        label: "Pending",
        stylesheet: {
          backgroundColor: "rgb(235 193 14 / 24%)",
          color: "orange",
        },
      },
      3: {
        label: "Declined",
        stylesheet: {
          backgroundColor: "rgb(115 56 240 / 8%)",
          color: "#ffc107;",
        },
      },
    };
    if (status[id] === undefined) return "-";

    const { label, stylesheet } = status[id];

    return <StyledChip sx={stylesheet} label={label} />;
  };
  const getEquipmentStatusStyles = (status) => {
    const list = {
      Pending: {
        color: "orange",
        backgroundColor: "rgb(235 193 14 / 24%)",
      },
      Cancel: {
        color: "#17a2b8",
        backgroundColor: "#dc354517",
      },
      Shipped: {
        color: "blue",
        backgroundColor: "#dee2e6",
      },
      Completed: {
        color: "green",
        backgroundColor: "#dee2e6",
      },
    };

    return <StyledChip sx={list[status]} label={status} />;
  };
  return (
    <>
      <Backdrop open={openBackdrop} />

      <div
        style={{
          backgroundColor: "white",
          borderRadius: "10px",
          color: "black",
        }}
      >
        {/* search bar and filter button */}
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          sx={{ padding: "20px 0px" }}
        >
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              sx={{
                height: "50px",
              }}
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
              onKeyUp={debounceserach}
            />
          </Search>

          <Button
            sx={{
              border: "1px solid rgba(15, 15, 15, 0.15)",
              borderRadius: "10px",
              mr: 2,
              padding: "5px 20px",
              fontWeight: "bold",
              color: "black",
            }}
            endIcon={
              <IconButton sx={{ ml: 3 }}>
                <img src={filterIcon} alt="Filter Options" />
              </IconButton>
            }
            onClick={openFilterDrawer}
          >
            Filter
          </Button>
        </Stack>
        <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
          <form
            onSubmit={onFilterSubmit}
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                // width: 220,
                padding: "10px 20px",
              }}
            >
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography sx={{ fontWeight: "bold" }}>Filter</Typography>
                <IconButton sx={{ color: "black" }} onClick={closeFilterDrawer}>
                  <CloseIcon />
                </IconButton>
              </Stack>
              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="orderNumber">
                  OrderNumber{" "}
                </StyledFormLabel>
                <StyledTextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Order Number"
                  fullWidth
                  id="orderNumber"
                  name="orderNumber"
                  value={values.orderNumber}
                  onChange={handleInputChange}
                />
              </Box>
              <Divider sx={{ mt: 2 }} />
              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="status">Status</StyledFormLabel>
                <StyledTextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search Status"
                  fullWidth
                  id="status"
                  name="status"
                  value={values.status}
                  onChange={handleInputChange}
                  select
                >
                  {statusList.map((value) => {
                    return (
                      <MenuItem
                        key={value.key}
                        value={value.value}
                        sx={{ width: "260px" }}
                      >
                        {value.name}
                      </MenuItem>
                    );
                  })}
                </StyledTextField>
              </Box>
              <Divider sx={{ mt: 2 }} />

              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="paymentStatus">
                  Payment Status
                </StyledFormLabel>
                <StyledTextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search Payment Status"
                  fullWidth
                  id="paymentStatus"
                  name="paymentStatus"
                  value={values.paymentStatus}
                  onChange={handleInputChange}
                  select
                >
                  {paymentStatusList.map((value) => {
                    return (
                      <MenuItem
                        key={value.key}
                        value={value.id}
                        sx={{ width: "260px" }}
                      >
                        {value.label}
                      </MenuItem>
                    );
                  })}
                </StyledTextField>
              </Box>
            </Box>
            <Stack
              sx={{ margin: "80px 20px 25px 20px" }}
              flexDirection="row"
              justifyContent="space-between"
            >
              <Button
                sx={{
                  backgroundColor: "white",
                  color: "black",
                  border: "1px solid #0F0F0F80",
                  borderRadius: "10px",
                  padding: "10px 25px",
                  "&:hover": {
                    // backgroundColor: "#0957DD",
                  },
                }}
                onClick={() => {
                  resetForm();
                  onFilterSubmit();
                }}
              >
                Reset
              </Button>
              <Button
                sx={{
                  backgroundColor: "#0957DD",
                  color: "white",
                  borderRadius: "10px",
                  padding: "10px 25px",
                  "&:hover": {
                    backgroundColor: "#0957DD",
                  },
                }}
                type="submit"
              >
                Filter
              </Button>
            </Stack>
          </form>
        </Drawer>
        <TableContainer
          component={Paper}
          sx={{ minHeight: "300px", overflowY: "scroll" }}
        >
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                {/* <StyledTableCell className="table-header-font">
                  ID
                </StyledTableCell> */}
                <StyledTableCell className="table-header-font">
                  Order Number
                </StyledTableCell>

                <StyledTableCell className="table-header-font">
                  User Name
                </StyledTableCell>
                <StyledTableCell className="table-header-font">
                  Order Qty
                </StyledTableCell>
                <StyledTableCell className="table-header-font">
                  Total Order
                </StyledTableCell>
                <StyledTableCell className="table-header-font">
                  Subtotal
                </StyledTableCell>

                <StyledTableCell className="table-header-font">
                  Status
                </StyledTableCell>
                <StyledTableCell className="table-header-font">
                  Payment Status
                </StyledTableCell>

                <StyledTableCell className="table-header-font">
                  Date
                </StyledTableCell>
                <StyledTableCell className="table-header-font">
                  Action
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders?.map((row, index) => {
                const {
                  orderId,
                  fname,
                  orderNumber,
                  orderQty,
                  orderTotal,
                  orderDiscount,
                  orderSubtotal,
                  status,
                  paymentStatus,
                  createdAt,
                } = row;
                return (
                  <StyledTableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    onClick={() => {
                      navigate("/equipmentsorder/details", {
                        state: { orderId: orderId, parent: "/equipmentsorder" },
                      });
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    {/* <TableCell>{orderId}</TableCell> */}
                    <TableCell className="equipment-list-order-number ">
                      {orderNumber}
                    </TableCell>
                    <TableCell>{fname}</TableCell>
                    <TableCell className="hover-effect">{orderQty}</TableCell>
                    <TableCell>{orderTotal}</TableCell>
                    <TableCell>{orderSubtotal}</TableCell>
                    <TableCell>{getEquipmentStatusStyles(status)}</TableCell>
                    <TableCell>{getPaymentStatus(paymentStatus)}</TableCell>

                    <TableCell>{moment(createdAt).format("LLL")}</TableCell>
                    <TableCell onClick={(e) => e.stopPropagation()}>
                      <IconButton
                        onClick={(e) => {
                          const current = orders.find(
                            (role) => role.id === row.id
                          );

                          handleClick(e, current);
                        }}
                        // style={{ zIndex: "9999" }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </TableCell>
                  </StyledTableRow>
                );
              })}
              {orders?.length === 0 ? (
                <TableRow>
                  <TableCell
                    sx={{
                      color: "silver",
                      textAlign: "center",
                      paddingTop: "90px",
                      borderBottom: "none",
                      fontSize: "30px",
                    }}
                    colSpan="7"
                  >
                    No records to display
                  </TableCell>
                </TableRow>
              ) : (
                ""
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Menu
          sx={{ mt: 0.3 }}
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuItem
            sx={{ mt: 1 }}
            onClick={() => {
              navigate("/equipmentsorder/details", {
                state: {
                  orderId: currentOrder.orderId,
                  parent: "/equipmentsorder",
                },
              });
            }}
          >
            <ListItemIcon>
              <img src={eye} alt="View Details" />
            </ListItemIcon>
            <ListItemText>View Details</ListItemText>
          </MenuItem>
          {/* <MenuItem
            sx={{ mt: 1 }}
            onClick={() => {
              navigate("/equipmentsorder/edit", {
                state: currentOrder.id,
              });
            }}
          >
            <ListItemIcon>
              <img src={edit} alt="Edit" />
            </ListItemIcon>
            <ListItemText>Edit</ListItemText>
          </MenuItem> */}
        </Menu>
      </div>
      {/* pagination */}
      <Stack
        sx={{ mt: 4, pb: 2 }}
        flexDirection="row"
        justifyContent="space-between"
      >
        <div>
          Showing{" "}
          {page * pagePerSize > totalRecords
            ? totalRecords
            : page * pagePerSize}{" "}
          of {totalRecords} Results
        </div>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
        />
      </Stack>
    </>
  );
}
