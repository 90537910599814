import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Box,
  Typography,
  IconButton,
  TextField,
  FormLabel,
  Tooltip,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Close as CloseIcon } from "@mui/icons-material";

import camera from "../../Assets/camera.svg";
import { useForm } from "../../Utils/useForm";

import Backdrop from "../BackDrop/BackDrop";
// import { useDispatch } from "react-redux";

import { addHSNApi } from "../../state/actions/hsnAction";
import { updateHSNApi } from "../../state/actions/hsnAction";

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
}));
const StyledTextField = styled(TextField)(() => ({
  marginTop: "10px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
  },
  ".css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
    padding: "14px",
  },
}));

const StyledRequired = styled("span")({
  color: "red",
  marginLeft: "2px",
  fontSize: "20px",
});

const AddHSN = (props) => {
  const { open, refreshScreen, handleClose, hsn } = props;
  const [isdisabled, setIsDisabled] = useState(false);
  const [openBackdrop, setOpenBreakdrop] = useState(false);

  const reset = () => {
    resetForm();
  };

  const isEditAction = hsn && typeof hsn === "object" ? true : false;
  var initialValues = {
    hsnName: "",
    hsnCode: "",
    taxRatePercentage: "",
    // status: "",
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("hsnName" in fieldValues)
      temp.hsnName = fieldValues.hsnName ? "" : "This field is required.";

    if ("hsnCode" in fieldValues)
      temp.hsnCode = fieldValues.hsnCode ? "" : "Only Numbers are allowed.";
    if ("taxRatePercentage" in fieldValues)
      temp.taxRatePercentage = fieldValues.taxRatePercentage
        ? ""
        : "Only Numbers are allowed.";
    // if (isEditAction) {
    //   if ("status" in fieldValues)
    //     temp.status =
    //       fieldValues.status || fieldValues.status === 0
    //         ? ""
    //         : "This field is required.";
    // }
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  useEffect(() => {
    if (isEditAction) {
      setValues(hsn);
    }
  }, [props]);
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const toastType = {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    };

    if (validate()) {
      // console.log("edit---", hsn.id);

      if (isEditAction) {
        setOpenBreakdrop(true);
        const res = await dispatch(updateHSNApi({ ...values, id: hsn.id }));
        if (res && res.status === true) {
          setOpenBreakdrop(false);
          setIsDisabled(true);
          close();
          toast.success(res.message, toastType);
          refreshScreen();
        } else {
          setOpenBreakdrop(false);
          toast.error(res.message, toastType);
        }
      } else {
        let response = await dispatch(addHSNApi(values));
        console.log("res", response.data);

        if (response && response.status === true) {
          setOpenBreakdrop(false);
          setIsDisabled(true);
          close();
          toast.success(response.message, toastType);
          refreshScreen();
        } else {
          setOpenBreakdrop(false);
          // setIsDisabled(true);
          // close();
          toast.error(response.message, toastType);
        }
      }
    }
  };

  const close = () => {
    reset();
    handleClose();
    setIsDisabled(false);
  };

  return (
    <>
      <Backdrop open={openBackdrop} />

      <Dialog open={open} fullWidth={true}>
        <DialogTitle
          sx={{
            fontWeight: "bold",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {isEditAction ? "Edit" : "Add"} HSN
          <Tooltip title="Close">
            <IconButton onClick={close}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit} id="addNewCategory">
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <StyledFormLabel htmlFor="hsnName">
                  Name
                  {Boolean(errors.hsnName) && (
                    <StyledRequired> * </StyledRequired>
                  )}
                </StyledFormLabel>
                <StyledTextField
                  fullWidth
                  // disabled={isEditAction ? true : false}
                  id="hsnName"
                  name="hsnName"
                  type="text"
                  value={values.hsnName}
                  onChange={handleInputChange}
                  error={Boolean(errors.hsnName)}
                  helperText={errors.hsnName}
                  inputProps={{ maxLength: 80 }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <StyledFormLabel htmlFor="hsnCode">
                  Code
                  {Boolean(errors.hsnCode) && (
                    <StyledRequired> * </StyledRequired>
                  )}
                </StyledFormLabel>
                <StyledTextField
                  // disabled={isEditAction ? true : false}
                  fullWidth
                  id="hsnCode"
                  name="hsnCode"
                  type="number"
                  value={values.hsnCode}
                  onChange={(e) => {
                    const limit = 8;
                    e.target.value = Number(e.target.value.substring(0, limit));
                    handleInputChange(e);
                  }}
                  error={Boolean(errors.hsnCode)}
                  helperText={errors.hsnCode}
                  inputProps={{ min: 0, max: 1000000 }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={1}>
              <Grid item sm={6} xs={12}>
                <StyledFormLabel htmlFor="taxRatePercentage">
                  Rate (%)
                  {Boolean(errors.taxRatePercentage) && (
                    <StyledRequired> * </StyledRequired>
                  )}
                </StyledFormLabel>
                <StyledTextField
                  fullWidth
                  id="taxRatePercentage"
                  name="taxRatePercentage"
                  type="number"
                  value={values.taxRatePercentage}
                  // onChange={handleInputChange}
                  onChange={(e) => {
                    const limit = 3;
                    e.target.value = Number(e.target.value.substring(0, limit));
                    if (e.target.value >= 0 && e.target.value <= 100)
                      handleInputChange(e);
                  }}
                  error={Boolean(errors.taxRatePercentage)}
                  helperText={errors.taxRatePercentage}
                  inputProps={{ min: 0, max: 100 }}
                />
              </Grid>

              {/* {isEditAction && (
                <Grid item sm={6} xs={12}>
                  <StyledFormLabel htmlFor="status">
                    Status
                    {Boolean(errors.status) && (
                      <StyledRequired> * </StyledRequired>
                    )}
                  </StyledFormLabel>
                  <StyledTextField
                    fullWidth
                    id="status"
                    name="status"
                    select
                    value={values.status}
                    onChange={handleInputChange}
                    error={Boolean(errors.status)}
                    helperText={errors.status}
                  >
                    <MenuItem value={0}>Not Available</MenuItem>
                    <MenuItem value={1}>Available</MenuItem>
                  </StyledTextField>
                </Grid>
              )} */}
            </Grid>
          </form>
        </DialogContent>
        <DialogActions sx={{ margin: "15px" }}>
          <Button
            sx={{
              backgroundColor: "white",
              color: "black",
              border: "1px solid #0F0F0F80",
              borderRadius: "10px",
              padding: "10px 25px",
              "&:hover": {},
            }}
            onClick={close}
          >
            Cancel
          </Button>
          <Button
            sx={{
              backgroundColor: "#0957DD",
              color: "white",
              borderRadius: "10px",
              padding: "10px 25px",
              "&:hover": {
                backgroundColor: "#0957DD",
              },
            }}
            disabled={isdisabled}
            type="submit"
            form="addNewCategory"
          >
            {isEditAction ? "Save" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddHSN;
