import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { ToastContainer } from "react-toastify";

import CountryList from "./CountryList";

const Country = () => {
  return (
    <>
      <Stack flexDirection="row" justifyContent="space-between">
        <Typography sx={{ fontSize: "30px", fontWeight: "bold" }}>
          Countries
        </Typography>
      </Stack>

      <Box sx={{ mt: 4 }}>
        <CountryList />
      </Box>
    </>
  );
};
export default Country;
