import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
function PrivateRoute({ children }) {
//    for temporary purpose because we dont have token now

//   const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
//   const token = useSelector((state) => state.auth.token);
  // const token = localStorage.getItem("token");
//  let isAuthenticated = 1;
//   return isAuthenticated ? children : <Navigate to="/login" />;
//   return children


  const token = useSelector((state) => state.auth.token);
  return token ? children : <Navigate to="/login" />;

}

export default PrivateRoute;
