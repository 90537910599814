import handleAuthToken from "../../Utils/handleAuthToken";
import axios from "axios";

export const nurseListApi = async (queryString) => {
  if (localStorage.token) {
    handleAuthToken(localStorage.token);
  }
  try {
    const res = await axios.get(`/admin/av1/listNursing` + queryString);
    return res.data;
  } catch (error) {
    return { success: false, message: error.response.data.message };
  }
};

export const nurseDetailsApi = async (id) => {
  if (localStorage.token) {
    handleAuthToken(localStorage.token);
  }
  try {
    const res = await axios.get(`/admin/av1/nurseDetail/${id}`);
    return res.data;
  } catch (error) {
    return { success: false, message: error.response.data.message };
  }
};

export const saveNurseDetailsApi = async (id,body) => {
  if (localStorage.token) {
    handleAuthToken(localStorage.token);
  }
  try {
    const res = await axios.post(`/admin/av1/UpdateNurseDetail/${id}`,body);
    return res.data;
  } catch (error) {
    return { success: false, message: error.response.data.message };
  }
};


export const addUpdateSubCategoryApi = async (id, data) => {
  if (localStorage.token) {
    handleAuthToken(localStorage.token);
  }
  try {
    const res = await axios.post(`/admin/av1/addUpdateSubcategory/${id}`, data);
    return res.data;
  } catch (error) {
    return { success: false, message: error.response.data.message };
  }
};

export const deleteNurseApi = async (id) => {
  if (localStorage.token) {
    handleAuthToken(localStorage.token);
  }
  try {
    const res = await axios.get(`admin/av1/deleteUserAdmin/${id}`);
    return res.data;
  } catch (error) {
    return { success: false, message: error.response.data.message };
  }
};
