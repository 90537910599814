import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";

import { styled } from "@mui/material/styles";
import {
  Search as SearchIcon,
  MoreVert as MoreVertIcon,
  Close as CloseIcon,
  Person as PersonIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
} from "@mui/icons-material";
import {
  InputBase,
  Button,
  Typography,
  IconButton,
  Stack,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Drawer,
  Divider,
  Box,
  TextField,
  FormLabel,
  InputAdornment,
  Avatar,
  Pagination,
} from "@mui/material";
import moment from "moment";

import filterIcon from "../../Assets/filter.svg";
import NurseDetailsModal from "./CaregiverDetails";
import AddNurse from "./AddCaregiver";
import {
  careGiverListApi,
  deleteCareGiverApi,
} from "../../state/actions/caregiverAction";
import { useForm } from "../../Utils/useForm";
import { getBase64FromUrl } from "../../Utils/getBase64FromUrl";
import Backdrop from "../BackDrop/BackDrop";
import DeleteComponent from "../DeleteComponent/DeleteComponent";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  color: "black",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  borderRadius: "10px",
  backgroundColor: "white",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    paddingRight: "15px",
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: "#0F0F0F80",
}));

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
}));
const StyledTextField = styled(TextField)(() => ({
  marginTop: "10px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
  },
}));
const StyledChip = styled(Chip)(() => ({
  borderRadius: "10px",
  fontSize: "14px",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#0957dd0a",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function UsersList(props) {
  const { refresh, refreshScreen } = props;
  const [caregiverList, setCaregiverList] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentCaregiver, setCurrentCaregiver] = useState(-1);
  const [caregiverView, setCaregiverView] = useState(false);

  const [deleteValue, setDeleteValue] = useState(false);
  const [addCaregiver, setAddCaregiver] = useState(false);
  const [openBackdrop, setOpenBreakdrop] = useState(false);

  const [page, setPage] = useState(1);
  const [pagePerSize, setPagePerSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [searchValue, setSearchValue] = useState("");

  const handleChangePage = (event, value) => {
    setPage(value);
    getCaregiverList(value);
  };

  async function getCaregiverList(page) {
    let queryString =
      "?page=" + page + "&size=" + pagePerSize + "&search=" + searchValue;

    Object.keys(values).map((key) => {
      queryString += "&" + key + "=" + values[key];
    });

    setOpenBreakdrop(true);

    const res = await careGiverListApi(queryString);
    if (res && res.status) {
      setCaregiverList(res.data);
      setTotalRecords(res.totalRecord);
      setTotalPages(Math.ceil(res.totalRecord / pagePerSize));
    } else {
      setCaregiverList([]);
      setTotalRecords(0);
      setTotalPages(0);
    }
    setOpenBreakdrop(false);
  }
  // api call for get a Caregiver-list
  useEffect(() => {
    getCaregiverList(page);
  }, [refresh]);

  const open = Boolean(anchorEl);
  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setCurrentCaregiver(index);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSearch = async (search) => {
    if (search.target.value === "") {
      resetForm();
    }
    setSearchValue(search.target.value);

    let queryString =
      "?page=" + 1 + "&size=" + pagePerSize + "&search=" + search.target.value;

    setOpenBreakdrop(true);
    const res = await careGiverListApi(queryString);
    if (res && res.status) {
      setCaregiverList(res.data);
      setTotalRecords(res.totalRecord);
      setTotalPages(Math.ceil(res.totalRecord / pagePerSize));
    } else {
      setCaregiverList([]);
      setTotalRecords(0);
      setTotalPages(0);
    }
    setOpenBreakdrop(false);
  };
  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 700);
    };
  };

  const debounceserach = debounce(onSearch);

  const initialValues = {
    name: "",
    email: "",
    mobile: "",
    status: "",
  };
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);

  const [filter, setFilter] = useState(false);
  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
  };

  const onFilterSubmit = async (e) => {
    if (typeof e != "undefined") {
      e.preventDefault();
    }
    //  we are using initialValues because our values object is not resetting.
    var formValues = typeof e === "undefined" ? initialValues : values;
    let queryString = "?search=" + "&page=" + 1 + "&size=" + pagePerSize;
    Object.keys(formValues).map((key) => {
      queryString += "&" + key + "=" + formValues[key];
    });

    setOpenBreakdrop(true);
    const res = await careGiverListApi(queryString);
    if (res && res.status) {
      setCaregiverList(res.data);
      setTotalRecords(res.totalRecord);
      setTotalPages(Math.ceil(res.totalRecord / pagePerSize));
    } else {
      setCaregiverList([]);
      setTotalRecords(0);
      setTotalPages(0);
    }
    setOpenBreakdrop(false);
    closeFilterDrawer();
  };

  const handleCaregiverView = () => {
    setCaregiverView(true);
  };
  const closeCaregiverView = () => {
    setCaregiverView(false);
  };

  const handledelete = () => {
    setDeleteValue(true);
  };
  const closedelete = () => {
    setDeleteValue(false);
  };

  const getStatus = (value) => {
    const status = {
      0: {
        title: "Pending",
        styles: {
          ml: 2,
          backgroundColor: "rgb(235 193 14 / 24%)",
          color: "orange",
        },
      },
      1: {
        title: "Active",
        styles: {
          ml: 2,
          backgroundColor: "rgba(18, 183, 106, 0.05)",
          color: "#12B76A",
        },
      },
      2: {
        title: "Rejected",
        styles: {
          ml: 2,
          backgroundColor: "rgba(240, 68, 56, 0.05)",
          color: "#F04438;",
        },
      },
      3: {
        title: "Delete",
        styles: {
          ml: 2,
          backgroundColor: "rgba(240, 68, 56, 0.05)",
          color: "#F04438",
        },
      },
    };
    return status[value];
  };
  const statusList = [
    {
      name: "Pending",
      value: 0,
    },
    {
      name: "Active",
      value: 1,
    },
    {
      name: "Rejected",
      value: 2,
    },
    {
      name: "Delete",
      value: 3,
    },
  ];

  const isProfileImageValid = (profile) => {
    return profile && !profile.includes("null") && profile.includes("https")
      ? true
      : false;
  };

  const handletableClick = (index) => {
    setCurrentCaregiver(index);
    setCaregiverView(true);
  };

  const onSubmit = async () => {
    const toastType = {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    };

    console.log("deleted caregiver id", currentCaregiver);
    const id = currentCaregiver.id;
    setOpenBreakdrop(true);
    const res = await deleteCareGiverApi(id);
    if (res && res.status) {
      toast.success(res.message, toastType);
    } else {
      toast.error(res.message, toastType);
    }
    setOpenBreakdrop(false);
    closedelete();
    refreshScreen();
  };

  return (
    <>
      <Backdrop open={openBackdrop} />
      <ToastContainer limit={1} />

      <div
        style={{
          backgroundColor: "white",
          borderRadius: "10px",
          color: "black",
        }}
      >
        {/* search bar and filter button */}
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          sx={{ padding: "20px 0px" }}
        >
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              sx={{
                height: "50px",
              }}
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
              onKeyUp={debounceserach}
            />
          </Search>

          <Button
            sx={{
              border: "1px solid rgba(15, 15, 15, 0.15)",
              borderRadius: "10px",
              mr: 2,
              padding: "5px 20px",
              fontWeight: "bold",
              color: "black",
            }}
            endIcon={
              <IconButton sx={{ ml: 3 }}>
                <img src={filterIcon} alt="Filter Options" />
              </IconButton>
            }
            onClick={openFilterDrawer}
          >
            Filter
          </Button>
        </Stack>
        {/* drawer */}
        <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
          <form
            onSubmit={onFilterSubmit}
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                // width: 220,
                padding: "10px 20px",
              }}
            >
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography sx={{ fontWeight: "bold" }}>Filter</Typography>
                <IconButton sx={{ color: "black" }} onClick={closeFilterDrawer}>
                  <CloseIcon />
                </IconButton>
              </Stack>

              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="name">Name </StyledFormLabel>
                <StyledTextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search Name"
                  fullWidth
                  id="name"
                  name="name"
                  value={values.name}
                  onChange={handleInputChange}
                />
              </Box>

              <Divider sx={{ mt: 2 }} />
              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="email">Email </StyledFormLabel>
                <StyledTextField
                  type="email"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search Email"
                  fullWidth
                  id="email"
                  name="email"
                  value={values.email}
                  onChange={handleInputChange}
                />
              </Box>
              <Divider sx={{ mt: 2 }} />

              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="mobile">
                  Mobile Number{" "}
                </StyledFormLabel>
                <StyledTextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Mobile Number"
                  fullWidth
                  id="mobile"
                  name="mobile"
                  type="number"
                  value={values.mobile}
                  onChange={(e) => {
                    const limit = 10;
                    e.target.value = Number(e.target.value.substring(0, limit));
                    handleInputChange(e);
                  }}
                />
              </Box>
              <Divider sx={{ mt: 2 }} />
              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="status">Status</StyledFormLabel>
                <StyledTextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Status"
                  fullWidth
                  id="status"
                  name="status"
                  value={values.status}
                  select
                  onChange={handleInputChange}
                >
                  {statusList.map((list, index) => {
                    const { name, value } = list;
                    return (
                      <MenuItem
                        sx={{ width: "250px" }}
                        value={value}
                        key={index}
                      >
                        {name}
                      </MenuItem>
                    );
                  })}
                </StyledTextField>
              </Box>
              <Divider sx={{ mt: 2 }} />
            </Box>
            <Stack
              sx={{ margin: "50px 20px 25px 20px" }}
              flexDirection="row"
              justifyContent="space-between"
            >
              <Button
                sx={{
                  backgroundColor: "white",
                  color: "black",
                  border: "1px solid #0F0F0F80",
                  borderRadius: "10px",
                  padding: "10px 25px",
                  "&:hover": {
                    // backgroundColor: "#0957DD",
                  },
                }}
                onClick={() => {
                  resetForm();
                  onFilterSubmit();
                }}
              >
                Reset
              </Button>
              <Button
                sx={{
                  backgroundColor: "#0957DD",
                  color: "white",
                  borderRadius: "10px",
                  padding: "10px 25px",
                  "&:hover": {
                    backgroundColor: "#0957DD",
                  },
                }}
                type="submit"
              >
                Filter
              </Button>
            </Stack>
          </form>
        </Drawer>
        {/* table */}
        <TableContainer
          component={Paper}
          sx={{ minHeight: "300px", overflowY: "scroll" }}
        >
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell className="table-header-font">
                  Name
                </StyledTableCell>
                <StyledTableCell className="table-header-font">
                  Email
                </StyledTableCell>
                <StyledTableCell className="table-header-font">
                  Mobile
                </StyledTableCell>
                <StyledTableCell className="table-header-font">
                  Status
                </StyledTableCell>
                <StyledTableCell className="table-header-font">
                  Date
                </StyledTableCell>
                <StyledTableCell className="table-header-font">
                  Action
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {caregiverList?.map((row, index) => {
                var {
                  fname,
                  profile_image,
                  email,
                  mobile,
                  createdAt,
                  isActive,
                } = row;
                var name = fname ? fname : "-";

                email = email ? email : "-";
                var status = getStatus(isActive);
                return (
                  <StyledTableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    onClick={(e) => handletableClick(row)}
                    style={{ cursor: "pointer" }}
                  >
                    <TableCell component="th" scope="row">
                      <Stack flexDirection="row" alignItems="center">
                        {isProfileImageValid(profile_image) ? (
                          <img
                            style={{
                              width: "40px",
                              height: "40px",
                              borderRadius: "50%",
                            }}
                            sx={{ mx: 0.5 }}
                            alt="User Profile"
                            src={profile_image}
                          />
                        ) : (
                          <Avatar>
                            <PersonIcon />
                          </Avatar>
                        )}
                        <Typography sx={{ ml: 2 }}>
                          {name?.length > 15
                            ? name.substr(0, 15) + "..."
                            : name}
                        </Typography>
                      </Stack>
                    </TableCell>
                    <TableCell>{email}</TableCell>
                    <TableCell>{mobile}</TableCell>
                    <TableCell>
                      <StyledChip label={status?.title} sx={status?.styles} />
                    </TableCell>
                    <TableCell>{moment(createdAt).format("LLL")}</TableCell>
                    <TableCell>
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          const currentCaregiver = caregiverList.find(
                            (role) => role.id === row.id
                          );
                          handleClick(e, currentCaregiver);
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </TableCell>
                  </StyledTableRow>
                );
              })}
              {caregiverList?.length === 0 ? (
                <TableRow>
                  <TableCell
                    sx={{
                      color: "silver",
                      textAlign: "center",
                      paddingTop: "90px",
                      borderBottom: "none",
                      fontSize: "30px",
                    }}
                    colSpan="7"
                  >
                    No records to display
                  </TableCell>
                </TableRow>
              ) : (
                ""
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Menu
          sx={{ mt: 0.3 }}
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuItem
            sx={{ mt: 1 }}
            onClick={() => {
              handleCaregiverView();
              handleClose();
            }}
          >
            <ListItemIcon>
              <VisibilityIcon />
            </ListItemIcon>
            <ListItemText>View Details</ListItemText>
          </MenuItem>
          <MenuItem
            sx={{ mt: 1 }}
            onClick={() => {
              handledelete();
              handleClose();
            }}
          >
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            <ListItemText>Delete</ListItemText>
          </MenuItem>
        </Menu>
        {caregiverView && (
          <NurseDetailsModal
            id={currentCaregiver?.id}
            open={caregiverView}
            handleClose={closeCaregiverView}
            refreshScreen={refreshScreen}
          />
        )}
        {deleteValue && (
          <DeleteComponent
            open={deleteValue}
            handleClose={closedelete}
            onSubmit={onSubmit}
          />
        )}
      </div>
      {/* pagination */}
      <Stack
        sx={{ mt: 4, pb: 2 }}
        flexDirection="row"
        justifyContent="space-between"
      >
        <div>
          Showing{" "}
          {page * pagePerSize > totalRecords
            ? totalRecords
            : page * pagePerSize}{" "}
          of {totalRecords} Results
        </div>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
        />
      </Stack>
    </>
  );
}
