import handleAuthToken from "../../Utils/handleAuthToken";
import axios from "axios";

export const  getPaymentListApi = async (queryString) => {
  if (localStorage.token) {
    handleAuthToken(localStorage.token);
  }
  try {
    const res = await axios.get(`/admin/av1/paymentListEquipment` + queryString);
    return res.data;
  } catch (error) {
    return { success: false, message: error.response.data.message };
  }
};

