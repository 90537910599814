import handleAuthToken from "../../Utils/handleAuthToken";
import axios from "axios";

export const usersListApi = async (queryString) => {
  if (localStorage.token) {
    handleAuthToken(localStorage.token);
  }
  try {
    const res = await axios.get(`/admin/av1/listUser` + queryString);
    return res.data;
  } catch (error) {
    return { success: false, message: error.response.data.message };
  }
};

export const editUserStatusApi = async (id, formdata) => {
  if (localStorage.token) {
    handleAuthToken(localStorage.token);
  }

  try {
    const res = await axios.post(
      `/admin/av1/statusUpdateActive/${id}`,
      formdata
    );
    return res.data;
  } catch (error) {
    return { success: false, message: error.response.data.message };
  }
};

export const userFamilyMemberApi = async (id) => {
  if (localStorage.token) {
    handleAuthToken(localStorage.token);
  }

  try {
    const res = await axios.get(`/admin/av1/userMemberListAdmin/${id}`);
    return res.data;
  } catch (error) {
    return { success: false, message: error.response.data.message };
  }
};

export const deleteUserApi = async (id) => {
  if (localStorage.token) {
    handleAuthToken(localStorage.token);
  }
  try {
    const res = await axios.get(`admin/av1/deleteUserAdmin/${id}`);
    return res.data;
  } catch (error) {
    return { success: false, message: error.response.data.message };
  }
};
