import React, { useEffect, useState } from "react";
import {
  Avatar,
  Card,
  Chip,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  MoreVert as MoreVertIcon,
  Person as PersonIcon,
} from "@mui/icons-material";
import { styled } from "@mui/system";
import Line from "./LineChart";
import PieChart from "./PieChart";
import { getUserCount } from "../../state/actions/dashboardAction";
import { usersListApi } from "../../state/actions/usersAction";
import UsersDetailsModal from "../Users/UsersDetails";
import eye from "../../Assets/eye.svg";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../../node_modules/bootstrap/dist/js/bootstrap.min.js";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { getBookingListApi } from "../../state/actions/nursesAction";
import { orderListApi } from "../../state/actions/orderAction";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: "#0F0F0F80",
}));

const StyledChip = styled(Chip)(() => ({
  borderRadius: "10px",
  fontSize: "14px",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#0957dd0a",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function Dashboard() {
  const navigate = useNavigate();

  const [userCounts, setUserCounts] = useState({
    activeCaregiver: 0,
    totalCaregiver: 0,
    totalNurse: 0,
    activeNurse: 0,
    totalUser: 0,
    activeUser: 0,
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [nurseAnchorEl, setNurseAnchorEl] = useState(null);
  const [equipmentAnchorEl, setEquipmentAnchorEl] = useState(null);
  const [currentUser, setCurrentUser] = useState(-1);
  const [users, setUsers] = useState([]);
  const [userView, setUserView] = useState(false);
  const [nurses, setNurses] = useState([]);
  const [currentNurse, setCurrentNurse] = useState({});
  const [orders, setOrders] = useState([]);
  const [currentOrder, setCurrentOrder] = useState({});
  const [refresh, setRefresh] = useState(false);
  const refreshScreen = () => {
    setRefresh(!refresh);
  };

  const getNursesList = async () => {
    let queryString = "?page=1&size=5";
    const res = await getBookingListApi(queryString);
    if (res && res.status) {
      setNurses(res.data);
    } else {
      setNurses([]);
    }
  };

  const getOrderList = async () => {
    let queryString = "?page=1&size=5";
    const res = await orderListApi(queryString);
    if (res && res.status) {
      setOrders(res.data);
    } else {
      setOrders([]);
    }
  };

  const getUserCounts = async () => {
    const countData = await getUserCount();
    if (countData && countData.status) {
      setUserCounts(countData.data);
    }
  };

  const getUsers = async () => {
    let queryString = "?page=1&size=5";
    const res = await usersListApi(queryString);
    if (res && res.status) {
      setUsers(res.data);
    } else {
      setUsers([]);
    }
  };

  const getStatus = (status) => {
    const statusColors = {
      1: {
        backgroundColor: "rgba(18, 183, 106, 0.05)",
        color: "#12B76A",
        label: "Active",
      },

      2: {
        backgroundColor: "rgba(240, 68, 56, 0.05)",
        color: "#F04438;",
        label: "InActive",
      },
      // not a convenient way
      3: {
        backgroundColor: "rgba(240, 68, 56, 0.05)",
        color: "#F04438;",
        label: "Deleted",
      },
    };

    return (
      <StyledChip
        sx={statusColors[status]}
        label={statusColors[status].label}
      />
    );
  };

  const open = Boolean(anchorEl);
  const openNurse = Boolean(nurseAnchorEl);
  const equipmentopen = Boolean(equipmentAnchorEl);

  const isProfileImageValid = (profile) => {
    return profile && !profile.includes("null") && profile.includes("https")
      ? true
      : false;
  };

  const handleUserView = () => {
    setUserView(true);
  };

  const closeUserView = () => {
    setUserView(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setNurseAnchorEl(null);
    setEquipmentAnchorEl(null);
  };

  const handleEquiomentClick = (event, current) => {
    setEquipmentAnchorEl(event.currentTarget);
    setCurrentOrder(current);
  };

  const handleClick = (event, currentUser) => {
    setAnchorEl(event.currentTarget);
    setCurrentUser(currentUser);
  };

  const handleNurseClick = (event, currentUser) => {
    setNurseAnchorEl(event.currentTarget);
    setCurrentNurse(currentUser);
  };
  const getPaymentStatus = (id) => {
    const status = {
      0: {
        label: "Failed",
        styleSheet: {
          backgroundColor: "rgb(240 68 56 / 15%)",
          color: "#e90000",
        },
      },
      1: {
        label: "Success",
        styleSheet: {
          backgroundColor: "#28a74538",
          color: "#28a745",
        },
      },
      2: {
        label: "Pending",
        styleSheet: {
          backgroundColor: "rgb(235 193 14 / 24%)",
          color: "orange",
        },
      },
      3: {
        label: "Declined",
        styleSheet: {
          backgroundColor: "rgb(115 56 240 / 8%)",
          color: "#ffc107;",
        },
      },
    };
    if (status[id] === undefined) return "-";

    const { label, styleSheet } = status[id];

    return <StyledChip sx={styleSheet} label={label} />;
  };
  const getOrderStatus = (status) => {
    const statusColors = {
      1: {
        styles: {
          color: "#12B76A",
        },
        title: "Alloted",
      },

      2: {
        styles: {
          color: "orange",
          backgroundColor: "rgb(235 193 14 / 24%)",
        },
        title: "Pending",
      },
      3: {
        styles: {
          color: "#F04438;",
        },
        title: "Canceled",
      },
      4: {
        styles: {
          color: "#F04438;",
        },
        title: "Completed",
      },
    };
    return (
      <StyledChip
        sx={statusColors[status]?.styles}
        label={statusColors[status]?.title}
      />
    );
  };

  useEffect(() => {
    getNursesList();
    getOrderList();
    getUserCounts();
  }, []);

  useEffect(() => {
    getUsers();
  }, [refresh]);

  const getEquipmentStatusStyles = (status) => {
    const list = {
      Pending: {
        color: "orange",
        backgroundColor: "rgb(235 193 14 / 24%)",
      },
      Cancel: {
        color: "#17a2b8",
        backgroundColor: "#dc354517",
      },
      Shipped: {
        color: "blue",
        backgroundColor: "#dee2e6",
      },
      Completed: {
        color: "green",
        backgroundColor: "#dee2e6",
      },
    };

    return <StyledChip sx={list[status]} label={status} />;
  };
  return (
    <>
      <Grid container spacing={3} mb={4}>
        <Grid item lg={4} md={6} xs={12}>
          <Card
            sx={{ pt: 3 }}
            style={{
              background:
                "conic-gradient(from 210deg at 58% 5%, #ffffff, #ffffff, #ffffff, #ffffff, #ffffff, #ffffff, #0957dd, #0957dd, #0957dd, #0957dd, #0957dd, #0957dd)",
              borderRadius: "10px",
            }}
          >
            <div sx={{ pt: 1 }}>
              <p
                sx={{ mb: "8px" }}
                style={{ display: "flex", justifyContent: "space-around" }}
              >
                <b>Active User</b>
                <b style={{ color: "#F0f0f0 " }}>Total User</b>
              </p>
              <h3 style={{ display: "flex", justifyContent: "space-around" }}>
                <b>{userCounts.activeUser}</b>
                <b style={{ color: "#F0f0f0 " }}>{userCounts.totalUser} </b>
              </h3>
            </div>
          </Card>
        </Grid>
        <Grid item lg={4} md={6} xs={12}>
          <Card
            sx={{ pt: 3 }}
            style={{
              background:
                "conic-gradient(from 210deg at 58% 5%, #ffffff, #ffffff, #ffffff, #ffffff, #ffffff, #ffffff, #0957dd, #0957dd, #0957dd, #0957dd, #0957dd, #0957dd)",
              borderRadius: "10px",
            }}
          >
            <div sx={{ pt: 1 }}>
              <p
                sx={{ mb: "8px" }}
                style={{ display: "flex", justifyContent: "space-around" }}
              >
                <b>Active Nurse</b>
                <b style={{ color: "#F0f0f0 " }}>Total Nurse</b>
              </p>
              <h3 style={{ display: "flex", justifyContent: "space-around" }}>
                <b>{userCounts.activeNurse} </b>
                <b style={{ color: "#F0f0f0 " }}>{userCounts.totalNurse} </b>
              </h3>
            </div>
          </Card>
        </Grid>
        <Grid item lg={4} md={6} xs={12}>
          <Card
            sx={{ pt: 3 }}
            style={{
              background:
                "conic-gradient(from 210deg at 58% 5%, #ffffff, #ffffff, #ffffff, #ffffff, #ffffff, #ffffff, #0957dd, #0957dd, #0957dd, #0957dd, #0957dd, #0957dd)",
              borderRadius: "10px",
            }}
          >
            <div sx={{ pt: 1 }}>
              <p
                sx={{ mb: "8px" }}
                style={{ display: "flex", justifyContent: "space-around" }}
              >
                <b>Active Caregiver</b>
                <b style={{ color: "#F0f0f0 " }}>Total Caregiver</b>
              </p>
              <h3 style={{ display: "flex", justifyContent: "space-around" }}>
                <b>{userCounts.activeCaregiver}</b>
                <b style={{ color: "#F0f0f0 " }}>
                  {userCounts.totalCaregiver}{" "}
                </b>
              </h3>
            </div>
          </Card>
        </Grid>
        <Grid item lg={4} md={6} xs={12}>
          <Card
            sx={{ pt: 3 }}
            style={{
              background:
                "conic-gradient(from 210deg at 58% 5%, #ffffff, #ffffff, #ffffff, #ffffff, #ffffff, #ffffff, #0957dd, #0957dd, #0957dd, #0957dd, #0957dd, #0957dd)",
              borderRadius: "10px",
            }}
          >
            <div sx={{ pt: 1 }}>
              <p
                sx={{ mb: "8px" }}
                style={{ display: "flex", justifyContent: "space-around" }}
              >
                <b>Pending Nurse Orders</b>
                <b style={{ color: "#F0f0f0 " }}>Total Nurse Orders</b>
              </p>
              <h3 style={{ display: "flex", justifyContent: "space-around" }}>
                <b>{userCounts.pendingNurseOrders}</b>
                <b style={{ color: "#F0f0f0 " }}>
                  {userCounts.totalNurseOrders}{" "}
                </b>
              </h3>
            </div>
          </Card>
        </Grid>
        <Grid item lg={4} md={6} xs={12}>
          <Card
            sx={{ pt: 3 }}
            style={{
              background:
                "conic-gradient(from 210deg at 58% 5%, #ffffff, #ffffff, #ffffff, #ffffff, #ffffff, #ffffff, #0957dd, #0957dd, #0957dd, #0957dd, #0957dd, #0957dd)",
              borderRadius: "10px",
            }}
          >
            <div sx={{ pt: 1 }}>
              <p
                sx={{ mb: "8px" }}
                style={{ display: "flex", justifyContent: "space-around" }}
              >
                <b>Pending Equipment Orders</b>
                <b style={{ color: "#F0f0f0 " }}>Total Equipment Orders</b>
              </p>
              <h3 style={{ display: "flex", justifyContent: "space-around" }}>
                <b>{userCounts.pendingEquipmentOrders}</b>
                <b style={{ color: "#F0f0f0 " }}>
                  {userCounts.totalEquipmentOrders}{" "}
                </b>
              </h3>
            </div>
          </Card>
        </Grid>
        <Grid item lg={4} md={6} xs={12}>
          <Card
            sx={{ pt: 3 }}
            style={{
              background:
                "conic-gradient(from 210deg at 58% 5%, #ffffff, #ffffff, #ffffff, #ffffff, #ffffff, #ffffff, #0957dd, #0957dd, #0957dd, #0957dd, #0957dd, #0957dd)",
              borderRadius: "10px",
            }}
          >
            <div sx={{ pt: 1 }}>
              <p
                sx={{ mb: "8px" }}
                style={{ display: "flex", justifyContent: "space-around" }}
              >
                <b>Pending Caregiver Orders</b>
                <b style={{ color: "#F0f0f0 " }}>Total Caregiver Orders</b>
              </p>
              <h3 style={{ display: "flex", justifyContent: "space-around" }}>
                <b>{userCounts.pendingCaregiverOrders}</b>
                <b style={{ color: "#F0f0f0 " }}>
                  {userCounts.totalCaregiverOrders}{" "}
                </b>
              </h3>
            </div>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={3} mb={4}>
        <Grid item lg={6} md={6} xs={12}>
          <Card sx={{ py: 3 }} elevation={3}>
            <h4 style={{ marginLeft: "5%" }}>User Bookings</h4>
            <Line
              graphData={userCounts.bookingGraphPerMonth}
              graphMonthData={userCounts.bookingGraphPerMonth2}
            />
          </Card>
        </Grid>
        <Grid item lg={6} md={6} xs={12}>
          <Card sx={{ py: 2.8 }} elevation={3}>
            <h4 style={{ marginLeft: "5%" }}>Register Users</h4>
            <PieChart graphUserData={userCounts.userGraph} />
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={3} mb={4}>
        <Grid item lg={12} md={12} xs={12}>
          <div
            style={{
              backgroundColor: "white",
              borderRadius: "10px",
              color: "black",
            }}
          >
            <h4 style={{ padding: "20px 0px 0px 10px" }}>
              Latest Nurse Orders
            </h4>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              sx={{ padding: "20px 0px" }}
            >
              <TableContainer
                component={Paper}
                sx={{ minHeight: "370px", overflowY: "scroll" }}
              >
                <Table stickyHeader aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell className="table-header-font">
                        Id
                      </StyledTableCell>
                      <StyledTableCell className="table-header-font">
                        Code
                      </StyledTableCell>
                      <StyledTableCell className="table-header-font">
                        Category Name
                      </StyledTableCell>
                      <StyledTableCell className="table-header-font">
                        Customer Name
                      </StyledTableCell>
                      <StyledTableCell className="table-header-font">
                        Nurse Name
                      </StyledTableCell>
                      <StyledTableCell className="table-header-font">
                        Booking Subtotal
                      </StyledTableCell>
                      <StyledTableCell className="table-header-font">
                        Payment Status
                      </StyledTableCell>
                      <StyledTableCell className="table-header-font">
                        Order Status
                      </StyledTableCell>
                      <StyledTableCell className="table-header-font">
                        Date
                      </StyledTableCell>
                      <StyledTableCell className="table-header-font">
                        Action
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {nurses?.map((row, index) => {
                      var {
                        bookingId,
                        bookingNumber,
                        categoryName,
                        userNameFname,
                        userNameLname,
                        userNurseFname,
                        userNurseLname,
                        bookingSubtotal,
                        paymentStatus,
                        status,
                        createdAtbooking,
                      } = row;
                      var nurseName = userNurseFname;
                      categoryName = categoryName === "" ? "-" : categoryName;
                      nurseName = nurseName === " " ? "-" : nurseName;
                      paymentStatus =
                        paymentStatus === null ? "-" : paymentStatus;
                      status = status === " " ? 2 : status;
                      return (
                        <StyledTableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                          onClick={() => {
                            navigate("/nurses/details", {
                              state: {
                                orderId: row.bookingId,
                                parent: "/dashboard",
                              },
                            });
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <TableCell>{index + 1}</TableCell>
                          <TableCell className="equipment-list-order-number">
                            {bookingNumber}
                          </TableCell>
                          <TableCell>{categoryName}</TableCell>
                          <TableCell>
                            {userNameFname} {userNameLname}
                          </TableCell>
                          <TableCell>{nurseName}</TableCell>
                          <TableCell>{bookingSubtotal}</TableCell>
                          <TableCell>
                            {getPaymentStatus(paymentStatus)}
                          </TableCell>
                          <TableCell>{getOrderStatus(status)}</TableCell>

                          <TableCell>
                            {moment(createdAtbooking).format("LLL")}
                          </TableCell>
                          <TableCell onClick={(e) => e.stopPropagation()}>
                            <IconButton
                              onClick={(e) => {
                                const current = nurses.find(
                                  (role) => role.bookingId === row.bookingId
                                );

                                handleNurseClick(e, current);
                              }}
                            >
                              <MoreVertIcon />
                            </IconButton>
                          </TableCell>
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Menu
                sx={{ mt: 0.3 }}
                id="basic-menu"
                anchorEl={nurseAnchorEl}
                open={openNurse}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <MenuItem
                  sx={{ mt: 1 }}
                  onClick={() => {
                    navigate("/nurses/details", {
                      state: {
                        orderId: currentNurse.bookingId,
                        parent: "/dashboard",
                      },
                    });
                  }}
                >
                  <ListItemIcon>
                    <img src={eye} alt="View Details" />
                  </ListItemIcon>
                  <ListItemText>View Details</ListItemText>
                </MenuItem>
              </Menu>
            </Stack>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={3} mb={4}>
        <Grid item lg={12} md={12} xs={12}>
          <div
            style={{
              backgroundColor: "white",
              borderRadius: "10px",
              color: "black",
            }}
          >
            <h4 style={{ padding: "20px 0px 0px 10px" }}>
              Latest Equipment Orders
            </h4>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              sx={{ padding: "20px 0px" }}
            >
              <TableContainer
                component={Paper}
                sx={{ minHeight: "370px", overflowY: "scroll" }}
              >
                <Table stickyHeader aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell className="table-header-font">
                        ID
                      </StyledTableCell>
                      <StyledTableCell className="table-header-font">
                        Order Number
                      </StyledTableCell>

                      <StyledTableCell className="table-header-font">
                        User Name
                      </StyledTableCell>
                      <StyledTableCell className="table-header-font">
                        Order Qty
                      </StyledTableCell>
                      <StyledTableCell className="table-header-font">
                        Total Order
                      </StyledTableCell>
                      <StyledTableCell className="table-header-font">
                        Subtotal
                      </StyledTableCell>

                      <StyledTableCell className="table-header-font">
                        Status
                      </StyledTableCell>
                      <StyledTableCell className="table-header-font">
                        Payment Status
                      </StyledTableCell>

                      <StyledTableCell className="table-header-font">
                        Date
                      </StyledTableCell>
                      <StyledTableCell className="table-header-font">
                        Action
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orders?.map((row, index) => {
                      const {
                        orderNumber,
                        fname,
                        orderQty,
                        orderTotal,
                        orderSubtotal,
                        status,
                        paymentStatus,
                        createdAt,
                      } = row;
                      return (
                        <StyledTableRow
                          key={row.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                          onClick={() => {
                            navigate("/equipmentsorder/details", {
                              state: {
                                orderId: row.orderId,
                                parent: "/dashboard",
                              },
                            });
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <TableCell>{index + 1}</TableCell>
                          <TableCell className="equipment-list-order-number ">
                            {orderNumber}
                          </TableCell>
                          <TableCell>{fname}</TableCell>
                          <TableCell className="hover-effect">
                            {orderQty}
                          </TableCell>
                          <TableCell>{orderTotal}</TableCell>
                          <TableCell>{orderSubtotal}</TableCell>
                          <TableCell>
                            {getEquipmentStatusStyles(status)}
                          </TableCell>
                          <TableCell>
                            {getPaymentStatus(paymentStatus)}
                          </TableCell>
                          <TableCell>
                            {moment(createdAt).format("LLL")}
                          </TableCell>
                          <TableCell onClick={(e) => e.stopPropagation()}>
                            <IconButton
                              onClick={(e) => {
                                const current = orders.find(
                                  (role) => role.id === row.id
                                );

                                handleEquiomentClick(e, current);
                              }}
                            >
                              <MoreVertIcon />
                            </IconButton>
                          </TableCell>
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Menu
                sx={{ mt: 0.3 }}
                id="basic-menu"
                anchorEl={equipmentAnchorEl}
                open={equipmentopen}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <MenuItem
                  sx={{ mt: 1 }}
                  onClick={() => {
                    navigate("/equipmentsorder/details", {
                      state: {
                        orderId: currentOrder.orderId,
                        parent: "/dashboard",
                      },
                    });
                  }}
                >
                  <ListItemIcon>
                    <img src={eye} alt="View Details" />
                  </ListItemIcon>
                  <ListItemText>View Details</ListItemText>
                </MenuItem>
              </Menu>
            </Stack>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={3} mb={4}>
        <Grid item lg={12} md={12} xs={12}>
          <div
            style={{
              backgroundColor: "white",
              borderRadius: "10px",
              color: "black",
            }}
          >
            <h4 style={{ padding: "20px 0px 0px 10px" }}>
              Latest Register Users
            </h4>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              sx={{ padding: "20px 0px" }}
            >
              <TableContainer
                component={Paper}
                sx={{ minHeight: "300px", overflowY: "scroll" }}
              >
                <Table stickyHeader aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell className="table-header-font">
                        Name
                      </StyledTableCell>
                      <StyledTableCell className="table-header-font">
                        Email
                      </StyledTableCell>
                      <StyledTableCell className="table-header-font">
                        Mobile
                      </StyledTableCell>
                      <StyledTableCell className="table-header-font">
                        Status
                      </StyledTableCell>
                      <StyledTableCell className="table-header-font">
                        Date
                      </StyledTableCell>
                      <StyledTableCell className="table-header-font">
                        Action
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users?.length === 0 ? (
                      <TableRow>
                        <TableCell
                          sx={{
                            color: "silver",
                            textAlign: "center",
                            paddingTop: "90px",
                            borderBottom: "none",
                            fontSize: "30px",
                          }}
                          colSpan="6"
                        >
                          No records to display
                        </TableCell>
                      </TableRow>
                    ) : (
                      ""
                    )}
                    {users?.map((row, index) => {
                      var {
                        fname,
                        lname,
                        profile_image,
                        email,
                        mobile,
                        createdAt,
                        isActive,
                      } = row;
                      var name =
                        (fname ? fname : "") + " " + (lname ? lname : "");
                      name = name === " " ? "-" : name;
                      email = email ? email : "-";

                      return (
                        <>
                          <StyledTableRow
                            key={row.id}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setCurrentUser(row);
                              handleUserView();
                            }}
                          >
                            <TableCell component="th" scope="row">
                              <Stack flexDirection="row" alignItems="center">
                                {isProfileImageValid(profile_image) ? (
                                  <img
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      borderRadius: "50%",
                                    }}
                                    sx={{ mx: 0.5 }}
                                    alt="User Profile"
                                    src={profile_image}
                                  />
                                ) : (
                                  <Avatar>
                                    <PersonIcon />
                                  </Avatar>
                                )}

                                <Typography sx={{ ml: 2 }}>
                                  {name?.length > 15
                                    ? name.substr(0, 15) + "..."
                                    : name}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell>{email}</TableCell>
                            <TableCell>{mobile}</TableCell>
                            <TableCell>{getStatus(isActive)}</TableCell>
                            <TableCell>
                              {moment(createdAt).format("LLL")}
                            </TableCell>
                            <TableCell>
                              <IconButton
                                onClick={(e) => {
                                  e.stopPropagation();
                                  const currentUser = users.find(
                                    (role) => role.id === row.id
                                  );
                                  handleClick(e, currentUser);
                                }}
                              >
                                <MoreVertIcon />
                              </IconButton>
                            </TableCell>
                          </StyledTableRow>
                        </>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClick={() => {
                  handleClose();
                }}
              >
                <MenuItem
                  sx={{ mt: 1 }}
                  onClick={() => {
                    handleUserView();
                  }}
                >
                  <ListItemIcon>
                    <img src={eye} alt="View Details" />
                  </ListItemIcon>
                  <ListItemText>View Details</ListItemText>
                </MenuItem>
              </Menu>
              {userView && (
                <UsersDetailsModal
                  user={currentUser}
                  open={userView}
                  setOpen={setUserView}
                  handleClose={closeUserView}
                  refreshScreen={refreshScreen}
                />
              )}
            </Stack>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
