import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { styled } from "@mui/material/styles";
import {
  Search as SearchIcon,
  MoreVert as MoreVertIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import {
  InputBase,
  Button,
  Typography,
  IconButton,
  Stack,
  Pagination,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Drawer,
  Divider,
  Box,
  TextField,
  FormLabel,
  InputAdornment,
} from "@mui/material";
import moment from "moment";

import eye from "../../Assets/eye.svg";
import edit from "../../Assets/edit.svg";
import deleteIcon from "../../Assets/delete.svg";
import filterIcon from "../../Assets/filter.svg";

import { useForm } from "../../Utils/useForm";
import { getBookingListApi } from "../../state/actions/nursesAction";
import { categoryListApi } from "../../state/actions/categoryAction";
import Backdrop from "../BackDrop/BackDrop";
import { useDispatch } from "react-redux";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  color: "black",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  borderRadius: "10px",
  backgroundColor: "white",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    paddingRight: "15px",
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: "#0F0F0F80",
}));

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
}));
const StyledTextField = styled(TextField)(() => ({
  marginTop: "10px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
  },
}));
const StyledChip = styled(Chip)(() => ({
  borderRadius: "10px",
  fontSize: "14px",
}));

const StyledRequired = styled("span")({
  color: "red",
  marginLeft: "2px",
  fontSize: "20px",
});

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#0957dd0a",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function NursesList(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { refresh, refreshScreen } = props;

  const [page, setPage] = useState(1);
  const [pagePerSize, setPagePerSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const [currentNurse, setCurrentNurse] = useState({});
  const [filter, setFilter] = useState(false);
  const [nurses, setNurses] = useState([]);
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const getCategoryDropdownList = async (page) => {
    setOpenBreakdrop(true);
    const res = await dispatch(categoryListApi());
    if (res && res.status) {
      setCategoryList(res.data);
    } else {
      setCategoryList([]);
    }
    setOpenBreakdrop(false);
  };

  useEffect(() => {
    getCategoryDropdownList();
  }, []);

  const getNursesList = async (page) => {
    let queryString =
      "?page=" + page + "&size=" + pagePerSize + "&search=" + searchValue;

    Object.keys(values).map((key) => {
      queryString += "&" + key + "=" + values[key];
    });

    setOpenBreakdrop(true);
    const res = await getBookingListApi(queryString);
    if (res && res.status) {
      setNurses(res.data);
      setTotalRecords(res.totalRecord);
      setTotalPages(Math.ceil(res.totalRecord / pagePerSize));
    } else {
      setNurses([]);
      setTotalRecords(0);
      setTotalPages(0);
    }
    setOpenBreakdrop(false);
  };

  const handleChangePage = (event, value) => {
    setPage(value);
    getNursesList(value);
  };
  //  whenever admin list change fetch the list again
  useEffect(() => {
    getNursesList(page);
  }, [refresh]);

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event, current) => {
    setAnchorEl(event.currentTarget);
    setCurrentNurse(current);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    handleClose();
  }, [nurses]);

  const onSearch = async (search) => {
    if (search.target.value === "") {
      resetForm();
    }
    setSearchValue(search.target.value);

    let queryString =
      "?page=" + 1 + "&size=" + pagePerSize + "&search=" + search.target.value;

    setOpenBreakdrop(true);
    const res = await getBookingListApi(queryString);
    if (res && res.status) {
      setNurses(res.data);
      setTotalRecords(res.totalRecord);
      setTotalPages(Math.ceil(res.totalRecord / pagePerSize));
    } else {
      setNurses([]);
      setTotalRecords(0);
      setTotalPages(0);
    }

    setOpenBreakdrop(false);
  };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 700);
    };
  };

  const debounceserach = debounce(onSearch);

  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
  };
  const initialValues = {
    bookingNumber: "",
    categoryId: "",
    status: "",
    paymentStatus: "",
  };
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);

  const onFilterSubmit = async (e) => {
    if (typeof e != "undefined") {
      e.preventDefault();
    }
    //  we are using initialValues because our values object is not resetting.
    var formValues = typeof e === "undefined" ? initialValues : values;
    let queryString = "?search=" + "&page=" + 1 + "&size=" + pagePerSize;
    Object.keys(formValues).map((key) => {
      queryString += "&" + key + "=" + formValues[key];
    });

    setOpenBreakdrop(true);
    const res = await getBookingListApi(queryString);
    if (res && res.status) {
      setNurses(res.data);
      setTotalRecords(res.totalRecord);
      setTotalPages(Math.ceil(res.totalRecord / pagePerSize));
    } else {
      setNurses([]);
      setTotalRecords(0);
      setTotalPages(0);
    }

    setOpenBreakdrop(false);
    closeFilterDrawer();
  };

  //  when admin successfully delete we have to close menu

  const [addCategory, setAddCategory] = useState(false);

  const getStatus = (status) => {
    const statusColors = {
      1: {
        styles: {
          // backgroundColor: "rgba(18, 183, 106, 0.05)",
          color: "#12B76A",
        },
        title: "Alloted",
      },

      2: {
        styles: {
          backgroundColor: "rgb(235 193 14 / 24%)",
          color: "orange",
        },
        title: "Pending",
      },
      3: {
        title: "Approved",
        styles: {
          color: "green",
        },
      },
      4: {
        styles: {
          // backgroundColor: "rgba(240, 68, 56, 0.05)",
          color: "#F04438;",
        },
        title: "Cancelled",
      },
      5: {
        styles: {
          // backgroundColor: "rgba(240, 68, 56, 0.05)",
          color: "#F04438;",
        },
        title: "Completed",
      },
    };
    return (
      <StyledChip
        sx={statusColors[status]?.styles}
        label={statusColors[status]?.title}
      />
    );
  };

  const statusList = [
    {
      name: "Alloted",
      value: 1,
    },
    {
      name: "Pending",
      value: 2,
    },
    {
      name: "Approved",
      value: 3,
    },
    {
      name: "Cancelled",
      value: 4,
    },
    {
      name: "Completed",
      value: 5,
    },
  ];

  const getPaymentStatus = (id) => {
    const status = {
      0: {
        label: "Failed",
        styleSheet: {
          backgroundColor: "rgb(240 68 56 / 15%)",
          color: "#e90000",
        },
      },
      1: {
        label: "Success",
        styleSheet: {
          backgroundColor: "#28a74538",
          color: "#28a745",
        },
      },
      2: {
        label: "Pending",
        styleSheet: {
          backgroundColor: "rgb(235 193 14 / 24%)",
          color: "orange",
        },
      },
      3: {
        label: "Declined",
        styleSheet: {
          backgroundColor: "rgb(115 56 240 / 8%)",
          color: "#ffc107;",
        },
      },
    };
    if (status[id] === undefined) return "-";
    const { label, styleSheet } = status[id];
    return <StyledChip sx={styleSheet} label={label} />;
  };
  return (
    <>
      <Backdrop open={openBackdrop} />
      <div
        style={{
          backgroundColor: "white",
          borderRadius: "10px",
          color: "black",
        }}
      >
        {/* search bar and filter button */}
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          sx={{ padding: "20px 0px" }}
        >
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              sx={{
                height: "50px",
              }}
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
              onKeyUp={debounceserach}
            />
          </Search>

          <Button
            sx={{
              border: "1px solid rgba(15, 15, 15, 0.15)",
              borderRadius: "10px",
              mr: 2,
              padding: "5px 20px",
              fontWeight: "bold",
              color: "black",
            }}
            endIcon={
              <IconButton sx={{ ml: 3 }}>
                <img src={filterIcon} alt="Filter Options" />
              </IconButton>
            }
            onClick={openFilterDrawer}
          >
            Filter
          </Button>
        </Stack>
        <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
          <form
            onSubmit={onFilterSubmit}
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                // width: 220,
                padding: "10px 20px",
              }}
            >
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography sx={{ fontWeight: "bold" }}>Filter</Typography>
                <IconButton sx={{ color: "black" }} onClick={closeFilterDrawer}>
                  <CloseIcon />
                </IconButton>
              </Stack>

              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="bookingNumber">
                  Booking Number{" "}
                </StyledFormLabel>
                <StyledTextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search Booking Number"
                  fullWidth
                  id="bookingNumber"
                  name="bookingNumber"
                  value={values.bookingNumber}
                  onChange={handleInputChange}
                />
              </Box>
              <Divider sx={{ mt: 2 }} />

              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="categoryId">Category</StyledFormLabel>
                <StyledTextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search Category"
                  fullWidth
                  id="categoryId"
                  name="categoryId"
                  value={values.categoryId}
                  onChange={handleInputChange}
                  select
                >
                  {categoryList
                    .filter((list) => list.id !== 1)
                    .map((list, index) => {
                      const { name, id } = list;
                      return (
                        <MenuItem
                          sx={{ width: "250px" }}
                          value={id}
                          key={index}
                        >
                          {name}
                        </MenuItem>
                      );
                    })}
                </StyledTextField>
              </Box>
              <Divider sx={{ mt: 2 }} />
              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="status">Status</StyledFormLabel>
                <StyledTextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Status"
                  fullWidth
                  id="status"
                  name="status"
                  value={values.status}
                  select
                  onChange={handleInputChange}
                >
                  {statusList.map((list, index) => {
                    const { name, value } = list;
                    return (
                      <MenuItem
                        sx={{ width: "250px" }}
                        value={value}
                        key={index}
                      >
                        {name}
                      </MenuItem>
                    );
                  })}
                </StyledTextField>
              </Box>
              <Divider sx={{ mt: 2 }} />
              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="paymentStatus">
                  Payment Status
                </StyledFormLabel>
                <StyledTextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Payment Status"
                  fullWidth
                  id="paymentStatus"
                  name="paymentStatus"
                  value={values.paymentStatus}
                  select
                  onChange={handleInputChange}
                >
                  <MenuItem sx={{ width: "250px" }} value={0}>
                    Failed
                  </MenuItem>
                  <MenuItem sx={{ width: "250px" }} value={1}>
                    Success
                  </MenuItem>
                </StyledTextField>
              </Box>
              <Divider sx={{ mt: 2 }} />
            </Box>
            <Stack
              sx={{ margin: "80px 20px 25px 20px" }}
              flexDirection="row"
              justifyContent="space-between"
            >
              <Button
                sx={{
                  backgroundColor: "white",
                  color: "black",
                  border: "1px solid #0F0F0F80",
                  borderRadius: "10px",
                  padding: "10px 25px",
                  "&:hover": {
                    // backgroundColor: "#0957DD",
                  },
                }}
                onClick={() => {
                  resetForm();
                  onFilterSubmit();
                }}
              >
                Reset
              </Button>
              <Button
                sx={{
                  backgroundColor: "#0957DD",
                  color: "white",
                  borderRadius: "10px",
                  padding: "10px 25px",
                  "&:hover": {
                    backgroundColor: "#0957DD",
                  },
                }}
                type="submit"
              >
                Filter
              </Button>
            </Stack>
          </form>
        </Drawer>

        <TableContainer
          component={Paper}
          sx={{ minHeight: "300px", overflowY: "scroll" }}
        >
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                {/* <StyledTableCell className="table-header-font">
                  Id
                </StyledTableCell> */}
                <StyledTableCell className="table-header-font">
                  Code
                </StyledTableCell>
                <StyledTableCell className="table-header-font">
                  Category Name
                </StyledTableCell>
                <StyledTableCell className="table-header-font">
                  Customer Name
                </StyledTableCell>
                <StyledTableCell className="table-header-font">
                  Nurse Name
                </StyledTableCell>
                <StyledTableCell className="table-header-font">
                  Booking Subtotal
                </StyledTableCell>
                <StyledTableCell className="table-header-font">
                  Payment Status
                </StyledTableCell>
                <StyledTableCell className="table-header-font">
                  Booking Status
                </StyledTableCell>
                <StyledTableCell className="table-header-font">
                  Date
                </StyledTableCell>
                <StyledTableCell className="table-header-font">
                  Action
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {nurses?.map((row, index) => {
                var {
                  bookingId,
                  bookingNumber,
                  categoryName,
                  userNameFname,
                  userNurseFname,
                  bookingDiscount,
                  bookingSubtotal,
                  paymentStatus,
                  status,
                  createdAtbooking,
                } = row;
                var nurseName = userNurseFname === "" ? "-" : userNurseFname;
                categoryName = categoryName === "" ? "-" : categoryName;
                nurseName = nurseName === " " ? "-" : nurseName;
                paymentStatus = paymentStatus === null ? "-" : paymentStatus;
                status = status === " " ? 2 : status;

                return (
                  <StyledTableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    onClick={() => {
                      navigate("/nurses/details", {
                        state: { orderId: row.bookingId, parent: "/nurses" },
                      });
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    {/* <TableCell>{bookingId}</TableCell> */}
                    <TableCell className="equipment-list-order-number">
                      {bookingNumber}
                    </TableCell>
                    <TableCell>{categoryName}</TableCell>
                    <TableCell>{userNameFname}</TableCell>
                    <TableCell>{nurseName}</TableCell>
                    <TableCell>{bookingSubtotal}</TableCell>
                    <TableCell>{getPaymentStatus(paymentStatus)}</TableCell>
                    <TableCell>{getStatus(status)}</TableCell>

                    <TableCell>
                      {createdAtbooking
                        ? moment(createdAtbooking).format("LLL")
                        : "-"}
                    </TableCell>
                    <TableCell onClick={(e) => e.stopPropagation()}>
                      <IconButton
                        onClick={(e) => {
                          const current = nurses.find(
                            (role) => role.id === row.id
                          );

                          handleClick(e, current);
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </TableCell>
                  </StyledTableRow>
                );
              })}
              {nurses?.length === 0 ? (
                <TableRow>
                  <TableCell
                    sx={{
                      color: "silver",
                      textAlign: "center",
                      paddingTop: "90px",
                      borderBottom: "none",
                      fontSize: "30px",
                    }}
                    colSpan="7"
                  >
                    No records to display
                  </TableCell>
                </TableRow>
              ) : (
                ""
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Menu
          sx={{ mt: 0.3 }}
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuItem
            sx={{ mt: 1 }}
            onClick={() => {
              navigate("/nurses/details", {
                state: { orderId: currentNurse.bookingId, parent: "/nurses" },
              });
            }}
          >
            <ListItemIcon>
              <img src={eye} alt="View Details" />
            </ListItemIcon>
            <ListItemText>View Details</ListItemText>
          </MenuItem>
          {/* <MenuItem
            sx={{ mt: 1 }}
            onClick={() => {
              navigate("/nurses/edit", {
                state: currentNurse.bookingId,
              });
            }}
          >
            <ListItemIcon>
              <img src={edit} alt="Edit" />
            </ListItemIcon>
            <ListItemText>Edit</ListItemText>
          </MenuItem> */}
        </Menu>
      </div>
      {/* pagination */}
      <Stack
        sx={{ mt: 4, pb: 2 }}
        flexDirection="row"
        justifyContent="space-between"
      >
        <div>
          Showing{" "}
          {page * pagePerSize > totalRecords
            ? totalRecords
            : page * pagePerSize}{" "}
          of {totalRecords} Results
        </div>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
        />
      </Stack>
    </>
  );
}
