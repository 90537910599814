import React, { useState, useEffect } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import Backdrop from "../BackDrop/BackDrop";
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './editorcss.css'
import { convertToHTML } from 'draft-convert';
import { getPrivacyPolicy, updatePrivacyPolicy } from "../../state/actions/generalAction";
import DOMPurify from 'dompurify';
import { ToastContainer, toast } from "react-toastify";

const toastType = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
};

export default function PrivacyPolicy() {

    const [openBackdrop, setOpenBreakdrop] = useState(false);
    const [convertedContent, setConvertedContent] = useState(null)
    const [policyId, setpolicyId] = useState(0)
    const [editorState, setEditorState] = useState();
    const handleEditorChange = (state) => {
        setEditorState(state);
        convertContentToHTML();
    }
    const convertContentToHTML = () => {
        let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
        setConvertedContent(currentContentAsHTML);
    }

    const updatePolicy = async () => {
        const payload = {
            descriptions: convertedContent
        };
        const res = await updatePrivacyPolicy(policyId, payload);
        if (res && res.status) {
            toast.success(res.message, toastType);
        }
    }

    const getPolicy = async () => {
        const res = await getPrivacyPolicy();
        if (res && res.status) {
            setpolicyId(res.data.id);
            setEditorState(() => EditorState.createWithContent(ContentState.createFromBlockArray(
                convertFromHTML(res.data.description)
            )))
            setOpenBreakdrop(false);
        } else {
            setOpenBreakdrop(false);
        }
    }

    const createMarkup = (html) => {
        return {
            __html: DOMPurify.sanitize(html)
        }
    }

    useEffect(() => {
        getPolicy();
    }, [])

    return (
        <>
            <Backdrop open={openBackdrop} />
            <ToastContainer limit={1} />
            <Stack flexDirection="row" justifyContent="space-between">
                <Typography sx={{ fontSize: "30px", fontWeight: "bold" }}>
                    Privacy Policy
                </Typography>
            </Stack>

            <div className="App">
                <header className="App-header">
                    {/* Rich Text Editor Example */}
                </header>
                <Editor
                    editorState={editorState}
                    onEditorStateChange={handleEditorChange}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                />
                {/* <div className="preview" dangerouslySetInnerHTML={createMarkup(convertedContent)}></div> */}
            </div>
            <Stack flexDirection="row" justifyContent="space-between" style={{ paddingTop: "10px" }}>
                <Button
                    sx={{
                        backgroundColor: "#0957DD",
                        color: "white",
                        borderRadius: "10px",
                        padding: "15px 40px",
                        "&:hover": {
                            backgroundColor: "#0957DD",
                        },
                    }}
                    onClick={() => updatePolicy()}
                    color="primary"
                >
                    Save
                </Button>
            </Stack>

        </>
    )
}
