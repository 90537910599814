import React, { useState } from "react";

import {  Button, Stack, Typography,Box  } from "@mui/material";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import EquipmentsList from './EquipmentsList'


const Equipments = () => {
  const navigate = useNavigate()
  const [refresh, setRefresh] = useState(false);
  const refreshScreen = () => {
    setRefresh(!refresh);
  };

  return (
    <>
      <Stack flexDirection="row" justifyContent="space-between">
        <Typography sx={{ fontSize: "30px", fontWeight: "bold" }}>
          Equipments
        </Typography>
        <Button
          sx={{
            backgroundColor: "#0957DD",
            color: "white",
            borderRadius: "10px",
            padding: "15px 40px",
            "&:hover": {
              backgroundColor: "#0957DD",
            },
          }}
          onClick={() => {
            navigate('/equipments/add',
              {
                state: ''
              }
            )
          }}
          startIcon={<Add />}
          color="primary"
        >
          Add Eqipments
        </Button>
      </Stack>

      <Box sx={{ mt: 4 }}>
        <EquipmentsList refresh={refresh} refreshScreen={refreshScreen} />
      </Box>
    </>
  );
};
export default Equipments;
